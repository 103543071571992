import { useMutation, useQuery } from 'react-query'

import { getQueryKey } from 'utils/reactQuery'

import {
  calculateFinancialWellness,
  getHolisticPayrollCalculatorData,
  getWorkerHolisticPayrollDashboard,
} from 'services/HolisticPayroll/WorkerServices'

import useErrorHandler from '../useErrorHandler'

const getQueryFunction = (serviceParams) => {
  const { queryKey, workerId, data } = serviceParams
  const currentQueryKey = getQueryKey(queryKey)

  if (currentQueryKey === 'getWorkerHolisticPayrollDashboard') {
    return getWorkerHolisticPayrollDashboard(workerId)
  }

  if (currentQueryKey === 'getHolisticPayrollCalculatorData') {
    return getHolisticPayrollCalculatorData(workerId, data)
  }

  return null
}

const getMutationFunction = (mutationData) => {
  const { mutationMethod, workerId, data } = mutationData

  if (mutationMethod === 'PATCH')
    return calculateFinancialWellness(workerId, data)

  return null
}

const useWorkerHolisticPayroll = ({
  serviceParams = {
    queryKey: 'getWorkerHolisticPayrollDashboard',
  },
  queryOptions = {},
  mutationOptions = {},
} = {}) => {
  const { handleError } = useErrorHandler()

  const { data: { data } = {}, ...restWorkerHolisticPayroll } = useQuery(
    serviceParams.queryKey,
    () => getQueryFunction(serviceParams),
    {
      onError: (error) => handleError(error),
      ...queryOptions,
    }
  )

  const workerHolisticPayrollMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => handleError(error),
      ...mutationOptions,
    }
  )

  return {
    workerHolisticPayrollQuery: {
      data,
      ...restWorkerHolisticPayroll,
    },
    workerHolisticPayrollMutation,
  }
}

export default useWorkerHolisticPayroll
