import { Box, Button, Typography } from '@mui/material'

import LoadedFile from 'components/UI/Formik/CommonFields/LoadedFile'
import Icon from 'components/UI/Icon'

import { formatLongDisplayDate } from 'utils/dateTime'
import { formatCurrency } from 'utils/format'
import useDownloadURI from 'utils/hooks/useDownloadURI'

import {
  getDocumentLabel,
  getFilesName,
  kinshipLabel,
} from '../Form/Beneficiaries/helpers'

const InProgressContent = ({ worker, affiliations, handleClose }) => {
  const downloadURI = useDownloadURI()

  const beneficiaries = affiliations?.beneficiaries || []

  const workerData = {
    position: {
      icon: <Icon name="document-signature" sx={{ fontSize: '2rem' }} />,
      text: 'Cargo',
      name: worker?.position?.name,
    },
    salary: {
      icon: <Icon name="coin" sx={{ fontSize: '2rem' }} />,
      text: 'Valor del salario',
      name: formatCurrency(worker?.base_salary),
    },
    initialDay: {
      icon: <Icon name="calendar" sx={{ fontSize: '2rem' }} />,
      text: 'Fecha de contratación',
      name: formatLongDisplayDate(worker?.initial_day),
    },
    healthProvider: {
      icon: <Icon name="medical-insurance" sx={{ fontSize: '2rem' }} />,
      text: 'EPS',
      name: worker?.health_provider?.name,
    },
    pensionProvider: {
      icon: <Icon name="coins" sx={{ fontSize: '2rem' }} />,
      text: 'Fondo de Pensiones',
      name: worker?.pension_provider?.name,
    },
    severanceProvider: {
      icon: <Icon name="document-coin" sx={{ fontSize: '2rem' }} />,
      text: 'Fondo de cesantías',
      name: worker?.severance_provider?.name,
    },
    riskType: {
      icon: <Icon name="signal" sx={{ fontSize: '2rem' }} />,
      text: 'Clase de riesgo',
      name: worker?.work_center?.risk_type,
    },
    location: {
      icon: <Icon name="location-pin" sx={{ fontSize: '2rem' }} />,
      text: 'Sede de trabajo',
      name: worker?.location?.name,
    },
  }
  return (
    <>
      <Typography variant="h4">Datos de la afiliación</Typography>
      <Box
        sx={(theme) => ({
          display: 'grid',
          gridTemplateColumns: '1fr',
          marginTop: theme.spacing(3),
          rowGap: theme.spacing(6),
          columnGap: theme.spacing(4),
          [theme.breakpoints.up('tablet')]: {
            gridTemplateColumns: '1fr 1fr',
          },
        })}
      >
        {Object.values(workerData).map((data) => (
          <Box
            key={data.text}
            sx={{
              display: 'flex',
            }}
          >
            <Box
              sx={(theme) => ({
                marginRight: theme.spacing(1),
                backgroundColor: 'complementary1.light',
                width: '3.13rem',
                height: '3.13rem',
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              })}
            >
              {data.icon}
            </Box>
            <Box>
              <Typography variant="blockquote">{data.name}</Typography>
              <Typography variant="body1">{data.text}</Typography>
            </Box>
          </Box>
        ))}
      </Box>
      {beneficiaries.length > 0 ? (
        <Box
          sx={(theme) => ({
            marginTop: theme.spacing(4),
          })}
        >
          <Typography
            variant="h4"
            sx={(theme) => ({
              marginBottom: theme.spacing(3),
            })}
          >
            Beneficiarios
          </Typography>
          <Box
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing(4),
            })}
          >
            {beneficiaries.map((beneficiary) => (
              <Box key={beneficiary.id}>
                <Box
                  sx={(theme) => ({
                    display: 'grid',
                    gridTemplateColumns: '1fr',
                    rowGap: theme.spacing(6),
                    columnGap: theme.spacing(4),
                    [theme.breakpoints.up('tablet')]: {
                      gridTemplateColumns: '1fr 1fr',
                    },
                  })}
                >
                  <Box
                    sx={{
                      display: 'flex',
                    }}
                  >
                    <Box
                      sx={(theme) => ({
                        marginRight: theme.spacing(1),
                        backgroundColor: 'complementary1.light',
                        width: '3.13rem',
                        height: '3.13rem',
                        borderRadius: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      })}
                    >
                      <Icon name="id-business-card" sx={{ fontSize: '2rem' }} />
                    </Box>
                    <Box>
                      <Typography variant="blockquote">
                        {beneficiary.name}
                      </Typography>
                      <Typography variant="body1">Nombre</Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                    }}
                  >
                    <Box
                      sx={(theme) => ({
                        marginRight: theme.spacing(1),
                        backgroundColor: 'complementary1.light',
                        width: '3.13rem',
                        height: '3.13rem',
                        borderRadius: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      })}
                    >
                      <Icon name="family" sx={{ fontSize: '2rem' }} />
                    </Box>
                    <Box>
                      <Typography variant="blockquote">
                        {kinshipLabel[beneficiary.kinship]}
                      </Typography>
                      <Typography variant="body1">Parentesco</Typography>
                    </Box>
                  </Box>
                  {beneficiary.files
                    ? Object.entries(beneficiary.files).map((file) => {
                        const filesName = getFilesName(beneficiary.files)

                        return (
                          <Box
                            key={file[0]}
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              minWidth: 0,
                            }}
                          >
                            <Typography
                              variant="lead2"
                              sx={(theme) => ({
                                marginBottom: theme.spacing(0.5),
                              })}
                            >
                              {getDocumentLabel(
                                beneficiary.kinship,
                                file[0]?.slice(0, -5)
                              )}
                            </Typography>
                            <Box
                              sx={(theme) => ({
                                display: 'flex',
                                alignItems: 'center',
                                gap: theme.spacing(2),
                              })}
                            >
                              <LoadedFile
                                fileName={filesName[file[0]]}
                                hideRemoveButton
                              />
                              <Button
                                variant="text"
                                onClick={() => downloadURI(file[1])}
                                sx={{
                                  margin: 0,
                                  padding: 0,
                                  width: 'fit-content',
                                  textDecoration: 'underline',
                                  minWidth: 0,
                                }}
                              >
                                Ver
                              </Button>
                            </Box>
                          </Box>
                        )
                      })
                    : null}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      ) : null}
      <Button
        sx={(theme) => ({ marginTop: theme.spacing(7) })}
        onClick={handleClose}
      >
        Regresar
      </Button>
    </>
  )
}

export default InProgressContent
