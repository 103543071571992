import { Box, Typography } from '@mui/material'

const StatusBanner = ({
  image,
  imageAlt,
  action = undefined,
  title,
  message,
}) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
          height: '60vh',
        },
      })}
    >
      {image ? (
        <img src={image} alt={imageAlt} width={128} height={128} />
      ) : null}
      <Box
        sx={(theme) => ({
          maxWidth: '50rem',
          display: 'flex',
          flexDirection: 'column',
          marginTop: theme.spacing(1.5),
          alignItems: 'center',
        })}
      >
        <Typography
          variant="h5"
          sx={(theme) => ({
            marginBottom: theme.spacing(1.5),
            textAlign: 'center',
          })}
        >
          {title}
        </Typography>
        <Typography
          variant="body1"
          sx={(theme) => ({
            marginBottom: theme.spacing(4),
            color: theme.palette.black.light,
            textAlign: 'center',
          })}
        >
          {message}
        </Typography>
        {action ? <>{action}</> : null}
      </Box>
    </Box>
  )
}

export default StatusBanner
