import { Typography } from '@mui/material'

const ContainerDetails = ({ nameDetail, detail }) => {
  return (
    <div>
      <Typography
        variant="lead1"
        sx={(theme) => ({
          marginBottom: theme.spacing(1),
        })}
      >
        {nameDetail}
      </Typography>
      <Typography variant="body2">{detail}</Typography>
    </div>
  )
}

export default ContainerDetails
