import { Box, Checkbox, FormControlLabel, FormGroup } from '@mui/material'

import Icon from 'components/UI/Icon'

const CheckboxGroupField = ({
  value,
  row = false,
  options = [],
  setValue,
  justifiSpaceBetween = false,
  checkboxSx,
}) => {
  return (
    <FormGroup
      row={row}
      sx={(theme) => ({
        ...(justifiSpaceBetween && {
          [theme.breakpoints.up('laptop')]: {
            justifyContent: 'space-between',
          },
        }),
      })}
    >
      {options.map((option) => {
        return (
          <Box sx={checkboxSx} key={option.label}>
            <FormControlLabel
              key={option.value}
              control={
                <Checkbox
                  checkedIcon={<Icon name="check-box-2" />}
                  icon={<Icon name="check-box-1" basic />}
                  checked={
                    value?.find?.(
                      (currentValue) => currentValue === option.value
                    ) !== undefined
                  }
                  onChange={({ target: { name, checked } }) => {
                    const valueSet = new Set(value)

                    if (checked) {
                      valueSet.add(name)
                    } else {
                      valueSet.delete(name)
                    }

                    setValue([...valueSet])
                  }}
                  name={option.value}
                />
              }
              label={option.label}
            />
          </Box>
        )
      })}
    </FormGroup>
  )
}

export default CheckboxGroupField
