import { ReactComponent as accountantIcon } from 'assets/images/views/onboarding/accountant.svg'
import { ReactComponent as businessmanIcon } from 'assets/images/views/onboarding/businessman.svg'
import { ReactComponent as othersIcon } from 'assets/images/views/onboarding/other_role.svg'
import { ReactComponent as outsourcerIcon } from 'assets/images/views/onboarding/outsourcer.svg'
import { ReactComponent as payrollOwnerIcon } from 'assets/images/views/onboarding/payroll_owner.svg'

export const detailsRole = [
  {
    id: 'payroll_assistant',
    role: 'Soy responsable de nómina donde trabajo',
    Image: payrollOwnerIcon,
    iconName: 'bribery',
  },
  {
    id: 'accountant',
    role: 'Contador independiente',
    Image: accountantIcon,
    iconName: 'refund',
  },
  {
    id: 'outsourcer',
    role: 'Outsourcing de nómina',
    Image: outsourcerIcon,
    iconName: 'consultation',
  },
  {
    id: 'businessman',
    role: 'Empresario o gerente',
    Image: businessmanIcon,
    iconName: 'management-team',
  },
  {
    id: 'other',
    role: 'Otro',
    detail:
      'Selecciona esta opción si no te identificas con ninguno de los roles',
    Image: othersIcon,
    dataCy: 'other-role-button',
    iconName: 'engage-users',
  },
]

export const getValuesToSend = (selectRole, otherRoleInformation) => {
  let valuesToSend = { onboarding_step: 'data' }

  if (selectRole === 'other') {
    valuesToSend = {
      ...valuesToSend,
      user_role: 'other',
      user_role_other_info: otherRoleInformation,
    }
  } else {
    valuesToSend = { ...valuesToSend, user_role: selectRole }
  }

  return valuesToSend
}
