import { Form, Formik } from 'formik'
import { useQueryClient } from 'react-query'

import { Box, Typography, useMediaQuery } from '@mui/material'

import WellnessHalfDonutChart from 'components/HolisticPayroll/Commons/WellnessHalfDonutChart'
import Button from 'components/UI/Button/Button'
import Loading from 'components/UI/Loading/Loading'
import TooltipInfoIcon from 'components/UI/MaterialUI/TooltipInfoIcon'

import { formatDisplayDateString } from 'utils/dateTime'
import useWorkerHolisticPayroll from 'utils/hooks/HolisticPayroll/WorkerServices'
import useAutoScroll from 'utils/hooks/useAutoScroll'
import useNotifications from 'utils/hooks/useNotifications'

import useWorker from '../../useWorker'
import {
  CardPayrollIncome,
  FinancialOverviewFields,
  MonthlyCashFlowFields,
  getCalculatorCardsData,
  getInitialValues,
  validationSchema,
} from './helpers'

export default function FinancialWellnessCalculator({ workerName }) {
  const queryClient = useQueryClient()
  const { showErrorMessage } = useNotifications()
  const { worker } = useWorker({ useCache: true })
  const isMobile = useMediaQuery(({ breakpoints }) =>
    breakpoints.down('tablet')
  )
  const [calculationResult, scrollToCalculationResult] = useAutoScroll({
    elementId: 'calculation-result',
  })

  const dashboardQueryKey = ['getWorkerHolisticPayrollDashboard', worker.id]
  const calculatorQueryKey = ['getHolisticPayrollCalculatorData', worker.id]
  const { workerHolisticPayrollQuery, workerHolisticPayrollMutation } =
    useWorkerHolisticPayroll({
      serviceParams: {
        queryKey: calculatorQueryKey,
        workerId: worker.id,
      },
    })

  const { worker_financial_wellness: workerFinancialWellness, payroll_income } =
    workerHolisticPayrollQuery.data || {}
  const payrollIncome =
    workerFinancialWellness?.payroll_income || payroll_income
  const financialWellnessScore =
    workerFinancialWellness?.score_dimensions?.financial_wellness_score || 0

  const handleSubmit = (values) => {
    const hasValues = Object.values(values).some((element) => element !== 0)

    if (!hasValues) {
      showErrorMessage(
        'Ingresa tu información para calcular tu Bienestar financiero'
      )
    } else {
      workerHolisticPayrollMutation.mutate(
        {
          mutationMethod: 'PATCH',
          workerId: worker.id,
          data: values,
        },
        {
          onSuccess: async () => {
            await queryClient.invalidateQueries(calculatorQueryKey)
            await queryClient.invalidateQueries(dashboardQueryKey)
            scrollToCalculationResult()
          },
        }
      )
    }
  }

  const getTextButton = () => {
    const baseText = workerFinancialWellness
      ? 'bienestar financiero nuevamente'
      : 'bienestar financiero'
    return isMobile ? `Calcular ${baseText}` : `Calcular mi ${baseText}`
  }

  const initialValues = getInitialValues(workerFinancialWellness)
  const cardsData = getCalculatorCardsData(workerFinancialWellness)

  return workerHolisticPayrollQuery.isLoading ? (
    <Loading />
  ) : (
    <Box
      sx={({ spacing }) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: spacing(6),
      })}
    >
      <Box
        sx={({ spacing }) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: spacing(2),
        })}
      >
        <Typography variant="h3">
          Calculadora de Bienestar Financiero
        </Typography>
        <Typography color="black.dark">
          ¿Crees que tus finanzas están saludables? Desliza y agrega los valores
          que representen tus finanzas actuales. No te preocupes si no tienes
          los datos exactos. Siempre podrás volver y actualizarlos.
        </Typography>
      </Box>

      <CardPayrollIncome payrollIncome={payrollIncome} />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values: formikValues, setFieldValue }) => {
          return (
            <Form>
              <Typography variant="h4">Panorama Financiero General</Typography>
              <FinancialOverviewFields
                payrollIncome={payrollIncome}
                formikValues={formikValues}
                setFieldValue={setFieldValue}
              />

              <Typography variant="h4">Flujo Mensual de Dinero</Typography>
              <MonthlyCashFlowFields
                payrollIncome={payrollIncome}
                formikValues={formikValues}
                setFieldValue={setFieldValue}
              />

              <Button
                size="large"
                type="submit"
                loading={workerHolisticPayrollMutation.isLoading}
                sx={({ breakpoints, spacing }) => ({
                  marginTop: spacing(7),
                  [breakpoints.down('tablet')]: { width: '100%' },
                })}
              >
                {getTextButton()}
              </Button>
            </Form>
          )
        }}
      </Formik>

      {workerFinancialWellness ? (
        <Box id={calculationResult}>
          <Box>
            <Typography
              variant="h3"
              sx={({ spacing }) => ({ marginBottom: spacing(2) })}
            >
              Resultados de tu test de Bienestar Financiero
            </Typography>
            <Typography color="black.dark">
              Respira, {workerName}. Queremos que tu platica esté segura y que
              cumplas todos tus sueños. ¡Creemos en ti!
            </Typography>
          </Box>

          <Box
            sx={({ spacing, breakpoints }) => ({
              display: 'grid',
              gridTemplateColumns: '1fr',
              rowGap: spacing(1),
              marginTop: spacing(4),
              [breakpoints.up('sm')]: {
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gap: spacing(4),
              },
            })}
          >
            <Box
              sx={({ spacing, palette }) => ({
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                backgroundColor: palette.accent1.light,
                borderRadius: '0.6563rem',
                padding: spacing(3),
                gap: spacing(3),
                alignItems: 'center',
                width: '100%',
              })}
            >
              <Typography variant="h4">
                Indicador de Bienestar financiero
              </Typography>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  width: '90%',
                  maxWidth: '28.75rem',
                  justifyContent: 'center',
                }}
              >
                <WellnessHalfDonutChart value={financialWellnessScore} />

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={({ spacing }) => ({
                      textAlign: 'center',
                      margin: spacing(3.6, 0, 1),
                    })}
                  >
                    Puntaje promedio
                  </Typography>
                  <Typography
                    variant="body1"
                    color="accent4.main"
                    textAlign="center"
                  >
                    Actualizado el{' '}
                    {formatDisplayDateString(
                      workerFinancialWellness?.updated_at
                    )}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box
              sx={({ spacing, breakpoints }) => ({
                display: 'grid',
                gridTemplateColumns: '1fr',
                rowGap: spacing(1),
                width: '100%',
                [breakpoints.up('tablet')]: {
                  gridTemplateColumns: '1fr 1fr',
                  gridTemplateRows: 'repeat(3, 9rem)',
                  columnGap: spacing(2),
                },
              })}
            >
              {cardsData.map((data) => (
                <Box
                  key={data.id}
                  sx={({ spacing }) => ({
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    backgroundColor: 'white.main',
                    borderRadius: '0.6563rem',
                    padding: {
                      desktop: spacing(2, 1),
                      tablet: spacing(2),
                      mobile: spacing(3),
                    },
                    alignItems: 'center',
                  })}
                >
                  <Box
                    sx={(theme) => ({
                      display: 'flex',
                      alignItems: 'center',
                      gap: theme.spacing(1),
                    })}
                  >
                    <Typography variant="lead2">{data.title}</Typography>
                    <TooltipInfoIcon
                      title={
                        <>
                          <Typography
                            sx={(theme) => ({ marginBottom: theme.spacing(2) })}
                          >
                            {data.description}
                          </Typography>
                          <Typography variant="h6">Tip: </Typography>
                          <Typography>{data.tip}</Typography>
                        </>
                      }
                      tooltipProps={{
                        tooltipSx: ({ shadows }) => ({
                          boxShadow: shadows[4],
                        }),
                      }}
                      iconProps={{
                        sx: {
                          color: 'accent4.main',
                        },
                      }}
                    />
                  </Box>

                  <Box
                    sx={(theme) => ({
                      width: '90%',
                      [theme.breakpoints.up('sm')]: { width: '9rem' },
                    })}
                  >
                    <WellnessHalfDonutChart value={data.value} />
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      ) : null}
    </Box>
  )
}
