import FormField from 'components/UI/Formik/FormField/Index'

import { contributorSubtypeOptions } from 'utils/worker'

const ContributorSubtype = ({
  name = 'contributor_subtype',
  contractCategory,
  optional,
  ...props
}) => {
  const contractCategories = ['part_time_contract', 'employee'].includes(
    contractCategory
  )

  return contractCategory && contractCategories ? (
    <FormField
      name={name}
      label="Subtipo de cotizante"
      variant="select"
      options={contributorSubtypeOptions || []}
      placeholder="Seleccione un subtipo..."
      optional={optional}
      {...props}
    />
  ) : null
}

export default ContributorSubtype
