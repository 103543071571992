import { useState } from 'react'

import Modal from 'components/UI/Modal/Modal'

import useUpdateOnboarding from '../useUpdateOnboarding'
import DiscardAction from './DiscardAction'
import DiscardProcessContent from './DiscardProcessContent'
import AffiliationForm from './Form'
import { discardText, discardTitles } from './helpers'

const AffiliationModal = ({
  state,
  handleClose,
  resetAffiliationModalState,
}) => {
  const [currentStep, setCurrentStep] = useState(0)
  const [discard, setDiscard] = useState(
    state.affiliation?.status === 'not_apply'
  )

  const { updateOnboarding, onboardingMutation } = useUpdateOnboarding()

  const handleUpdateOnboarding = () => {
    updateOnboarding({
      onboardingId: state.onboardingId,
      data: {
        action_to_discard: 'affiliation',
      },
      onSuccessCallback: () => {
        handleClose()
      },
    })
  }

  const resetModalState = () => {
    setDiscard(false)
    setCurrentStep(0)
    resetAffiliationModalState()
  }

  return (
    <Modal
      open={state.open}
      onCancel={handleClose}
      header={
        currentStep === 0 ? (
          <DiscardAction
            discardTitles={discardTitles.affiliation}
            discard={discard}
            setDiscard={setDiscard}
          />
        ) : (
          'Nueva afiliación a Seguridad Social'
        )
      }
      hideFooter
      isLoading={onboardingMutation.isLoading}
      adornment={[
        {
          variant: 'signature',
          color: 'complementary1.light',
          width: 177,
          height: 195,
          sx: { bottom: '-4.8rem', right: '2rem' },
        },
      ]}
      paperSx={{
        maxWidth: '46rem',
      }}
      dialogProps={{
        TransitionProps: {
          onExited: resetModalState,
        },
      }}
    >
      {!discard ? (
        <AffiliationForm
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          handleClose={handleClose}
          workerId={state.workerId}
          workerName={state.workerName}
        />
      ) : null}
      {discard ? (
        <DiscardProcessContent
          discardText={discardText.discardAffiliation}
          handleClose={handleClose}
          handleUpdateOnboarding={handleUpdateOnboarding}
          isLoading={onboardingMutation.isLoading}
        />
      ) : null}
    </Modal>
  )
}

export default AffiliationModal
