import { Link as MuiLink } from '@mui/material'

import * as organizationConfig from 'config/organization'

const OrganizationWhatsApp = ({ variant, copy }) => {
  const whatAppByVariant = {
    sales: organizationConfig.WHATSAPP_SALES,
  }

  const whatsApp = whatAppByVariant[variant]

  return (
    <MuiLink href={whatsApp} target="_blank" underline="hover">
      {copy}
    </MuiLink>
  )
}

export default OrganizationWhatsApp
