import { useQuery } from 'react-query'

import { formatCurrency } from 'utils/format'
import useErrorHandler from 'utils/hooks/useErrorHandler'

import { getWorkersCredits } from 'services/benefits/credits'

export const creditsColumnsData = [
  {
    Header: 'Número crédito',
    accessor: 'number',
  },
  {
    Header: 'Empleado',
    accessor: 'worker_full_name',
  },
  {
    Header: 'Valor crédito',
    accessor: 'value',
    Cell: ({ row }) => formatCurrency(row.original.value),
  },
  {
    Header: 'Total pagado',
    accessor: 'paid_value',
    Cell: ({ row }) => formatCurrency(row.original.paid_value),
  },
]

export const useWorkersCredits = (options = {}) => {
  const { handleError } = useErrorHandler()

  const {
    data: workersCreditsData,
    isLoading: isLoadingWorkersCredits,
    ...restWorkersCredits
  } = useQuery('workersCredits', getWorkersCredits, {
    onError: (error) => {
      handleError(error)
    },
    ...options,
  })

  return {
    ...restWorkersCredits,
    workersCreditsData,
    isLoadingWorkersCredits,
  }
}
