import { Box } from '@mui/material'

import { isAlegraClaroDist, isArusDist } from 'utils/auth'
import { isDev } from 'utils/general'

import robotErrorImg from 'assets/images/views/common/aleluya_beach_man.svg'

import ErrorPageAtom from './Atoms/ErrorPage'

const ErrorPage = ({ error, resetError }) => {
  const errorFooter =
    (isAlegraClaroDist() || isArusDist()) &&
    '¿Podrías contarme por el chat qué pasó?'

  const handleResetError = () => {
    if (resetError) {
      resetError()
    }
  }

  return (
    <Box m={4}>
      <ErrorPageAtom
        subtitle="No eres tú, somos nosotros"
        description="Al parecer algo está fallando, pero no te preocupes estamos trabajando para darle solución."
        image={robotErrorImg}
        details={isDev ? error.toString() : null}
        footer={errorFooter}
        handleResetError={handleResetError}
      />
    </Box>
  )
}

export default ErrorPage
