import { useFormikContext } from 'formik'

import { Box, Typography } from '@mui/material'

import usePremiumFeature from 'components/App/Premium/usePremiumFeature'
import Emoji from 'components/UI/Emoji'
import DocumentTypeField from 'components/UI/Formik/CommonFields/DocumentTypeField'
import IdentificationNumberField from 'components/UI/Formik/CommonFields/IdentificationNumberField'
import FormField from 'components/UI/Formik/FormField/Index'

import { isAlegraClaroDist } from 'utils/auth'
import { chronometer } from 'utils/emojis'
import { getFileNameFromUrl, isValidUrl } from 'utils/general'
import { closedQuestionOptions } from 'utils/worker'

import messages from 'messages/worker'

const FormFieldsBasic = () => {
  const { values } = useFormikContext()

  const pictureUrl = isValidUrl(values?.picture)

  const tempPictureName = getFileNameFromUrl(
    pictureUrl ? pictureUrl.search : undefined
  )

  const pictureName = tempPictureName?.replace(/\.[^.]+$/, '')

  const { showPremiumFeatures, showHRFeatures } = usePremiumFeature()

  return (
    <Box>
      <Typography
        variant="h6"
        color="primary.dark"
        sx={(theme) => ({
          marginTop: theme.spacing(3),
          marginBottom: theme.spacing(2),
        })}
      >
        Datos básicos
      </Typography>
      <Box
        sx={(theme) => ({
          display: 'grid',
          width: '100%',
          gridTemplateColumns: '1fr',
          marginTop: theme.spacing(2),
        })}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            rowGap: theme.spacing(2),
            columnGap: theme.spacing(3),
            [theme.breakpoints.up('tablet')]: {
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
            },
          })}
        >
          <DocumentTypeField optional={false} />
          <IdentificationNumberField optional={false} />
          <FormField name="name" label="Nombres" optional={false} />
          <FormField name="last_name" label="Apellidos" optional={false} />
          <FormField
            name="email"
            type="email"
            label="Correo electrónico"
            optional={values.active_user === false}
          />
          {!isAlegraClaroDist() ? (
            <FormField
              name="active_user"
              label="¿Permitir que esta Persona tenga acceso web?"
              variant="radio-group"
              tooltipContent={
                <>
                  {messages.allow_web_access({
                    showPremiumFeatures,
                    showHRFeatures,
                  })}
                  {showPremiumFeatures || showHRFeatures ? (
                    <Emoji code={chronometer} />
                  ) : null}
                </>
              }
              row
              options={closedQuestionOptions}
            />
          ) : null}
          <Box sx={{ gridColumn: '1 / -1' }}>
            <FormField
              name="picture"
              label="Cargar foto de la persona"
              variant="picture"
              fileType={['image/jpeg', 'image/png']}
              accept={['image/jpeg', 'image/png']}
              fileName={pictureName}
              applyBorderRadius
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default FormFieldsBasic
