import { Box, Chip, Typography, alpha } from '@mui/material'

import useWorker from 'components/Worker/useWorker'

import { formatLocateDateString } from 'utils/dateTime'
import { getWorkerContractLabel, getWorkerType } from 'utils/worker'

import ProfilePicture from './ProfilePicture'

const WorkerSummary = () => {
  const { worker } = useWorker({ useCache: true })
  const workerType = getWorkerType(worker)
  const workerContractLabel =
    getWorkerContractLabel(worker.contract_detail?.contributor_subtype)?.[
      workerType
    ] || null

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: theme.spacing(3),
        marginBottom: theme.spacing(4),
        [theme.breakpoints.up('tablet')]: {
          flexDirection: 'row',
          alignItems: 'inherit',
        },
      })}
    >
      <ProfilePicture worker={worker} />
      <Box
        sx={(theme) => ({
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100%',
          justifyContent: 'center',
          [theme.breakpoints.down('tablet')]: {
            alignItems: 'center',
            width: 'auto',
            minHeight: 'auto',
          },
        })}
      >
        {workerContractLabel ? (
          <Chip
            label={workerContractLabel}
            data-cy="contract-category-chip"
            sx={(theme) => ({
              color: 'primary.dark',
              backgroundColor: alpha(theme.palette.primary.main, 0.2),
              width: 'fit-content',
              marginBottom: theme.spacing(1),
            })}
          />
        ) : null}
        <Typography
          variant="h2"
          color="black.main"
          sx={(theme) => ({
            marginBottom: theme.spacing(1),
          })}
        >
          {worker?.fullName}
        </Typography>
        <Typography variant="body1" color="black.dark">
          {worker?.position?.name}
        </Typography>
        {worker?.terminated ? (
          <Typography
            variant="body1"
            color="black.dark"
            sx={(theme) => ({
              marginTop: theme.spacing(1),
            })}
          >
            Liquidado el: {formatLocateDateString(worker?.termination.date)}
          </Typography>
        ) : null}
      </Box>
    </Box>
  )
}

export default WorkerSummary
