import { isTest } from 'utils/general'

const useDownloadURI = ({ forceOpen = false } = {}) => {
  return (uri, target = '_blank') => {
    if (!uri || isTest) return

    if (forceOpen) {
      window.open(uri, target)
      return
    }

    const isPdf = new URL(uri)?.search?.includes?.('.pdf')
    const link = document.createElement('a')
    if (isPdf) link.target = target
    document.body.appendChild(link)
    link.style = 'display: none'
    link.href = uri
    link.download = true
    link.click()
    document.body.removeChild(link)
  }
}

export default useDownloadURI
