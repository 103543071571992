import { useMemo } from 'react'
import { useQueryClient } from 'react-query'

import { Box, Grid, Typography } from '@mui/material'

import Icon from 'components/UI/Icon'
import { getDocumentLabel } from 'components/Worker/SSAffiliations/OnboardingWorkers/SSAffiliation/Form/Beneficiaries/helpers'
import useWorker from 'components/Worker/useWorker'

import DownloadFile from '../DownloadFile'
import Item from '../Item'

const kinshipTitle = {
  spouse: 'Cónyuge o compañero',
  progenitor: 'Padres',
  child: 'Hijos',
}

const EmptyState = () => {
  return (
    <Box
      sx={(theme) => ({
        padding: theme.spacing(8, 2, 2, 2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: theme.spacing(2),
        textAlign: 'center',
      })}
    >
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.primary.light,
          width: '6.5rem',
          height: '6.5rem',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        })}
      >
        <Icon name="avatar-search" twoTone sx={{ fontSize: '5rem' }} />
      </Box>
      <Typography variant="h5">
        El empleado no tiene ningún beneficiario registrado
      </Typography>
    </Box>
  )
}

const BeneficiariesContainer = () => {
  const { worker } = useWorker({ useCache: true })
  const queryClient = useQueryClient()
  const affiliationQueryKey = ['getAffiliationById', worker.id]
  const affiliationCache = queryClient.getQueryData(affiliationQueryKey)
  const beneficiaries = useMemo(
    () => affiliationCache?.data?.beneficiaries || [],
    [affiliationCache?.data?.beneficiaries]
  )

  const sectionData = useMemo(() => {
    const sections = {
      spouse: [],
      child: [],
      progenitor: [],
    }

    beneficiaries.forEach((beneficiary, index) => {
      const filesItems = Object.entries(beneficiary.files).map(
        ([key, value]) => {
          return {
            label: getDocumentLabel(beneficiary.kinship, key.slice(0, -5)),
            value: <DownloadFile file={value} />,
          }
        }
      )

      sections[beneficiary.kinship] = [
        ...sections[beneficiary.kinship],
        {
          id: `beneficiary_${index + 1}`,
          name: beneficiary.name,
          itemsList: filesItems,
        },
      ]
    })

    return sections
  }, [beneficiaries])

  return (
    <Box
      sx={(theme) => ({
        padding: theme.spacing(0, 3, 6, 3),
      })}
    >
      <Typography
        variant="h4"
        sx={(theme) => ({
          marginBottom: theme.spacing(5),
        })}
      >
        Beneficiarios
      </Typography>

      {beneficiaries.length === 0 ? <EmptyState /> : null}
      {Object.entries(sectionData).map(([key, section], sectionIndex) => {
        if (sectionData[key].length > 0) {
          return (
            <Box
              key={key}
              sx={(theme) => ({
                ...(sectionIndex < Object.entries(sectionData).length - 1 && {
                  marginBottom: theme.spacing(8),
                }),
              })}
            >
              <Typography
                variant="h6"
                sx={(theme) => ({
                  marginBottom: theme.spacing(2),
                })}
              >
                {kinshipTitle[key]}
              </Typography>
              {section.map((beneficiary) => (
                <Box
                  key={beneficiary.id}
                  sx={(theme) => ({
                    marginBottom: theme.spacing(3),
                  })}
                >
                  <Typography
                    variant="lead1"
                    sx={(theme) => ({
                      marginBottom: theme.spacing(2),
                    })}
                  >
                    {beneficiary.name}
                  </Typography>
                  <Grid container spacing={2}>
                    {beneficiary.itemsList.map((fileItem, index) => (
                      <Item
                        item={fileItem}
                        key={`${key}_file_${index.toString()}`}
                      />
                    ))}
                  </Grid>
                </Box>
              ))}
            </Box>
          )
        }

        return null
      })}
    </Box>
  )
}

export default BeneficiariesContainer
