import { useNavigate } from 'react-router-dom'

import {
  Box,
  Button,
  Breadcrumbs as MBreadcrumbs,
  useMediaQuery,
} from '@mui/material'

import Icon from 'components/UI/Icon'

import Breadcrumb from './Breadcrumb'
import { useBreadcrumbs } from './helpers'

const Breadcrumbs = ({ isCompanyImmediatePayment }) => {
  const navigate = useNavigate()
  const breadcrumbs = useBreadcrumbs()
  const multipleBreadcrumbs = breadcrumbs.length > 1
  const xsDown = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const handleGoBack = () => {
    if (multipleBreadcrumbs) {
      const previousBreadcrumb = breadcrumbs[breadcrumbs.length - 2]
      if (previousBreadcrumb) {
        previousBreadcrumb?.onClick?.()
        navigate(previousBreadcrumb.to)
      } else {
        navigate(-1)
      }
    }
  }

  return (
    <Box
      sx={{
        ...(isCompanyImmediatePayment && {
          filter: 'saturate(0)',
          opacity: 0.5,
          pointerEvents: 'none',
        }),
      }}
    >
      {!xsDown ? (
        <MBreadcrumbs
          aria-label="breadcrumb"
          separator={
            <Icon
              name="arrow-right-2"
              sx={{
                fontSize: '0.8rem',
              }}
            />
          }
          sx={(theme) => ({
            '& .MuiBreadcrumbs-separator': {
              color: theme.palette.black.main,
              margin: theme.spacing(0.5),
            },
          })}
        >
          {breadcrumbs.map((breadcrumb) => {
            return <Breadcrumb key={breadcrumb?.to} {...breadcrumb} />
          })}
        </MBreadcrumbs>
      ) : (
        <>
          {multipleBreadcrumbs ? (
            <Button
              size="small"
              variant="text"
              startIcon={
                <Icon
                  name="arrow-right-2"
                  sx={{ transform: 'rotate(180deg)' }}
                />
              }
              component="a"
              onClick={handleGoBack}
            >
              Regresar
            </Button>
          ) : null}
        </>
      )}
    </Box>
  )
}

export default Breadcrumbs
