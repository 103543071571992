import { isValid } from 'date-fns'
import { cloneDeep } from 'lodash'

import { formatDate } from 'utils/dateTime'
import { filterContractFields } from 'utils/worker'

export const formatWorker = (worker) => {
  const workerCopy = cloneDeep(worker)

  if (!worker.document_type) workerCopy.document_type = 'cc'

  if (!worker.base_salary) workerCopy.base_salary = ''

  if (worker.accumulated_holidays) {
    workerCopy.accumulated_holidays = Number(worker.accumulated_holidays)
  }

  if (worker.enjoyed_days_since_hired) {
    workerCopy.enjoyed_days_since_hired = Number(
      worker.enjoyed_days_since_hired
    )
  } else {
    workerCopy.enjoyed_days_since_hired = 0
  }

  if (typeof worker.transport_subsidy !== 'boolean') {
    // default transport_subsidy
    workerCopy.transport_subsidy = true
  }

  if (typeof worker.active_user !== 'boolean') {
    // default active_user
    workerCopy.active_user = true
  }

  if (
    worker.contract_category !== 'contractor' &&
    worker.contract_category !== 'schooling_trainee' &&
    (!worker.work_center || worker.work_center?.id === null)
  ) {
    workerCopy.work_center = null

    // default high_risk_pension
    workerCopy.high_risk_pension = null
  }

  if (worker.contract_category?.value !== 'contractor') {
    if (!worker.rest_days) workerCopy.rest_days = ['6', '0']
  } else {
    delete workerCopy.rest_days
    delete workerCopy.accumulated_holidays
  }

  // fields that not exists, must be created with null
  // for validation purpose
  if (!worker.email) workerCopy.email = null
  if (worker.account_type) {
    workerCopy.account_type = worker.account_type
  } else {
    workerCopy.account_type = null
  }

  // convert to null if inner property 'id' is null

  if (!worker.location || worker.location.id === null)
    workerCopy.location = null

  if (!worker.area || worker.area.id === null) workerCopy.area = null

  if (!worker.position || worker.position.id === null)
    workerCopy.position = null

  if (!worker.cost_center || worker.cost_center.id === null)
    workerCopy.cost_center = null

  if (!worker.bank || worker.bank.id === null) workerCopy.bank = null

  if (!worker.termination || worker.termination.id === null)
    workerCopy.termination = null

  if (!worker.health_provider || worker.health_provider.id === null)
    workerCopy.health_provider = null

  if (!worker.pension_provider || worker.pension_provider.id === null)
    workerCopy.pension_provider = null

  if (!worker.severance_provider || worker.severance_provider.id === null)
    workerCopy.severance_provider = null

  if (worker.name && worker.last_name)
    workerCopy.fullName = `${worker.name} ${worker.last_name}`

  return workerCopy
}

export const getWorkerDirtyValues = (initialValues, values, isEditing) => {
  const dirtyWorker = {}

  // check for dirty values in current step fields
  Object.keys(values).forEach((key) => {
    const value = values[key]
    const initValue = initialValues ? initialValues[key] : undefined
    const type = typeof value

    if (type === 'object' || type === 'function') {
      if (key === 'rest_days') {
        // strategy to check if rest_days values changed
        const initRestDays = [...initValue].sort()
        const restDays = [...value].sort()

        if (initRestDays.join() !== restDays.join()) dirtyWorker[key] = restDays
      } else if (isEditing) {
        if (value !== initValue) {
          if (value?.id) {
            dirtyWorker[`${key}_id`] = value.id
          } else if (value?.value) {
            dirtyWorker[key] = value.value
          } else if (isValid(value)) {
            dirtyWorker[key] = formatDate(value)
          } else if (value instanceof File) {
            dirtyWorker[key] = { image: value, name: value.name }
          } else if ((value === '' || value === null) && key === 'picture') {
            dirtyWorker[key] = ''
          }
        }
      } else if (value?.id) {
        dirtyWorker[`${key}_id`] = value.id
      } else if (value?.value) {
        dirtyWorker[key] = value.value
      } else if (isValid(value)) {
        dirtyWorker[key] = formatDate(value)
      } else if (value instanceof File) {
        dirtyWorker[key] = { image: value, name: value.name }
      } else if ((value === '' || value === null) && key === 'picture') {
        dirtyWorker[key] = ''
      }
    } else if (!isEditing) {
      dirtyWorker[key] = value
    } else if (value !== initValue) {
      dirtyWorker[key] = value
    }
  })

  const filteredDirtyValues = filterContractFields(
    dirtyWorker,
    values.contract_category,
    values.wage_category,
    values.contributor_subtype
  )

  return { dirtyWorker: filteredDirtyValues }
}
