import { useFormikContext } from 'formik'
import { useContext, useEffect } from 'react'

import { Box, IconButton, OutlinedInput } from '@mui/material'

import Icon from 'components/UI/Icon'

import { SubscriptionViewContext } from '../Index'
import { useSelectPlan } from '../helpers'

const WorkersCounter = () => {
  const { subscription, isSubscriptionPromoPath } = useContext(
    SubscriptionViewContext
  )
  const { values, errors } = useFormikContext()
  const { onChangeWorkersNumber, firstInteraction } = useSelectPlan()
  const isClaraPayment = subscription?.payment_category === 'clara_payment'
  const hasEmployees = subscription?.payrolls_size > 0
  const disableDecreaseButton =
    (isClaraPayment && Number(values?.workers_number) === 20) ||
    values?.workers_number <= 1 ||
    values?.workers_number <= subscription.payrolls_size

  const handleIncrease = () => {
    const value = Number(values?.workers_number || 0) + 1
    onChangeWorkersNumber(value)
  }

  const handleDecrease = () => {
    const value = Number(values?.workers_number || 0) - 1
    onChangeWorkersNumber(value > 0 ? value : 0)
  }

  useEffect(() => {
    if (!hasEmployees && !isSubscriptionPromoPath) {
      onChangeWorkersNumber(1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasEmployees])

  return (
    <Box
      sx={(theme) => ({
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: 'auto 1fr auto',
        columnGap: theme.spacing(1),
      })}
    >
      <IconButton
        aria-label="decrease"
        color="primary"
        onClick={handleDecrease}
        disabled={disableDecreaseButton}
        size="large"
      >
        <Icon name="minus" basic />
      </IconButton>
      <OutlinedInput
        placeholder="1"
        name="workers_number"
        variant="number"
        value={values?.workers_number || subscription.payrolls_size}
        onChange={onChangeWorkersNumber}
        inputProps={{
          type: 'number',
        }}
        error={errors?.workers_number && firstInteraction}
        sx={{
          width: '3.5rem',
          height: '2.5rem',
          '& input[type=number]': {
            MozAppearance: 'textfield',
          },
          '& input[type=number]::-webkit-outer-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
          },
          '& input[type=number]::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
          },
        }}
      />
      <IconButton
        aria-label="increase"
        color="primary"
        onClick={handleIncrease}
        size="large"
      >
        <Icon name="plus" basic />
      </IconButton>
    </Box>
  )
}

export default WorkersCounter
