import { useState } from 'react'

const useIsOkAffiliationModal = () => {
  const [state, setState] = useState({
    open: false,
    workerId: null,
  })

  const closeIsOkAffiliationModal = () => {
    setState((previousState) => ({
      ...previousState,
      open: false,
    }))
  }

  const openIsOkAffiliationModal = ({ workerId }) => {
    setState((previousState) => ({
      ...previousState,
      open: true,
      workerId,
    }))
  }

  return {
    isOkAffiliationModalState: state,
    closeIsOkAffiliationModal,
    openIsOkAffiliationModal,
  }
}

export default useIsOkAffiliationModal
