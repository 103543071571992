import { parseISO } from 'date-fns'
import * as yup from 'yup'

import auth from 'utils/auth'
import { yupLocaleES } from 'utils/form'
import { formatCurrency } from 'utils/format'

yup.setLocale(yupLocaleES)

export const smlmvByYear = {
  2011: 535600,
  2012: 566700,
  2013: 589500,
  2014: 616000,
  2015: 644350,
  2016: 689455,
  2017: 737717,
  2018: 781242,
  2019: 828116,
  2020: 877803,
  2021: 908526,
  2022: 1000000,
  2023: 1160000,
  2024: 1300000,
}

export const payrollRetentionBaseSalaryByYear = {
  2019: 4770183,
  2020: 4538914,
  2021: 5053861,
  2022: 5289934,
  2023: 5903540,
  2024: 6500000,
}

export function formatWorker(worker) {
  const formattedWorker = worker
  formattedWorker.fullName = `${worker.name || ''} ${worker.last_name || ''}`
  formattedWorker.initial_day = worker.initial_day

  if (!worker.health_provider?.id) formattedWorker.health_provider = null
  if (!worker.pension_provider?.id) formattedWorker.pension_provider = null
  if (!worker.severance_provider?.id) formattedWorker.severance_provider = null

  if (worker.end_day) formattedWorker.end_day = worker.end_day
  if (worker.birthday) formattedWorker.birthday = worker.birthday
  if (worker.termination?.date) {
    formattedWorker.termination.date = worker.termination?.date
  }

  return formattedWorker
}

export function getWorkerType(worker) {
  if (worker.terminated) return 'terminated_worker'
  if (worker.active) return worker.contract_category
  return 'inactive_worker'
}

export function getWorkerId() {
  const workerId = localStorage.getItem('worker_id')
  if (
    localStorage.getItem('role') === 'worker' &&
    !workerId &&
    localStorage.getItem('multicompany_user') !== 'true'
  ) {
    auth.logOut()
  }

  return workerId
}

export function getNameWorkerType(workerType, plural) {
  let workerTypeName = 'Sin terminar registro'

  switch (workerType) {
    case 'apprentice':
    case 'schooling_trainee':
    case 'productive_trainee':
    case 'intern':
      workerTypeName = plural ? 'Aprendices' : 'Aprendíz'
      break
    case 'contractor':
      workerTypeName = plural ? 'Contratistas' : 'Contratista'
      break
    case 'employee':
      workerTypeName = plural ? 'Empleados' : 'Empleado'
      break
    case 'inactive_worker':
      workerTypeName = plural ? 'Inactivos' : 'Inactivo'
      break
    case 'student':
    case 'student_decree_055':
    case 'student_law_789':
      workerTypeName = plural ? 'Estudiantes' : 'Estudiante'
      break
    case 'terminated':
    case 'terminated_worker':
      workerTypeName = plural ? 'Liquidados' : 'Liquidado'
      break
    case 'person':
      workerTypeName = plural ? 'Personas' : 'Persona'
      break
    case 'part_time_contract':
      workerTypeName = 'Tiempo parcial'
      break
    case 'all':
      workerTypeName = 'Todos'
      break
    default:
      break
  }

  return workerTypeName
}

/**
 * Convert 'student_decree_055' and 'student_law_789' to 'student', and
 * 'schooling_trainee', 'productive_trainee' and 'intern' to 'apprentice'
 * @param {string} contractCategory
 */
export function getGeneralContractCategory(contractCategory) {
  let category = contractCategory

  if (category === 'student_decree_055' || category === 'student_law_789')
    category = 'student'
  else if (
    category === 'schooling_trainee' ||
    category === 'productive_trainee' ||
    category === 'intern'
  )
    category = 'apprentice'

  return category
}

/**
 * Parse only 'YYYY-MM-DD' String date (API Format) to current "Salario Minimo Legal Vigente".
 * @param {String} dateString
 * @returns {Number}
 */
export function getYearSMLV(date) {
  return smlmvByYear[date.getFullYear()]
}

export function getPayrollRetentionBaseSalaryByYear(date) {
  return payrollRetentionBaseSalaryByYear[date?.getFullYear?.()]
}

export const documentTypes = {
  cc: 'Cédula de ciudadanía',
  ce: 'Cédula de extranjería',
  ti: 'Tarjeta de identidad',
  rc: 'Registro civil',
  pa: 'Pasaporte',
  pe: 'Permiso especial de permanencia',
  ce_no_pension: 'Cédula de extranjería (Exento pensión)',
  pe_no_pension: 'Permiso especial de permanencia (Exento pensión)',
  pt: 'Permiso de protección temporal',
  pt_no_pension: 'Permiso de protección temporal (Exento pensión)',
}

export const documentTypesOptions = Object.keys(documentTypes).map((value) => ({
  value,
  label: documentTypes[value],
}))

export function getDocumentTypeOptions(isLegal = false) {
  const options = documentTypesOptions

  return !isLegal ? options : [...options, { value: 'ni', label: 'NIT' }]
}

export const contractTypes = {
  indefinite: 'Término indefinido',
  fixed: 'Término fijo',
  project: 'Obra o labor',
}

export const contractTypesOptions = Object.keys(contractTypes).map((value) => ({
  value,
  label: contractTypes[value],
}))

export const risks = {
  risk_1: 'Mínimo - Riesgo 1',
  risk_2: 'Bajo - Riesgo 2',
  risk_3: 'Medio - Riesgo 3',
  risk_4: 'Alto - Riesgo 4',
  risk_5: 'Máximo - Riesgo 5',
}

export const risksOptions = Object.keys(risks).map((value) => ({
  value,
  label: risks[value],
}))

export const wageCategories = {
  monthly: 'Mensual',
  per_hour: 'Por hora',
  integral_salary: 'Salario integral',
  part_time: 'Mensual Medio Tiempo',
}

export const wageCategoriesOptions = Object.keys(wageCategories).map(
  (value) => ({
    value,
    label: wageCategories[value],
  })
)

export const contractCategories = {
  employee: 'Empleado',
  contractor: 'Contratista',
  schooling_trainee: 'Aprendiz SENA en etapa lectiva',
  productive_trainee: 'Aprendiz SENA en etapa productiva',
  intern: 'Aprendiz universitario',
  student_law_789: 'Estudiante (ley 789 de 2002)',
  student_decree_055: 'Estudiante (decreto 055)',
  part_time_contract: 'Tiempo parcial',
}

export const contractCategoriesOptions = Object.keys(contractCategories).map(
  (value) => ({
    value,
    label: contractCategories[value],
  })
)

export const getWorkerContractLabel = (contributorSubtype = 'no_subtype') => {
  return {
    ...contractCategories,
    inactive_worker: 'Inactivo',
    terminated_worker: 'Liquidado',
    employee: `Empleado${
      contributorSubtype !== 'no_subtype' ? ' - No aplica pensión' : ''
    }`,
  }
}

export const roles = {
  admin: 'Administrador',
  accountant: 'Contador',
  worker: 'Trabajador',
}

export const rolesOptions = Object.keys(roles).map((rolCode) => ({
  label: roles[rolCode],
  value: rolCode,
}))

export const rolesOptionsWithoutWorker = rolesOptions.filter(
  (role) => role.value !== 'worker'
)

export const restDays = {
  1: 'Lunes',
  2: 'Martes',
  3: 'Miércoles',
  4: 'Jueves',
  5: 'Viernes',
  6: 'Sábado',
  0: 'Domingo',
}

/**
 *
 * @param {Object} options
 * @param {Date} options.date The date from which the year will be obtained
 * @param {'monthly' | 'daily'} options.type  Default
 * @param {number} options.multiplier
 */
export function getMinimumSalary({
  date,
  type = 'monthly',
  multiplier = 1,
  endDay,
} = {}) {
  let year = date ? parseISO(date).getFullYear() : new Date().getFullYear()
  year = year >= 2011 ? year : new Date().getFullYear()

  let minimumSalary = smlmvByYear[year]

  if (date && endDay) {
    const lastYear = endDay ? parseISO(endDay).getFullYear() : null
    const initialYear = date ? parseISO(date).getFullYear() : null

    if (initialYear && lastYear && lastYear > initialYear) {
      minimumSalary = smlmvByYear[lastYear]
    } else {
      minimumSalary = smlmvByYear[year]
    }
  }

  if (type === 'daily') minimumSalary /= 30

  return Math.floor(minimumSalary * multiplier)
}

export const workerCertificates = {
  income_certificate: 'Ingresos y retenciones',
  labor_certificate: 'Laboral',
}

export const salaryTypes = {
  base_salary: 'Salario Base',
  average_salary: 'Salario Promedio',
  no_salary: 'Sin Salario',
}

export const salaryTypeOptions = Object.keys(salaryTypes).map((value) => ({
  value,
  label: salaryTypes[value],
}))

/**
 *
 * @param {Object} object The worker or contract object
 * @param {string} contractCategory
 * @param {string} wageCategory
 */
export function filterContractFields(
  object,
  contractCategory,
  wageCategory,
  workerContributorSubtype
) {
  const objectCopy = { ...object }
  if (contractCategory === 'employee') {
    if (wageCategory === 'integral_salary') {
      delete objectCopy.severance_provider
    }

    if (workerContributorSubtype && workerContributorSubtype !== 'no_subtype') {
      delete objectCopy.pension_provider
    }
  } else if (contractCategory === 'contractor') {
    delete objectCopy.transport_subsidy
    delete objectCopy.risk_type
    delete objectCopy.work_center
    delete objectCopy.health_provider
    delete objectCopy.pension_provider
    delete objectCopy.severance_provider
    delete objectCopy.rest_days
    delete objectCopy.accumulated_holidays
  } else if (contractCategory === 'schooling_trainee') {
    delete objectCopy.risk_type
    delete objectCopy.work_center
    delete objectCopy.pension_provider
    delete objectCopy.severance_provider
  }
  if (
    contractCategory === 'productive_trainee' ||
    contractCategory === 'intern'
  ) {
    delete objectCopy.pension_provider
    delete objectCopy.severance_provider
  } else if (contractCategory === 'student_law_789') {
    delete objectCopy.severance_provider
  } else if (contractCategory === 'student_decree_055') {
    delete objectCopy.health_provider
    delete objectCopy.pension_provider
    delete objectCopy.severance_provider
  }

  return objectCopy
}

export const certificateDocumentOptions = [
  { value: 'pdf', label: 'PDF' },
  { value: 'word', label: 'Word' },
]

export const certificateYearOptions = [
  { value: '2023', label: '2023' },
  { value: '2022', label: '2022' },
]

export const parseDate = (date) => {
  if (typeof date === 'string') return parseISO(date)

  return date
}

export const generateWageBaseSalaryValidationSchema = (
  contractCategoryField = 'contract_category',
  wageCategoryField = 'wage_category',
  initialDayField = 'initial_day'
) => {
  return yup
    .number()
    .nullable()
    .when(
      [contractCategoryField, wageCategoryField, initialDayField, 'end_day'],
      (contractCategory, wageCategory, initialDay, endDay, schema) => {
        let minValue = 0

        if (
          [
            'employee',
            'contractor',
            'student_decree_055',
            'student_law_789',
          ].includes(contractCategory)
        ) {
          return schema.min(minValue)
        }
        if (contractCategory === 'schooling_trainee') {
          minValue = getMinimumSalary({
            multiplier: 0.5,
            date: initialDay,
          })

          return schema.min(
            minValue,
            `Debe ser mayor o igual a ${formatCurrency(minValue)} (1/2 SMLMV).`
          )
        }
        if (contractCategory === 'productive_trainee') {
          if (
            initialDay &&
            [2020, 2021, 2022, 2023, 2024].includes(
              parseDate(initialDay).getFullYear()
            )
          ) {
            minValue = getMinimumSalary({ multiplier: 0.75, date: initialDay })

            return schema.min(
              minValue,
              `Debe ser mayor o igual a ${formatCurrency(
                minValue
              )} (3/4 SMLMV).`
            )
          }
          minValue = getMinimumSalary({ date: initialDay })

          return schema.min(
            minValue,
            `Debe ser mayor o igual a ${formatCurrency(minValue)} (1 SMLMV).`
          )
        }
        if (contractCategory === 'intern') {
          minValue = getMinimumSalary({ date: initialDay })

          return schema.min(
            minValue,
            `Debe ser mayor o igual a ${formatCurrency(minValue)} (1 SMLMV).`
          )
        }
        return schema.positive()
      }
    )
    .required()
}

export const genderOptions = [
  {
    label: 'Femenino',
    value: 'female',
  },
  {
    label: 'Masculino',
    value: 'male',
  },
]

export const nationalityOptions = [
  {
    label: 'Colombiana',
    value: 'colombian',
  },
  {
    label: 'Venezolana',
    value: 'venezuelan',
  },
]

export const maritalStatus = [
  {
    label: 'Soltero',
    value: 'single',
  },
  {
    label: 'Casado',
    value: 'married',
  },
  {
    label: 'Divorciado',
    value: 'divorced',
  },
  {
    label: 'Viudo',
    value: 'widowed',
  },
  {
    label: 'Unión Libre',
    value: 'free_union',
  },
]

export const pensionTypeOptions = [
  {
    label: 'Vejez',
    value: 'old_age',
  },
  {
    label: 'Invalidez',
    value: 'disability',
  },
  {
    label: 'Sobrevivencia',
    value: 'survival',
  },
  {
    label: 'Familiar',
    value: 'familiar',
  },
]

export const educationLevelOptions = [
  {
    label: 'Primaria',
    value: 'primary',
  },
  {
    label: 'Bachiller',
    value: 'high_school',
  },
  {
    label: 'Universitario',
    value: 'undergraduate',
  },
  {
    label: 'Postgrado',
    value: 'postgraduate',
  },
  {
    label: 'Otro',
    value: 'other_education_level',
  },
  {
    label: 'Ninguno',
    value: 'none_education_level',
  },
]

export const socialStratumOptions = [
  {
    label: '1',
    value: 1,
  },
  {
    label: '2',
    value: 2,
  },
  {
    label: '3',
    value: 3,
  },
  {
    label: '4',
    value: 4,
  },
  {
    label: '5',
    value: 5,
  },
  {
    label: '6',
    value: 6,
  },
]

export const closedQuestionOptions = [
  {
    label: 'Sí',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
]

export const houseTypeOptions = [
  {
    label: 'Familiar',
    value: 'family',
  },
  {
    label: 'Propia',
    value: 'own',
  },
  {
    label: 'Arrendada',
    value: 'leased',
  },
]

export const kinshipOptions = [
  {
    label: 'Padre/Madre',
    value: 'progenitor',
  },
  {
    label: 'Hijo/a',
    value: 'child',
  },
  {
    label: 'Hermano/a',
    value: 'sibling',
  },
  {
    label: 'Cónyuge',
    value: 'spouse',
  },
  {
    label: 'Otro',
    value: 'other',
  },
]

export const arusIntegrationDocumentType = [
  {
    label: 'Cédula de ciudadanía',
    value: 'CC',
  },
  {
    label: 'Cédula de extranjería',
    value: 'CE',
  },
  {
    label: 'Pasaporte',
    value: 'PA',
  },
  {
    label: 'Tarjeta de identidad',
    value: 'TI',
  },
  {
    label: 'Registro civil',
    value: 'RC',
  },
  {
    label: 'Carné diplomático',
    value: 'CD',
  },
  {
    label: 'Salvoconducto de permanencia',
    value: 'SC',
  },
  {
    label: 'Permiso especial de permanencia',
    value: 'PE',
  },
  {
    label: 'Permiso de protección temporal',
    value: 'PT',
  },
]

export const contributorSubtype = {
  no_subtype: 'Sin subtipo - Cotiza pensión',
  pensioner: 'Pensionado',
  age_exempt_contributor:
    'Cotizante no obligado a cotización a pensiones por edad',
  qualified_for_pension: 'Cotizante con requisitos cumplidos para pensión',
  compensated_contributor:
    'Cotizante a quien se le ha reconocido indemnización sustitutiva o devolución de saldos',
}

export const contributorSubtypeOptions = Object.keys(contributorSubtype).map(
  (value) => ({
    value,
    label: contributorSubtype[value],
  })
)

export const profileImageRequirements = {
  supported_formats: {
    list: ['image/png', 'image/jpeg'],
    message: 'Formato de imagen no soportado.',
  },
  max_size: {
    value: 1000000,
    message: 'El tamaño del archivo no debe superar 1 MB.',
  },
  min_size: {
    value: 0,
    message:
      '¡No pudimos cargar la imagen! El archivo que intentas subir no tiene contenido o se encuentra dañado. Intenta con uno nuevo.',
  },
}
