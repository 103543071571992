import { forwardRef, useRef } from 'react'

import {
  Box,
  Button,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material'

import LoadedFile from '../CommonFields/LoadedFile'

const FileFieldContent = forwardRef(
  function FileFieldContentForwardRef(props, inputRef) {
    const {
      id,
      name,
      disabled,
      accept, // This prop controls the selection when using the file selector
      fileType, // This prop controls the selection when dragging the file
      setValue,
      selector,
      disableDropzone,
    } = props.fileFieldProps
    const { stopEvent } = props
    const fileInputRef = useRef(null)
    const smUp = useMediaQuery((theme) => theme.breakpoints.up('sm'))

    if (!accept || !fileType) {
      throw new Error(
        `accept and fileType props are required and you provided: accept: ${accept} and fileType: ${fileType}`
      )
    }

    const onFileAdded = (event) => {
      if (disabled) return
      stopEvent(event)

      const file = event.target.files[0]
      setValue(file)
    }

    const openFileDialog = () => {
      if (disabled) return

      fileInputRef.current.click()
    }

    const onDrop = (event) => {
      if (disabled) return
      stopEvent(event)

      const file = event.dataTransfer.files[0]
      if (fileType) {
        if (typeof fileType === 'string' && file?.type === fileType) {
          setValue(file)
        } else if (Array.isArray(fileType) && fileType.includes(file.type)) {
          setValue(file)
        }
      }
    }

    const onDragOver = (event) => stopEvent(event)

    return (
      <Box
        ref={inputRef}
        role="none"
        onDrop={!disableDropzone ? onDrop : undefined}
        onDragOver={!disableDropzone ? onDragOver : undefined}
        onClick={openFileDialog}
        sx={{
          width: '100%',
          display: 'flex',
          cursor: 'pointer',
        }}
      >
        <input
          id={id}
          name={name}
          type="file"
          ref={fileInputRef}
          accept={accept}
          onChange={onFileAdded}
          disabled={disabled}
          data-cy="file-input"
          style={{
            display: 'none',
          }}
        />
        {selector || (
          <Box
            sx={(theme) => ({
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              height: '100%',
              justifyContent: 'space-between',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              gap: theme.spacing(1),
            })}
          >
            <Typography variant="body1" color="black.light" noWrap>
              {smUp ? 'Arrastrar aquí' : 'Arrastrar'}
            </Typography>
            <Button
              color="primary"
              disabled={disabled}
              size="small"
              sx={{
                height: '1.5rem',

                boxShadow: 'none',
                '&:focus': {
                  boxShadow: 'none',
                },
              }}
            >
              <Box
                component="span"
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {smUp ? 'Adjuntar archivo' : 'Adjuntar'}
              </Box>
            </Button>
          </Box>
        )}
      </Box>
    )
  }
)

const FileField = ({ ...props } = {}) => {
  const stopEvent = (event) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const handleDeleteFile = (event) => {
    stopEvent(event)

    props.setValue(null)
  }

  if (props.value) {
    return (
      <LoadedFile
        fileName={props.fileName}
        handleDeleteFile={handleDeleteFile}
        value={props.value}
        hideRemoveButton={props.hideRemoveButton}
      />
    )
  }

  return (
    <TextField
      disabled={props.disabled}
      name={props.name}
      id={props.id}
      InputProps={{
        inputComponent: forwardRef(
          function InputComponentForwardRef(innerProps, inputRef) {
            return (
              <FileFieldContent
                ref={inputRef}
                {...innerProps}
                fileFieldProps={props}
                stopEvent={stopEvent}
              />
            )
          }
        ),
      }}
      sx={{
        ...(props.withoutBorder && {
          '& fieldset': {
            border: 'none !important',
          },
        }),
      }}
    />
  )
}

export default FileField
