import { Box } from '@mui/material'

import { isAlegraClaroDist, isArusDist } from 'utils/auth'

import alegraLogo from 'assets/images/logos/alegra/alegra_logo.svg'
import aleluyaPositive from 'assets/images/logos/aleluya_positive.png'
import arusLogo from 'assets/images/logos/arus/logo_arus_dark.png'

const AuthLogo = (props) => {
  const imgProps = {
    alt: 'Logo aleluya',
    src: aleluyaPositive,
    width: 125,
  }

  if (isArusDist()) {
    imgProps.alt = 'Logo suaporte'
    imgProps.src = arusLogo
    imgProps.width = 245
  } else if (isAlegraClaroDist()) {
    imgProps.alt = 'Logo alegra'
    imgProps.src = alegraLogo
    imgProps.width = 218
  }

  return (
    <Box
      component="img"
      src={imgProps.src}
      alt={imgProps.alt}
      {...props}
      width={imgProps.width}
      height={56}
      loading="lazy"
    />
  )
}

export default AuthLogo
