import { useMutation, useQuery } from 'react-query'

import { useUser } from 'components/App/UserContext/useUser'

import { getCompanyId } from 'utils/company'
import { formatUnixDate } from 'utils/dateTime'
import { getQueryKey } from 'utils/reactQuery'

import {
  createCompany,
  getCompany,
  getWorkersFile,
  updateCompany,
} from 'services/company/companyService'

import useErrorHandler from '../useErrorHandler'

export const useCompanyCreatedAfter = (initialDate) => {
  const {
    company: { created_at: createdAt },
  } = useUser()

  if (!initialDate) return false

  const limitDate = new Date(initialDate.replace('/', ', '))
  const isCreatedAfter = new Date(formatUnixDate(createdAt)) >= limitDate
  return isCreatedAfter
}

const getQueryFunction = (serviceParams) => {
  const { queryKey, company } = serviceParams
  const companyId = getCompanyId()
  const currentQueryKey = getQueryKey(queryKey)

  if (currentQueryKey === 'companyInformation') return getCompany(companyId)

  if (currentQueryKey === 'updateOnboardingStatus')
    return updateCompany(companyId, company)

  return null
}

const getMutationFunction = (mutationData) => {
  const { mutationMethod, company } = mutationData
  const companyId = getCompanyId()

  if (mutationMethod === 'PATCH') return updateCompany(companyId, company)

  if (mutationMethod === 'POST') return createCompany(company)

  if (mutationMethod === 'GET') return getWorkersFile()

  return null
}

export const useCompanyService = ({
  serviceParams = { queryKey: 'companyInformation' },
  queryOptions = {},
  mutationOptions = {},
} = {}) => {
  const { handleError } = useErrorHandler()

  const { data: { data } = {}, ...restCompanyResponse } = useQuery(
    serviceParams.queryKey,
    () => getQueryFunction(serviceParams),
    {
      onError: (error) => {
        handleError(error)
      },
      ...queryOptions,
    }
  )

  const companyMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => {
        handleError(error)
      },
      ...mutationOptions,
    }
  )

  return {
    companyQuery: {
      data,
      ...restCompanyResponse,
    },
    companyMutation,
  }
}
