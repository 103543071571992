import { Form, Formik } from 'formik'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { Box, Button as MButton, Typography } from '@mui/material'

import useModals from 'components/App/ModalsManager/useModals'
import Button from 'components/UI/Button/Button'
import LoadingBox from 'components/UI/Loading/LoadingBox'
import NewSteps from 'components/UI/Steps/NewSteps'

import { getCompanyId } from 'utils/company'
import useAffiliationsService from 'utils/hooks/affiliations/affiliations'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useWorkerService from 'utils/hooks/worker/workerService'

import { COMPANY_EDIT } from 'config/routes'

import AffiliationDataStep from './AffiliationDataStep'
import AttachDocumentsStep from './AttachDocumentsSteps'
import ConfirmAffiliation from './Modals/ConfirmAffiliation'
import CredentialsAlert from './Modals/CredentialsAlert'
import PersonalInformationStep from './PersonalInformationStep'
import {
  formHasChanged,
  getDirtyWorker,
  getInitialValues,
  stepsData,
  validationSchema,
} from './helpers'

const AffiliationForm = ({
  currentStep,
  setCurrentStep,
  handleClose,
  workerId,
  workerName,
}) => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const modals = useModals()
  const { handleError } = useErrorHandler()
  const workerQueryKey = ['getWorkerById', workerId]
  const affiliationQueryKey = ['getAffiliationById', workerId]
  const { workerQuery, workerMutation } = useWorkerService({
    serviceParams: { queryKey: workerQueryKey, workerId },
    queryOptions: {
      enabled: Boolean(workerId),
    },
  })
  const { affiliationsQuery, affiliationsMutation } = useAffiliationsService({
    serviceParams: {
      queryKey: affiliationQueryKey,
      workerId,
    },
    queryOptions: {
      enabled: Boolean(workerId),
      onSuccess: ({ data }) => {
        if (data?.id) {
          setCurrentStep(2)
        }
      },
      onError: (error) => {
        // Avoid displaying the error alert when the error is 0001, since it means that the affiliation does not exist yet
        if (error?.errors?.[0] && error.errors[0]?.code !== '0001') {
          handleError(error)
        }
      },
    },
    mutationOptions: {
      // Avoid displaying the error about administrators' credentials, as the custom alert is displayed instead
      onError: (error) => {
        const isOnlyEntitiesErrors = error.errors?.every((errorItem) =>
          ['3008', '3009', '3010', '3011', '3012'].includes(errorItem.code)
        )

        if (!isOnlyEntitiesErrors) {
          handleError(error)
        }
      },
    },
  })
  const currentWorker = workerQuery.data || {}
  const currentAffiliation = affiliationsQuery.data || {}

  const initialValues = getInitialValues(currentWorker, currentAffiliation)

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep((previousStep) => previousStep - 1)
    } else {
      handleClose()
    }
  }

  const handleAffiliationMutation = (data, onSuccess, onError) => {
    affiliationsMutation.mutate(
      {
        mutationMethod: 'PUT',
        workerId,
        affiliationData: data,
      },
      {
        onSuccess,
        onError,
      }
    )
  }

  const handleConfirmationModal = () => {
    const confirmationModalId = modals.openModal({
      id: 'confirmAffiliation',
      content: <ConfirmAffiliation />,
      modalProps: {
        header: 'Finalizar solicitud de afiliación',
        adornment: [
          {
            variant: 'signature',
            color: 'complementary1.light',
            width: 177,
            height: 195,
            sx: { bottom: '-4.2rem', right: '2rem' },
          },
        ],
        okText: 'Solicitar afiliación',
        cancelText: 'Cancelar',
        onCancel: () => {
          modals.closeAll()
        },
        onOk: () => {
          const confirmationData = new FormData()
          confirmationData.append('status', 'waiting_response')

          modals.setLoadingModal(true)
          handleAffiliationMutation(
            confirmationData,
            async () => {
              await queryClient.invalidateQueries(affiliationQueryKey)
              await queryClient.invalidateQueries([
                'getCompanyOnboardings',
                getCompanyId(),
              ])
              modals.setLoadingModal(false)
              modals.closeAll()
              handleClose()
            },
            (error) => {
              modals.setLoadingModal(false)
              modals.closeModal(confirmationModalId)
              modals.openModal({
                id: 'credentialsAlert',
                content: <CredentialsAlert errors={error.errors} />,
                modalProps: {
                  header: 'Completa la información de tu empresa',
                  okText: 'Ir a empresa',
                  onCancel: () => {
                    modals.closeAll()
                  },
                  onOk: () => {
                    modals.closeAll()
                    handleClose()
                    navigate(COMPANY_EDIT(getCompanyId()), {
                      state: {
                        initialStep: 6,
                      },
                    })
                  },
                  dialogProps: {
                    fullWidth: true,
                    maxWidth: 'sm',
                  },
                },
              })
            }
          )
        },
        dialogProps: {
          fullWidth: true,
          maxWidth: 'sm',
        },
      },
    })
  }

  const onSubmit = (values) => {
    if (currentStep !== 2) {
      const dirtyWorker = getDirtyWorker(values, currentStep)

      if (formHasChanged(values, initialValues, currentStep)) {
        workerMutation.mutate(
          {
            mutationMethod: 'PATCH',
            worker: { id: workerId, ...dirtyWorker },
            workerId,
          },
          {
            onSuccess: async () => {
              await queryClient.invalidateQueries(workerQueryKey, {
                refetchInactive: true,
                refetchActive: true,
                exact: false,
              })
              if (currentStep < stepsData.length - 1) {
                setCurrentStep((previousStep) => previousStep + 1)
              }
            },
          }
        )
      } else if (currentStep < stepsData.length - 1) {
        setCurrentStep((previousStep) => previousStep + 1)
      }
    } else if (currentStep === 2) {
      if (formHasChanged(values, initialValues, currentStep)) {
        const affiliationData = new FormData()

        if (values.identification_document instanceof File) {
          affiliationData.append(
            'identification_document',
            values.identification_document
          )
        }

        if (values.comment && values.comment.length > 0) {
          affiliationData.append('comment', values.comment)
        } else {
          affiliationData.append('comment', '')
        }

        handleAffiliationMutation(affiliationData, async () => {
          await queryClient.invalidateQueries(affiliationQueryKey)
          handleConfirmationModal()
        })
      } else {
        handleConfirmationModal()
      }
    }
  }

  return (
    <Box>
      {currentStep === 0 ? (
        <Typography variant="h3">
          Se afiliará a {workerName} a la Seguridad Social
        </Typography>
      ) : (
        <Box
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'wrap',
            marginBottom: theme.spacing(2),
          })}
        >
          <Typography
            variant="body1"
            color="black.dark"
            sx={(theme) => ({
              marginTop: theme.spacing(1),
            })}
          >
            La información de la persona será utilizada para ayudarte a
            gestionar la afiliación a Seguridad Social, pensión y cesantías.
          </Typography>
        </Box>
      )}
      <Box
        sx={(theme) => ({
          margin: theme.spacing(4, 0, 3, 0),
        })}
      >
        <NewSteps
          stepsData={stepsData}
          current={currentStep}
          progress={currentStep}
          onChangeStep={(step) => setCurrentStep(step)}
        />
      </Box>
      {workerQuery.isLoading || affiliationsQuery.isLoading ? (
        <LoadingBox />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema[currentStep]}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ handleSubmit, values }) => {
            return (
              <Form>
                <Typography
                  variant="h6"
                  sx={(theme) => ({
                    marginBottom: theme.spacing(2),
                  })}
                  color="primary.dark"
                >
                  {stepsData[currentStep]?.pageTitle}
                </Typography>
                {currentStep === 0 ? <PersonalInformationStep /> : null}
                {currentStep === 1 ? (
                  <AffiliationDataStep worker={currentWorker} />
                ) : null}
                {currentStep === 2 ? <AttachDocumentsStep /> : null}
                <Box
                  sx={(theme) => ({
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    gap: theme.spacing(2),
                    marginTop: theme.spacing(6),
                    flexWrap: 'wrap',
                    [theme.breakpoints.up('tablet')]: {
                      justifyContent: 'flex-start',
                    },
                  })}
                >
                  <Button
                    onClick={handleSubmit}
                    type="submit"
                    loading={
                      workerMutation.isLoading || affiliationsMutation.isLoading
                    }
                    disabled={
                      currentStep === 2 &&
                      (!values.identification_document ||
                        currentAffiliation?.status === 'waiting_response')
                    }
                  >
                    {currentStep === 2
                      ? 'Solicitar afiliación'
                      : 'Guardar y continuar'}
                  </Button>
                  <MButton variant="outlined" onClick={handleBack}>
                    {currentStep === 0 ? 'Cancelar' : 'Volver al paso anterior'}
                  </MButton>
                </Box>
              </Form>
            )
          }}
        </Formik>
      )}
    </Box>
  )
}

export default AffiliationForm
