import Modal from 'components/UI/Modal/Modal'

import WorkerBenefitsIndex from '../Worker/Index'

const CreditDetailModal = ({ onCloseModal, selectedCredit }) => {
  return (
    <Modal
      open
      header="Información crédito"
      onCancel={onCloseModal}
      dialogProps={{
        maxWidth: 'md',
        fullWidth: true,
      }}
      footer={null}
    >
      <WorkerBenefitsIndex usedByAdmin creditStatusData={selectedCredit} />
    </Modal>
  )
}
export default CreditDetailModal
