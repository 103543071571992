import { formatLocateDateString, getDaysOfWeekFromArray } from 'utils/dateTime'
import { formatCurrency, formatNumberWithDecimal } from 'utils/format'
import {
  contractCategories,
  contractTypes,
  contributorSubtype,
  wageCategories,
} from 'utils/worker'

const getSectionData = ({
  worker,
  contractDetail,
  contractCategory,
  isFromAffiliationView,
  setEditWageModal,
  openGeneralDataModal,
  isRegistrationFinished,
  handleFinishPersonRegistration,
}) => {
  const data = []

  const salaryBase = {
    label: 'Salario base',
    value: wageCategories[worker.wage_category]
      ? wageCategories[worker.wage_category]
      : worker.wage_category,
    icon: 'coin',
  }
  const salaryQuantity = {
    label: isFromAffiliationView ? 'Salario' : 'Cantidad',
    value: formatCurrency(worker.base_salary),
    icon: 'coin',
  }

  const transportSubsidy = {
    label: 'Subsidio de transporte',
    value: worker.transport_subsidy ? 'Sí' : 'No',
    icon: 'bus-front',
  }

  const contractDate = {
    label: 'Fecha de contratación',
    value: formatLocateDateString(worker.initial_day),
    icon: 'calendar',
  }

  const healthProvider = {
    label: 'EPS',
    value: worker.health_provider?.name,
    hidden: ['contractor', 'student_decree_055', 'part_time_contract'].includes(
      contractCategory
    ),
    icon: 'medical-insurance',
  }

  const severanceProvider = {
    label: 'Fondo de cesantías',
    value: worker.severance_provider?.name,
    hidden:
      !['employee', 'part_time_contract'].includes(contractCategory) ||
      worker.wage_category === 'integral_salary',
    icon: 'document-coin',
  }

  const arlProvider = {
    label: 'ARL',
    value: worker.risk_provider?.name,
    icon: 'shield-security',
  }

  const compensationProvider = {
    label: 'Caja de compensación familiar',
    value: worker.compensation_fund?.name,
    icon: 'family',
  }

  const department = {
    label: 'Departamento donde labora',
    value: worker.department,
    icon: 'location-pin',
  }

  const city = {
    label: 'Ciudad donde labora',
    value: worker.city,
    icon: 'location-pin',
  }

  const pensionProvider = {
    label: 'Fondo de pensiones',
    value: worker.pension_provider?.name,
    hidden:
      !['employee', 'student_law_789', 'part_time_contract'].includes(
        contractCategory
      ) ||
      ['pe_no_pension', 'ce_no_pension', 'pt_no_pension'].includes(
        worker.document_type
      ) ||
      contractDetail.contributor_subtype !== 'no_subtype',
    icon: 'coins',
  }

  const contractCategoryItem = {
    label: 'Tipo de contrato',
    value: contractCategories[contractCategory]
      ? contractCategories[contractCategory]
      : contractCategory,
    icon: 'document-signature',
  }

  const contractType = {
    label: 'Término de contrato',
    value: contractTypes[worker.term]
      ? contractTypes[worker.term]
      : worker.term,
    icon: 'document',
  }

  const contractFinalDate = {
    label:
      worker.term === 'fixed'
        ? 'Fecha terminación del contrato'
        : 'Fecha estimada de finalización de la obra o labor',
    value: formatLocateDateString(worker.end_day),
    hidden: !['fixed', 'project'].includes(worker.term),
    icon: 'calendar',
  }

  const accumulatedHolidays = {
    label: 'Vacaciones acumuladas',
    value: `${formatNumberWithDecimal(worker.accumulated_holidays, 1)} días`,
    hidden: [
      'contractor',
      'student',
      'apprentice',
      'terminated_worker',
    ].includes(contractCategory),
    icon: 'flip-flops',
  }

  const workCenter = {
    label: 'Centro de trabajo - Nivel de riesgo',
    value: worker?.work_center?.name,
    hidden: ['contractor', 'schooling_trainee'].includes(contractCategory),
    icon: 'signal',
  }

  const highRiskPension = {
    label: '¿Es una persona de alto riesgo de acuerdo al decreto 2090 de 2003?',
    value: worker.high_risk_pension ? 'Si' : 'No',
    hidden:
      worker.high_risk_pension === null ||
      contractDetail?.contributor_subtype !== 'no_subtype' ||
      !worker.work_center?.risk_type?.includes('5'),
    icon: 'medical-insurance',
  }

  const restDays = {
    label: 'Días de descanso',
    value: getDaysOfWeekFromArray(worker.rest_days),
    hidden: !(contractCategory === 'employee'),
    icon: 'calendar',
  }

  const contributorSubtypeData = {
    label: 'Subtipo de cotizante',
    value: contributorSubtype[contractDetail.contributor_subtype],
    hidden:
      !['employee', 'part_time_contract'].includes(contractCategory) ||
      contractDetail.contributor_subtype === 'no_subtype',
    icon: 'document',
  }

  const location = {
    label: 'Sede de Trabajo',
    value: worker.location?.name,
    icon: 'location-pin',
  }

  const costCenter = {
    label: 'Centro de Costos',
    value: worker.cost_center?.name,
    icon: 'edit-document',
  }

  const area = {
    label: 'Área',
    value: worker.area?.name,
    icon: 'suitcase',
  }

  const position = {
    label: 'Cargo',
    value: worker.position?.name,
    icon: 'suitcase',
  }

  const handleClick = ({ section, modalType, title }) => {
    if (
      ['moneySection', 'contractSection', 'entitiesSection'].includes(
        section
      ) &&
      !isRegistrationFinished
    ) {
      return handleFinishPersonRegistration()
    }

    if (section === 'moneySection') {
      return setEditWageModal((previousState) => ({
        ...previousState,
        open: true,
      }))
    }

    if (
      ['contractSection', 'entitiesSection', 'jobPositionSection'].includes(
        section
      )
    ) {
      return openGeneralDataModal(title, modalType)
    }
  }

  if (isFromAffiliationView) {
    data.push({
      id: 'entities',
      title: 'Administradoras',
      onClick: () => {},
      buttonCyLabel: 'edit_wage_button',
      containerCyLabel: 'wage_container',
      hiddenEdit: isFromAffiliationView,
      showDivider: false,
      itemsList: [
        position,
        salaryQuantity,
        contractDate,
        healthProvider,
        pensionProvider,
        severanceProvider,
        arlProvider,
        workCenter,
        compensationProvider,
        department,
        city,
      ],
    })
  } else {
    data.push(
      {
        id: 'moneySection',
        title: contractCategory === 'contractor' ? 'Honorarios' : 'Salario',
        onClick: () => handleClick({ section: 'moneySection' }),
        buttonCyLabel: 'edit_wage_button',
        containerCyLabel: 'wage_container',
        itemsList: [salaryQuantity, salaryBase, transportSubsidy],
      },
      {
        id: 'contractSection',
        title: 'Datos de contrato',
        onClick: () =>
          handleClick({
            section: 'contractSection',
            title: 'Datos de contrato',
            modalType: 'contract_data',
          }),
        buttonCyLabel: 'edit_contract_button',
        containerCyLabel: 'contract_container',
        itemsList: [
          contractCategoryItem,
          contractType,
          contractDate,
          contractFinalDate,
          accumulatedHolidays,
          restDays,
          contributorSubtypeData,
          workCenter,
          highRiskPension,
        ],
      },
      {
        id: 'entitiesSection',
        title: 'Entidades',
        onClick: () =>
          handleClick({
            section: 'entitiesSection',
            title: 'Entidades',
            modalType: 'entities',
          }),
        buttonCyLabel: 'edit_entities_button',
        containerCyLabel: 'entities_container',
        hidden: ['contractor', 'student_decree_055'].includes(contractCategory),
        itemsList: [healthProvider, severanceProvider, pensionProvider],
      },
      {
        id: 'jobPositionSection',
        title: 'Datos puesto de trabajo',
        onClick: () =>
          handleClick({
            section: 'jobPositionSection',
            title: 'Datos de Puesto de Trabajo',
            modalType: 'job_position_data',
          }),
        buttonCyLabel: 'edit_job_position_button',
        containerCyLabel: 'job_position_container',
        showDivider: false,
        itemsList: [location, costCenter, area, position],
      }
    )
  }

  return data
}

export default getSectionData
