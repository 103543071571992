import alegra from './alegra'
import aleluya from './aleluya'
import arus from './arus'

export const colors = {
  nominapp: aleluya,
  alegra,
  arus,
}

export const alertColors = {
  error: 'rgba(255, 231, 231, 1)',
  success: 'rgba(236, 252, 244, 1)',
  info: 'rgba(219, 233, 254, 1)',
  warning: 'rgba(255, 248, 219, 1)',
}

export * from './utils'
