import * as yup from 'yup'

import { Box, Button, Typography } from '@mui/material'

import LoadedFile from 'components/UI/Formik/CommonFields/LoadedFile'
import FormField from 'components/UI/Formik/FormField/Index'
import Icon from 'components/UI/Icon'

import { yupLocaleES } from 'utils/form'
import { getFileNameFromUrl, isValidUrl } from 'utils/general'

yup.setLocale(yupLocaleES)

export const kinshipLabel = {
  spouse: 'Cónyuge',
  child: 'Hijos',
  progenitor: 'Madre o padre',
}

export const kinshipOptions = Object.entries(kinshipLabel).map(
  ([key, value]) => ({
    label: value,
    value: key,
  })
)

export const remainKinshipOptions = (beneficiaries, currentKinship) => {
  const canAddProgenitor =
    beneficiaries?.filter((beneficiary) => beneficiary.kinship === 'progenitor')
      ?.length < 2
  const canAddSpouse =
    beneficiaries?.filter((beneficiary) => beneficiary.kinship === 'spouse')
      ?.length < 1
  let options = [...kinshipOptions]

  if (!['spouse', 'progenitor'].includes(currentKinship)) {
    if (!canAddProgenitor) {
      options = options.filter((item) => item.value !== 'progenitor')
    }

    if (!canAddSpouse) {
      options = options.filter((item) => item.value !== 'spouse')
    }
  } else {
    if (currentKinship === 'progenitor') {
      if (!canAddSpouse) {
        options = options.filter((item) => item.value !== 'spouse')
      }
    }

    if (currentKinship === 'spouse') {
      if (!canAddProgenitor) {
        options = options.filter((item) => item.value !== 'progenitor')
      }
    }
  }

  return options
}

const DocumentSelector = ({ children, className }) => {
  return (
    <Button
      size="small"
      variant="text"
      className={className}
      startIcon={<Icon name="upload" basic />}
    >
      <Box
        component="span"
        sx={{
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      >
        {children}
      </Box>
    </Button>
  )
}

const documentName = {
  spouse: {
    civil_registration: 'Registro civil de matrimonio',
    identification_document: 'Cédula del cónyuge',
  },
  child: {
    identification_document: 'Documento de identificación',
    civil_registration: 'Registro civil de nacimiento',
    study_document: 'Certificado de estudios',
  },
  progenitor: {
    identification_document: 'Cédula de padre o madre',
  },
}
export const getDocumentLabel = (kinship, documentType) =>
  documentName?.[kinship]?.[documentType] || ''

export const getFilesName = (files) => {
  const names = {}

  Object.entries(files).forEach(([key, value]) => {
    const documentUrl = isValidUrl(value)
    const fileName = getFileNameFromUrl(
      documentUrl ? documentUrl.search : undefined
    )

    names[key] = fileName
  })

  return names
}

export const attachDocuments = ({ kinship, files = {} }) => {
  const names = getFilesName(files)

  if (!kinship) return null

  return (
    <>
      <FormField
        name="files.identification_document_file"
        variant="file"
        fileType="application/pdf"
        accept="application/pdf"
        disableDropzone
        fullWidth={false}
        fileName={names.identification_document_file}
        optional={false}
        selector={
          <DocumentSelector>
            {getDocumentLabel(kinship, 'identification_document')}
          </DocumentSelector>
        }
        withoutBorder
      />
      {['spouse', 'child'].includes(kinship) ? (
        <FormField
          name="files.civil_registration_file"
          variant="file"
          fileType="application/pdf"
          accept="application/pdf"
          disableDropzone
          fullWidth={false}
          fileName={names.civil_registration_file}
          optional={false}
          selector={
            <DocumentSelector>
              {getDocumentLabel(kinship, 'civil_registration')}
            </DocumentSelector>
          }
          withoutBorder
        />
      ) : null}
      {kinship === 'child' ? (
        <FormField
          name="files.study_document_file"
          variant="file"
          fileType="application/pdf"
          accept="application/pdf"
          disableDropzone
          fullWidth={false}
          fileName={names.study_document_file}
          optional={false}
          selector={
            <DocumentSelector>
              {getDocumentLabel(kinship, 'study_document')}
            </DocumentSelector>
          }
          withoutBorder
        />
      ) : null}
    </>
  )
}

const DownloadButton = ({ onClick }) => {
  return (
    <Button
      onClick={onClick}
      sx={{
        padding: 0,
        minWidth: 'auto',
        width: 'fit-content',
        backgroundColor: 'transparent',
        textDecoration: 'underline',
        '&:hover': {
          backgroundColor: 'transparent',
          textDecoration: 'underline',
        },
        '&:focus': {
          boxShadow: 'none',
        },
      }}
    >
      <Typography
        variant="lead2"
        sx={(theme) => ({ color: theme.palette.black.dark })}
      >
        Ver
      </Typography>
    </Button>
  )
}

export const getAttachedDocuments = ({ kinship, files, downloadURI }) => {
  const names = getFilesName(files)

  const handleClick = (documentType) => {
    downloadURI(files[documentType])
  }

  return (
    <>
      {names.identification_document_file ? (
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            minWidth: 0,
            gap: theme.spacing(0.5),
          })}
        >
          <Typography variant="lead2">
            {getDocumentLabel(kinship, 'identification_document')}
          </Typography>
          <Box
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              gap: theme.spacing(2),
            })}
          >
            <LoadedFile
              fileName={names.identification_document_file}
              hideRemoveButton
            />
            <DownloadButton
              onClick={() => handleClick('identification_document_file')}
            />
          </Box>
        </Box>
      ) : null}
      {['spouse', 'child'].includes(kinship) &&
      names.civil_registration_file ? (
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            minWidth: 0,
            gap: theme.spacing(0.5),
          })}
        >
          <Typography variant="lead2">
            {getDocumentLabel(kinship, 'civil_registration')}
          </Typography>
          <Box
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              gap: theme.spacing(2),
            })}
          >
            <LoadedFile
              fileName={names.civil_registration_file}
              hideRemoveButton
            />
            <DownloadButton
              onClick={() => handleClick('civil_registration_file')}
            />
          </Box>
        </Box>
      ) : null}
      {kinship === 'child' && names.study_document_file ? (
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            minWidth: 0,
            gap: theme.spacing(0.5),
          })}
        >
          <Typography variant="lead2">
            {getDocumentLabel(kinship, 'study_document')}
          </Typography>
          <Box
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              gap: theme.spacing(2),
            })}
          >
            <LoadedFile fileName={names.study_document_file} hideRemoveButton />
            <DownloadButton
              onClick={() => handleClick('study_document_file')}
            />
          </Box>
        </Box>
      ) : null}
    </>
  )
}

export const validationSchema = yup.object({
  name: yup.string().nullable().required(),
  kinship: yup.string().nullable().required(),
  files: yup
    .object()
    .when('kinship', {
      is: (kinship) => kinship === 'spouse',
      then: yup.object().shape(
        {
          civil_registration_file: yup
            .mixed()
            .when('identification_document_file', {
              is: (identificationDocument) => {
                if (
                  identificationDocument &&
                  (identificationDocument instanceof File ||
                    typeof identificationDocument === 'string')
                ) {
                  return true
                }

                return false
              },
              then: yup.mixed(),
              otherwise: yup
                .mixed()
                .test('civil_registration_file', null, (value) => {
                  if (!(typeof value === 'string')) {
                    return value instanceof File
                  }

                  return Boolean(value)
                })
                .required(),
            }),
          identification_document_file: yup
            .mixed()
            .when('civil_registration_file', {
              is: (civilRegistration) => {
                if (
                  civilRegistration &&
                  (civilRegistration instanceof File ||
                    typeof civilRegistration === 'string')
                ) {
                  return true
                }

                return false
              },
              then: yup.mixed(),
              otherwise: yup
                .mixed()
                .test('identification_document_file', null, (value) => {
                  if (!(typeof value === 'string')) {
                    return value instanceof File
                  }

                  return Boolean(value)
                })
                .required(),
            }),
        },
        ['identification_document_file', 'civil_registration_file']
      ),
    })
    .when('kinship', {
      is: (kinship) => kinship === 'child',
      then: yup.object().shape(
        {
          civil_registration_file: yup
            .mixed()
            .when(['identification_document_file', 'study_document_file'], {
              is: (identificationDocument, studyDocument) => {
                if (
                  identificationDocument &&
                  (identificationDocument instanceof File ||
                    typeof identificationDocument === 'string')
                ) {
                  return true
                }

                if (
                  studyDocument &&
                  (studyDocument instanceof File ||
                    typeof studyDocument === 'string')
                ) {
                  return true
                }

                return false
              },
              then: yup.mixed(),
              otherwise: yup
                .mixed()
                .test('civil_registration_file', null, (value) => {
                  if (!(typeof value === 'string')) {
                    return value instanceof File
                  }

                  return Boolean(value)
                })
                .required(),
            }),
          identification_document_file: yup
            .mixed()
            .when(['civil_registration_file', 'study_document_file'], {
              is: (civilRegistration, studyDocument) => {
                if (
                  civilRegistration &&
                  (civilRegistration instanceof File ||
                    typeof civilRegistration === 'string')
                ) {
                  return true
                }

                if (
                  studyDocument &&
                  (studyDocument instanceof File ||
                    typeof studyDocument === 'string')
                ) {
                  return true
                }

                return false
              },
              then: yup.mixed(),
              otherwise: yup
                .mixed()
                .test('identification_document_file', null, (value) => {
                  if (!(typeof value === 'string')) {
                    return value instanceof File
                  }

                  return Boolean(value)
                })
                .required(),
            }),
          study_document_file: yup
            .mixed()
            .when(['identification_document_file', 'civil_registration_file'], {
              is: (identificationDocument, civilRegistration) => {
                if (
                  civilRegistration &&
                  (civilRegistration instanceof File ||
                    typeof civilRegistration === 'string')
                ) {
                  return true
                }

                if (
                  identificationDocument &&
                  (identificationDocument instanceof File ||
                    typeof identificationDocument === 'string')
                ) {
                  return true
                }

                return false
              },
              then: yup.mixed(),
              otherwise: yup
                .mixed()
                .test('study_document_file', null, (value) => {
                  if (!(typeof value === 'string')) {
                    return value instanceof File
                  }

                  return Boolean(value)
                })
                .required(),
            }),
        },
        [
          ['civil_registration_file', 'identification_document_file'],
          ['civil_registration_file', 'study_document_file'],
          ['identification_document_file', 'study_document_file'],
        ]
      ),
    })
    .when('kinship', {
      is: (kinship) => kinship === 'progenitor',
      then: yup.object().shape(
        {
          identification_document_file: yup
            .mixed()
            .test('identification_document_file', null, (value) => {
              if (!(typeof value === 'string')) {
                return value instanceof File
              }

              return Boolean(value)
            })
            .required(),
        },
        ['identification_document_file', 'identification_document_file']
      ),
    }),
})
