import StatusBanner from 'components/Benefits/Atoms/StatusBanner'
import Loading from 'components/UI/Loading/Loading'

import CreditDetails from './CreditDetails'
import { useCreditDetail } from './helpers'

const BenefitsIndex = ({ usedByAdmin = false, creditStatusData }) => {
  const creditStatusActive = creditStatusData?.status === 'active'

  const { creditDetailData, isGettingCreditDetail } = useCreditDetail(
    creditStatusData?.id,
    creditStatusActive,
    creditStatusData?.workerId
  )

  if (creditStatusData?.status === 'without_preapproved_credit') {
    return (
      <StatusBanner
        title="Aún no tienes un crédito preaprobado"
        message="Cuando tengas un crédito preaprobado te enviaremos un correo electrónico para que puedas iniciar tu solicitud. Con tu crédito de libranza el valor de tus cuotas será descontado de tu nómina de forma automática."
        image={null}
        imageAlt="Image you have not sent any request"
      />
    )
  }

  if (isGettingCreditDetail) return <Loading />

  if (creditStatusData?.status === 'active') {
    return <CreditDetails data={creditDetailData} usedByAdmin={usedByAdmin} />
  }

  return (
    <StatusBanner
      title="Aún no tienes un crédito preaprobado"
      message="Cuando tengas un crédito preaprobado te enviaremos un correo electrónico para que puedas iniciar tu solicitud. Con tu crédito de libranza el valor de tus cuotas será descontado de tu nómina de forma automática."
      image={null}
      imageAlt="Image you have not sent any request"
    />
  )
}

export default BenefitsIndex
