import { Link } from 'react-router-dom'

import { Button } from '@mui/material'

import ButtonAddWorker from 'components/UI/Button/ButtonAddWorker'
import FilterButton from 'components/UI/Button/FilterButton'
import Icon from 'components/UI/Icon'

import * as routes from 'config/routes'

import WorkerActions from './WorkerActions'

const useTableActions = ({
  activeContract,
  setActiveContract,
  activeLocation,
  setActiveLocation,
  locationData,
  workerTotalData,
  tab,
  showCreditsButton,
  handleAddWorkerManually,
  openNewWorkerModal,
  payrollsSize,
}) => {
  const locationOptions = locationData.map((location) => ({
    label: location.name,
    value: location.id,
  }))

  const getContractOptions = () => {
    if (tab === 'active') {
      const activeWorkers = workerTotalData?.filter(
        (category) =>
          !['all', 'inactive', 'terminated_worker', 'new_contracts'].includes(
            category?.coded_name
          )
      )

      return activeWorkers?.map((category) => {
        if (category?.coded_name === 'active') {
          return {
            label: `Todos (${category.size})`,
            value: 'active',
          }
        }
        return {
          label: `${category.name} (${category.size})`,
          value: category.coded_name,
        }
      })
    }

    if (tab === 'disabled') {
      const inactiveWorkers = workerTotalData?.filter((category) =>
        ['inactive', 'terminated_worker'].includes(category?.coded_name)
      )
      const totalDisabledWorkers = inactiveWorkers?.reduce(
        (acc, category) => acc + (category?.size || 0),
        0
      )

      return [
        {
          label: `Todos (${totalDisabledWorkers})`,
          value: 'disabled',
        },
      ].concat(
        inactiveWorkers?.map((category) => ({
          label: `${category.name} (${category.size})`,
          value: category.coded_name,
        }))
      )
    }

    return workerTotalData
      .filter((category) => category.coded_name !== 'new_contracts')
      .map((category) => ({
        label: `${category.name} (${category.size})`,
        value: category.coded_name,
      }))
  }

  const handleCleanFilter = () => {
    if (tab === 'active') {
      setActiveContract('active')
    } else {
      setActiveContract('disabled')
    }
  }

  const actions = [
    {
      id: 'contractFilter',
      isFreeAction: true,
      position: 'left',
      Component: (
        <FilterButton
          defaultLabel="Contrato"
          options={getContractOptions()}
          value={activeContract}
          onChange={setActiveContract}
          onCleanFilter={handleCleanFilter}
          dataCy="contract-category-filter"
        />
      ),
    },
    {
      id: 'locationFilter',
      isFreeAction: true,
      position: 'left',
      Component: (
        <FilterButton
          defaultLabel="Sedes"
          options={locationOptions}
          value={activeLocation}
          onChange={setActiveLocation}
        />
      ),
    },
    (rowData) => ({
      id: 'contractTermination',
      tooltip: 'Liquidar persona',
      icon: <Icon name="coin" basic />,
      buttonProps: {
        component: Link,
        to: routes.WORKER_CONTRACT_TERMINATION_NEW(
          rowData.id,
          rowData.contract_id
        ),
        onClick: undefined,
      },
      hidden:
        rowData.terminated ||
        !rowData.active ||
        [
          'student_law_789',
          'productive_trainee',
          'student_decree_055',
          'contractor',
          'terminated',
        ].includes(rowData.contract_category),
    }),
    (rowData) => ({
      id: 'seeProfile',
      tooltip: 'Ver perfil',
      icon: <Icon name="eye" basic />,
      buttonProps: {
        component: Link,
        to: routes.WORKER_SHOW(rowData.id),
        onClick: undefined,
      },
    }),
    {
      id: 'benefits',
      endIcon: <Icon name="call-hand" basic />,
      isFreeAction: true,
      Component: (
        <Button
          component={Link}
          variant="outlined"
          to={routes.WORKER_BENEFITS_SHOW()}
        >
          Beneficios empleados
        </Button>
      ),
      hidden: !showCreditsButton,
    },
    {
      id: 'downloadInformation',
      isFreeAction: true,
      Component: <WorkerActions />,
    },
  ]

  if (tab !== 'disabled' && payrollsSize > 0)
    actions.push({
      id: 'wagesUpdate',
      isFreeAction: true,
      Component: (
        <Button
          component={Link}
          variant="outlined"
          to={routes.WORKER_WAGES_UPDATE()}
        >
          Editar salarios
        </Button>
      ),
    })

  if (tab !== 'disabled')
    actions.push({
      id: 'addWorker',
      isFreeAction: true,
      Component: (
        <ButtonAddWorker
          handleAddWorkerManually={handleAddWorkerManually}
          openNewWorkerModal={openNewWorkerModal}
        />
      ),
    })

  return actions
}

export default useTableActions
