import { useNavigate } from 'react-router-dom'

import { Box, Typography } from '@mui/material'

import { useUser } from 'components/App/UserContext/useUser'
import Button from 'components/UI/Button/Button'
import OrganizationEmail from 'components/UI/OrganizationEmail'

import { isAuthenticated } from 'utils/auth'
import { hasSelectedCompany } from 'utils/company'

import {
  DASHBOARD,
  ORGANIZER_COMPANY_INDEX,
  WORKER_PROFILE_SHOW,
} from 'config/routes'

const ErrorPageAtom = ({
  subtitle,
  description,
  image,
  details = null,
  footer,
  handleResetError = () => {},
}) => {
  const isAuthenticatedUser = isAuthenticated()
  const navigate = useNavigate()
  const { isWorker, isOrganizer } = useUser()

  const handleReloadPage = () => {
    window.location.reload()
  }

  const handleGoBack = () => {
    if (isWorker) return navigate(WORKER_PROFILE_SHOW())

    if (isOrganizer && !hasSelectedCompany())
      return navigate(ORGANIZER_COMPANY_INDEX())

    return navigate(DASHBOARD)
  }

  const getGoBackText = () => {
    if (isWorker) return 'Regresar a mi perfil'

    if (isOrganizer && !hasSelectedCompany()) return 'Regresar a empresas'

    return 'Regresar a la torre de control'
  }

  return (
    <>
      <Box
        component="section"
        sx={(theme) => ({
          display: 'grid',
          gridTemplateAreas: '"main aside" "main aside"',
          gridTemplateRows: '1fr',
          height: '90vh',
          alignItems: 'center',
          '& section:first-of-type': {
            gridArea: 'main',
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(3.75),
            alignItems: 'flex-start',
          },
          [theme.breakpoints.down('md')]: {
            height: '100%',
            gridTemplateAreas: '"aside aside" "main main"',
            justifyContent: 'center',
            justifyItems: 'center',
            margin: theme.spacing(4, 0),
            '& section:first-of-type': {
              gap: theme.spacing(2),
            },
          },
          [theme.breakpoints.down('sm')]: {
            gridTemplateRows: '0.4fr 0.5fr',
            '& aside > img': {
              width: '18rem',
              height: '20rem',
            },
          },
        })}
      >
        <section>
          <Typography variant="h2">Lo sentimos</Typography>
          <Typography variant="h3">{subtitle}</Typography>
          <Typography>{description}</Typography>
          <Typography>{details}</Typography>

          {isAuthenticatedUser ? (
            <Button
              onClick={() => {
                handleGoBack()
                handleResetError()
              }}
            >
              {getGoBackText()}
            </Button>
          ) : (
            <Button onClick={handleReloadPage}>Recargar la página</Button>
          )}
        </section>
        <aside>
          <img src={image} alt="robot not found" height={475} />
        </aside>
      </Box>
      <Box
        component="footer"
        sx={{
          gridArea: 'footer',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Typography>
          {footer || (
            <>
              Si requieres que alguien te rescate, escríbenos a{' '}
              <OrganizationEmail />.{' '}
            </>
          )}
        </Typography>
      </Box>
    </>
  )
}

export default ErrorPageAtom
