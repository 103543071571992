import aleluyaColors from './aleluya'

const ALEGRA_COLORS = {
  ...aleluyaColors,
  PRIMARY: {
    dark: 'rgb(0, 70, 62)',
    main: 'rgb(0, 177, 157)',
    light: 'rgb(189, 227, 220)',
  },
}

export default ALEGRA_COLORS
