import { useState } from 'react'
import * as yup from 'yup'

import { Box, Paper, Typography } from '@mui/material'

import FormField from 'components/UI/Formik/FormField/Index'
import LordIcon from 'components/UI/LordIcon'
import TooltipInfoIcon from 'components/UI/MaterialUI/TooltipInfoIcon'
import Slider from 'components/UI/Slider'

import {
  bank,
  chartWithUpwardsTrendAndYenSign,
  compass,
  creditCard,
  cryEmoji,
  houseWithGarden,
  moneyBag,
  oncomingAutomobile,
  policeCarsRevolvingLight,
  wingedMoney,
} from 'utils/emojis'
import { formatCurrency } from 'utils/format'

const monthlyCashFlowFields = [
  'household_expenses',
  'food_and_transport',
  'debt_payments',
  'other_expenses',
  'investments',
  'savings',
]

const GRADIENT_VARIANT_ONE =
  'linear-gradient(180deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(270deg, #FFF34D 2.48%, #E4660B 100%)'

const GRADIENT_VARIANT_TWO =
  'linear-gradient(180deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(270deg, #EF5B0C 2.48%, #8C213B 100%)'

const GRADIENT_VARIANT_THREE =
  'linear-gradient(180deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(270deg, #F238B3 2.48%, #CD2644 100%)'

export const getInitialValues = (data) => {
  return {
    assets: data?.assets || 0,
    debt_payments: data?.debt_payments || 0,
    food_and_transport: data?.food_and_transport || 0,
    household_expenses: data?.household_expenses || 0,
    investments: data?.investments || 0,
    investments_and_savings: data?.investments_and_savings || 0,
    liabilities: data?.liabilities || 0,
    other_expenses: data?.other_expenses || 0,
    other_income: data?.other_income || 0,
    savings: data?.savings || 0,
  }
}

export const validationSchema = yup.object({
  assets: yup.number().nullable().required(),
  debt_payments: yup.number().nullable().required(),
  food_and_transport: yup
    .number()
    .nullable()
    .moreThan(0, 'El valor debe ser mayor a ${more}')
    .required(),
  household_expenses: yup
    .number()
    .nullable()
    .moreThan(0, 'El valor debe ser mayor a ${more}')
    .required(),
  investments: yup.number().nullable().required(),
  investments_and_savings: yup.number().nullable().required(),
  liabilities: yup.number().nullable().required(),
  other_expenses: yup.number().nullable().required(),
  other_income: yup.number().nullable().required(),
  savings: yup.number().nullable().required(),
})

export const getCalculatorCardsData = (fetchData = {}) => {
  return [
    {
      id: 'contingency_prevention_score',
      title: 'Prevención de imprevistos',
      value: fetchData?.score_dimensions?.contingency_prevention_score || 0,
      description:
        'Este indicador refleja qué tan preparado estás para situaciones inesperadas que pueden afectar tus finanzas, como una emergencia médica o una reparación urgente.',
      tip: 'Ahorra al menos el 10% de tu ingreso mensual y guarda ese fondo exclusivamente para emergencias y épocas de “vacas flacas”.',
    },
    {
      id: 'debt_management_score',
      title: 'Gestión de deudas',
      value: fetchData?.score_dimensions?.debt_management_score || 0,
      description:
        'Este indicador muestra si tus deudas están bajo control y si puedes pagarlas sin sentirte ahogado. Un buen puntaje indica que manejas bien tus compromisos financieros.',
      tip: 'Paga tus deudas a tiempo y, en caso de tener varias, da prioridad a aquellas con intereses más altos.',
    },
    {
      id: 'financial_abundance_score',
      title: 'Abundancia financiera',
      value: fetchData?.score_dimensions?.financial_abundance_score || 0,
      description:
        'Este indicador muestra si estás diversificando tus ingresos y construyendo un flujo de dinero sostenible más allá de tu salario, como inversiones o ingresos pasivos.',
      tip: 'Explora ingresos adicionales, como proyectos freelance o inversiones sencillas, para que tu dinero trabaje para ti.',
    },
    {
      id: 'future_planning_score',
      title: 'Planeación del futuro',
      value: fetchData?.score_dimensions?.future_planning_score || 0,
      description:
        'Este indicador refleja qué tan bien te estás preparando para las metas importantes de tu vida, como tu plan de retiro o la compra de una casa.',
      tip: 'Aterriza tus sueños en proyectos claros alineados con metas mensuales de ahorro o inversión.',
    },
    {
      id: 'present_security_score',
      title: 'Seguridad en el presente',
      value: fetchData?.score_dimensions?.present_security_score || 0,
      description:
        'Este indicador mide qué tan tranquilo te sientes con tus finanzas diarias. Si tienes tus gastos bajo control y aún puedes darte algún gusto, vas por buen camino.',
      tip: 'Lleva un control simple de tus gastos mensuales mediante un presupuesto; registra tus gastos diarios para identificar posibles fugas y en qué categorías necesitas más control.',
    },
  ]
}

export function CardPayrollIncome({ payrollIncome }) {
  return (
    <Box
      sx={({ spacing }) => ({
        display: 'flex',
        alignItems: 'center',
        gap: spacing(2),
      })}
    >
      <Paper
        className="invoice-hover-swipe"
        elevation={2}
        sx={(theme) => ({
          display: 'flex',
          height: '9rem',
          width: '100%',
          maxWidth: '34rem',
          borderRadius: '0.5rem',
          [theme.breakpoints.up('tablet')]: {
            marginLeft: theme.spacing(2),
          },
        })}
      >
        <Box
          sx={{
            backgroundColor: 'accent4.light',
            borderTopLeftRadius: '0.5rem',
            borderBottomLeftRadius: '0.5rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minWidth: '7rem',
          }}
        >
          <LordIcon
            name="invoice-hover-swipe"
            target="invoice-hover-swipe"
            sx={{
              '& > lord-icon': {
                width: '5.5rem',
                height: '5.5rem',
              },
            }}
          />
        </Box>
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(2.125),
            minWidth: '12.5rem',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          })}
        >
          <Typography variant="h4">Ingreso neto mensual:</Typography>
          <Box
            sx={(theme) => ({
              display: 'flex',
              columnGap: theme.spacing(1.63),
            })}
          >
            <Typography variant="h1" color="accent4.main">
              {formatCurrency(payrollIncome)}
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Box>
  )
}

function LabelWellnessEstimatorFiled({ fieldLabel, tooltipDescription }) {
  return (
    <Box
      sx={({ spacing, breakpoints }) => ({
        display: 'flex',
        gap: spacing(1),
        alignItems: 'center',
        marginBottom: spacing(1.5),
        [breakpoints.up('md')]: {
          marginBottom: 0,
        },
      })}
    >
      <Typography>{fieldLabel}</Typography>
      {tooltipDescription ? (
        <TooltipInfoIcon
          title={tooltipDescription}
          tooltipProps={{
            tooltipSx: ({ shadows }) => ({
              boxShadow: shadows[4],
            }),
          }}
          iconProps={{
            sx: {
              color: 'accent4.main',
            },
          }}
        />
      ) : null}
    </Box>
  )
}

const getSlidersFinancialOverview = (payroll_income) => [
  {
    id: 'assets',
    fieldLabel: 'Activos',
    backgroundColor: GRADIENT_VARIANT_ONE,
    maxValue: payroll_income * 100,
    stepValue: 1000,
    tooltipDescription: 'Valor actual del carro, casa, propiedades y negocios.',
    thumbIcon: moneyBag,
  },
  {
    id: 'investments_and_savings',
    fieldLabel: 'Inversiones + Ahorros',
    backgroundColor: GRADIENT_VARIANT_ONE,
    maxValue: payroll_income * 100,
    stepValue: 1000,
    tooltipDescription:
      'Todo lo que tengas como ahorro o inversiones, diferente a los activos, ej. acciones, saldo en cuentas bancarias, natillera, entre otros.',
    thumbIcon: chartWithUpwardsTrendAndYenSign,
  },
  {
    id: 'liabilities',
    fieldLabel: 'Deudas',
    backgroundColor: GRADIENT_VARIANT_TWO,
    maxValue: payroll_income * 100,
    stepValue: 1000,
    tooltipDescription: 'Préstamos, saldo tarjeta de crédito, hipotecas, etc.',
    thumbIcon: cryEmoji,
  },
]

export function FinancialOverviewFields({
  payrollIncome,
  formikValues,
  setFieldValue,
}) {
  const [overviewState, setOverviewState] = useState({
    fieldName: '',
    newValue: 0,
  })

  const slidersFinancialOverviewData =
    getSlidersFinancialOverview(payrollIncome)

  const handleSliderChange = (newValue, fieldName) => {
    setOverviewState({ fieldName, newValue })
  }

  const onChangeCommitted = (newValue, fieldName) => {
    setFieldValue(fieldName, newValue)
  }

  const handleInputChange = (event, fieldName) => {
    setFieldValue(fieldName, Number(event.value))
    setOverviewState({
      fieldName: '',
      newValue: 0,
    })
  }

  return (
    <Box
      sx={({ spacing, breakpoints }) => ({
        display: 'grid',
        gridTemplateColumns: '1fr',
        rowGap: spacing(4),
        columnGap: spacing(5.5),
        margin: spacing(2, 0, 5, 0),
        [breakpoints.up('tablet')]: {
          gridTemplateColumns: '1fr 1fr',
        },
      })}
    >
      {slidersFinancialOverviewData.map(
        ({
          id: fieldName,
          fieldLabel,
          tooltipDescription,
          backgroundColor,
          maxValue,
          minValue = 0,
          stepValue,
          thumbIcon,
        }) => (
          <Box
            key={fieldName}
            sx={({ spacing, breakpoints }) => ({
              display: 'flex',
              flexDirection: 'column',
              [breakpoints.down('tablet')]: {
                paddingX: spacing(2),
              },
            })}
          >
            <LabelWellnessEstimatorFiled
              fieldLabel={fieldLabel}
              tooltipDescription={tooltipDescription}
            />

            <Box
              sx={({ spacing, breakpoints }) => ({
                display: 'flex',
                flexDirection: 'column-reverse',
                gap: spacing(1),
                [breakpoints.up('md')]: {
                  flexDirection: 'row',
                  gap: spacing(4),
                  alignItems: 'center',
                },
              })}
            >
              <Slider
                value={
                  overviewState.fieldName === fieldName
                    ? overviewState.newValue
                    : formikValues[fieldName]
                }
                backgroundColor={backgroundColor}
                maxValue={maxValue}
                minValue={minValue}
                stepValue={stepValue}
                onChangeCommitted={(__, newValue) =>
                  onChangeCommitted(newValue, fieldName)
                }
                onChange={(__, newValue) =>
                  handleSliderChange(newValue, fieldName)
                }
                componentsProps={{
                  thumb: { thumbIcon },
                }}
                valueLabelFormat={(label) => formatCurrency(label)}
              />

              <Box>
                <FormField
                  name={fieldName}
                  variant="number"
                  onValueChange={(event) => handleInputChange(event, fieldName)}
                  prefix="$"
                  isAllowed={({ value }) => Number(value) <= maxValue}
                  decimalSeparator={false}
                  allowLeadingZeros={false}
                  sx={({ typography, palette }) => ({
                    backgroundColor: 'transparent',
                    '&:hover': {
                      backgroundColor: 'white.main',
                    },
                    '&.Mui-focused': {
                      backgroundColor: 'white.main',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: `0.031rem solid ${palette.black.light}`,
                    },

                    '& .MuiTypography-root': {
                      color: 'accent4.main',
                      fontFamily: typography.lead1,
                    },
                    '& .MuiOutlinedInput-input': {
                      textAlign: 'end',
                      padding: 0,
                      color: 'accent4.main',
                      fontFamily: typography.lead1,
                    },
                  })}
                  inputProps={{
                    min: minValue,
                    max: maxValue,
                    'aria-labelledby': 'input-slider',
                  }}
                />
              </Box>
            </Box>
          </Box>
        )
      )}
    </Box>
  )
}

const getSlidersMonthlyCashFlow = (payroll_income, formikValues) => {
  const { other_income } = formikValues || {}

  return [
    {
      id: 'other_income',
      fieldLabel: 'Otros ingresos',
      backgroundColor: GRADIENT_VARIANT_THREE,
      maxValue: payroll_income * 4,
      stepValue: 1000,
      tooltipDescription:
        'Alquileres, trabajos adicionales, ayudas económicas, etc.',
      thumbIcon: wingedMoney,
    },
    {
      id: 'household_expenses',
      fieldLabel: 'Gastos del hogar',
      backgroundColor: GRADIENT_VARIANT_THREE,
      maxValue: payroll_income + other_income,
      stepValue: 1000,
      tooltipDescription:
        'Alquiler/hipoteca, servicios públicos, administración, etc.',
      thumbIcon: houseWithGarden,
    },
    {
      id: 'food_and_transport',
      fieldLabel: 'Alimentación y transporte',
      backgroundColor: GRADIENT_VARIANT_ONE,
      maxValue: payroll_income + other_income,
      stepValue: 1000,
      tooltipDescription:
        'Mercado, gastos de alimentación, gastos en transporte, gasolina, seguros, etc.',
      thumbIcon: oncomingAutomobile,
    },
    {
      id: 'debt_payments',
      fieldLabel: 'Pago de deudas',
      backgroundColor: GRADIENT_VARIANT_ONE,
      maxValue: payroll_income + other_income,
      stepValue: 1000,
      tooltipDescription: 'Tarjetas de crédito y otros créditos.',
      thumbIcon: bank,
    },
    {
      id: 'other_expenses',
      fieldLabel: 'Otros costos',
      backgroundColor: GRADIENT_VARIANT_TWO,
      maxValue: payroll_income + other_income,
      stepValue: 1000,
      tooltipDescription:
        'Otros costos fijos, ej. suscripciones de telefonía, Netflix, gimnasio, etc.',
      thumbIcon: creditCard,
    },
    {
      id: 'investments',
      fieldLabel: 'Inversiones financieras',
      backgroundColor: GRADIENT_VARIANT_TWO,
      maxValue: payroll_income + other_income,
      stepValue: 1000,
      tooltipDescription: 'Fondo de pensión voluntaria, acciones, CDTs, etc.',
      thumbIcon: compass,
    },
    {
      id: 'savings',
      fieldLabel: 'Ahorros de emergencia y a largo plazo',
      backgroundColor: GRADIENT_VARIANT_THREE,
      maxValue: payroll_income + other_income,
      stepValue: 1000,
      tooltipDescription:
        'Ahorros para emergencias y ahorros para compras grandes.',
      thumbIcon: policeCarsRevolvingLight,
    },
  ]
}

export function MonthlyCashFlowFields({
  payrollIncome,
  formikValues,
  setFieldValue,
}) {
  const [cashFlowState, setCashFlowState] = useState({
    fieldName: '',
    newValue: 0,
  })

  const slidersMonthlyCashFlowData = getSlidersMonthlyCashFlow(
    payrollIncome,
    formikValues
  )

  const handleSliderChange = (newValue, fieldName) => {
    setCashFlowState({ fieldName, newValue })
  }

  const onChangeCommitted = (newValue, fieldName) => {
    setFieldValue(fieldName, newValue)

    if (fieldName === 'other_income') {
      monthlyCashFlowFields.forEach((field) => {
        if (formikValues[field] > payrollIncome + newValue) {
          setFieldValue(field, payrollIncome + newValue)
        }
      })
    }
  }

  const handleInputChange = (event, fieldName) => {
    setFieldValue(fieldName, Number(event.value))
    setCashFlowState({
      fieldName: '',
      newValue: 0,
    })
  }

  return (
    <Box
      sx={({ spacing, breakpoints }) => ({
        display: 'grid',
        gridTemplateColumns: '1fr',
        rowGap: spacing(4),
        columnGap: spacing(5.5),
        margin: spacing(2, 0),
        [breakpoints.up('tablet')]: {
          gridTemplateColumns: '1fr 1fr',
        },
      })}
    >
      {slidersMonthlyCashFlowData.map(
        ({
          id: fieldName,
          fieldLabel,
          tooltipDescription,
          backgroundColor,
          maxValue,
          minValue = 0,
          stepValue,
          thumbIcon,
        }) => (
          <Box
            key={fieldName}
            sx={({ spacing, breakpoints }) => ({
              display: 'flex',
              flexDirection: 'column',
              [breakpoints.down('tablet')]: {
                paddingX: spacing(2),
              },
            })}
          >
            <LabelWellnessEstimatorFiled
              fieldLabel={fieldLabel}
              tooltipDescription={tooltipDescription}
            />
            <Box
              sx={({ spacing, breakpoints }) => ({
                display: 'flex',
                flexDirection: 'column-reverse',
                gap: spacing(1),
                [breakpoints.up('md')]: {
                  flexDirection: 'row',
                  gap: spacing(4),
                  alignItems: 'center',
                },
              })}
            >
              <Slider
                value={
                  cashFlowState.fieldName === fieldName
                    ? cashFlowState.newValue
                    : formikValues[fieldName]
                }
                backgroundColor={backgroundColor}
                maxValue={maxValue}
                minValue={minValue}
                stepValue={stepValue}
                onChangeCommitted={(__, newValue) =>
                  onChangeCommitted(newValue, fieldName)
                }
                onChange={(__, newValue) =>
                  handleSliderChange(newValue, fieldName)
                }
                componentsProps={{
                  thumb: { thumbIcon },
                }}
                valueLabelFormat={(label) => formatCurrency(label)}
              />

              <Box>
                <FormField
                  name={fieldName}
                  variant="number"
                  onValueChange={(event) => handleInputChange(event, fieldName)}
                  prefix="$"
                  isAllowed={({ value }) => Number(value) <= maxValue}
                  decimalSeparator={false}
                  allowLeadingZeros={false}
                  sx={({ typography, palette }) => ({
                    backgroundColor: 'transparent',
                    '&:hover': {
                      backgroundColor: 'white.main',
                    },
                    '&.Mui-focused': {
                      backgroundColor: 'white.main',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: `0.031rem solid ${palette.black.light}`,
                    },

                    '& .MuiTypography-root': {
                      color: 'accent4.main',
                      fontFamily: typography.lead1,
                    },
                    '& .MuiOutlinedInput-input': {
                      textAlign: 'end',
                      padding: 0,
                      color: 'accent4.main',
                      fontFamily: typography.lead1,
                    },
                  })}
                  inputProps={{
                    min: minValue,
                    max: maxValue,
                    'aria-labelledby': 'input-slider',
                  }}
                  // {...props}
                />
              </Box>
            </Box>
          </Box>
        )
      )}
    </Box>
  )
}
