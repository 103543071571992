import { useFormikContext } from 'formik'

import useDepartmentsService from 'utils/hooks/departmentsService'

import FormField from '../FormField/Index'

/**
 * This component is intended to be used in conjunction with the <DepartmentsList /> component, since when changing the department
 * a query is executed to bring the cities of that department.
 *
 * The prop "department" is to know to which department the cities should be consulted, by default the cities of the department that has the key department
 * in the context of Formik will be consulted.
 *
 * For an example of custom usage, see: src/components/Benefits/Credits/Form/Fields/References.jsx
 */

const CitiesList = ({ name = '', label = '', department, optional }) => {
  const { values } = useFormikContext()

  const { departmentsQuery } = useDepartmentsService({
    serviceParams: {
      queryKey: [
        'citiesByDepartment',
        department?.name || values?.department?.name,
      ],
      departmentId: department?.id || values?.department?.id,
    },
    queryOptions: {
      staleTime: Infinity,
      enabled: Boolean(department?.id || values?.department?.id),
    },
  })

  return (
    <FormField
      disabled={!(values?.department || department)}
      variant="autocomplete"
      options={departmentsQuery?.data?.cities || []}
      name={name}
      data-cy={name}
      label={label}
      placeholder={
        values?.department || department
          ? 'Selecciona una ciudad...'
          : 'Selecciona primero un departamento'
      }
      optional={optional}
    />
  )
}

export default CitiesList
