import { Form, Formik } from 'formik'
import { useNavigate } from 'react-router-dom'

import { Box, Typography } from '@mui/material'

import { useUser } from 'components/App/UserContext/useUser'
import Button from 'components/UI/Button/Button'
import FormBox from 'components/UI/FormBox'
import PasswordField from 'components/UI/Formik/CommonFields/PasswordField'
import FormField from 'components/UI/Formik/FormField/Index'
import Page from 'components/UI/Page/Page'

import { getDirtyValues } from 'utils/form'
import { isObjectEmpty } from 'utils/general'
import useUsersService from 'utils/hooks/settings/usersService'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'

import { SETTINGS } from 'config/routes'

import messages from 'messages/notification'

import { getInitialValues, getValidationSchema } from './helpers'

const SettingsProfile = () => {
  const { user, logIn } = useUser()
  const navigate = useNavigate()
  const { showSuccessMessage } = useNotifications()
  const { handleError } = useErrorHandler()
  const { usersMutation } = useUsersService({
    queryOptions: {
      enabled: false,
    },
  })

  const handleSubmit = async (values, form) => {
    const dataToSend = {
      ...values,
    }

    if (dataToSend.password) {
      dataToSend.password = btoa(values.password)
    } else delete dataToSend.password

    if (dataToSend.password_confirmation) {
      dataToSend.password_confirmation = btoa(values.password_confirmation)
    } else delete dataToSend.password_confirmation

    const dirtyValues = getDirtyValues(user, dataToSend)

    if (!isObjectEmpty(dirtyValues)) {
      usersMutation.mutate(
        {
          mutationMethod: 'PATCH',
          mutationKey: 'updateProfile',
          user: dirtyValues,
        },
        {
          onSuccess: ({ data }) => {
            logIn(data)
            showSuccessMessage(messages.SETTINGS_USER_PROFILE_SUCCESS)
            navigate(SETTINGS())
          },
          onError: (error) => handleError(error, form),
        }
      )
    }
  }

  const initialValues = getInitialValues(user)

  return (
    <Page header="Editar mi cuenta" grid>
      <FormBox sx={{ position: 'relative', gridColumn: '1 / -1' }}>
        <Typography
          variant="h6"
          color="primary.dark"
          sx={(theme) => ({ marginBottom: theme.spacing(2) })}
        >
          Modificar datos de tu cuenta
        </Typography>
        <Typography sx={(theme) => ({ marginBottom: theme.spacing(2) })}>
          Acá puedes modificar tu nombre de usuario y datos de acceso de forma
          rápida y segura. Ten en cuenta que para ello, debes ingresar tu
          contraseña actual en la parte inferior.
        </Typography>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={getValidationSchema(initialValues)}
          enableReinitialize
        >
          {(formProps) => {
            const { handleSubmit: onSubmit, isSubmitting, values } = formProps

            return (
              <Form>
                <FormField
                  name="name"
                  label="Nombre de usuario"
                  sx={(theme) => ({ marginBottom: theme.spacing(2) })}
                />
                <FormField
                  type="email"
                  name="email"
                  label="Correo electrónico"
                  sx={(theme) => ({ marginBottom: theme.spacing(2) })}
                />
                <Typography
                  variant="h6"
                  color="primary.dark"
                  sx={(theme) => ({ margin: theme.spacing(2, 0, 2, 0) })}
                >
                  Modificar contraseña
                </Typography>
                <Typography
                  sx={(theme) => ({ margin: theme.spacing(2, 0, 2, 0) })}
                >
                  A continuación, puedes modificar tu contraseña. Recuerda que
                  debe tener una extensión mínima de 8 caracteres, incluir
                  números, letras mayúsculas, letras minúsculas y caracteres
                  especiales como #$!*@%&.
                </Typography>
                <Box
                  sx={(theme) => ({
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    gap: theme.spacing(2),
                    [theme.breakpoints.down('tablet')]: {
                      gridTemplateColumns: '1fr',
                    },
                  })}
                >
                  <PasswordField
                    name="password"
                    label="Nueva contraseña"
                    autoComplete="password"
                    optional={true}
                  />
                  <PasswordField
                    name="password_confirmation"
                    label="Confirmar contraseña"
                    autoComplete="password"
                    optional={true}
                  />
                </Box>
                <Typography
                  variant="h6"
                  color="primary.dark"
                  sx={(theme) => ({ margin: theme.spacing(4, 0, 2, 0) })}
                >
                  Ingresa tu contraseña actual
                </Typography>
                <Typography
                  sx={(theme) => ({ margin: theme.spacing(2, 0, 2, 0) })}
                >
                  Por razones de seguridad, debes ingresar tu contraseña actual
                  para modificar tu correo electrónico o crear una nueva
                  contraseña.
                </Typography>
                <FormField
                  type="password"
                  name="current_password"
                  label="Contraseña actual"
                  optional={
                    values.email === user.email &&
                    !values.password &&
                    !values.password_confirmation
                  }
                />
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    justifyContent: 'flex-start',
                    marginTop: theme.spacing(8),
                    gap: theme.spacing(2),
                  })}
                >
                  <Button onClick={onSubmit} loading={isSubmitting}>
                    Actualizar
                  </Button>
                  <Button
                    onClick={() => navigate(SETTINGS())}
                    variant="outlined"
                  >
                    Cancelar
                  </Button>
                </Box>
              </Form>
            )
          }}
        </Formik>
      </FormBox>
    </Page>
  )
}

export default SettingsProfile
