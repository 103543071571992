import {
  Box,
  Step,
  StepButton,
  StepContent,
  Stepper,
  Typography,
  alpha,
  stepConnectorClasses,
  stepContentClasses,
  stepLabelClasses,
} from '@mui/material'

import Button from 'components/UI/Button/Button'
import Icon from 'components/UI/Icon'

import useDownloadURI from 'utils/hooks/useDownloadURI'

const labels = {
  compensation_fund: 'CCF',
  health_provider: 'EPS',
  pension_provider: 'AFP',
  risk_provider: 'ARL',
}

const CertificateStateButton = ({ completed }) => (
  <Typography
    variant="lead2"
    sx={(theme) => ({
      padding: theme.spacing(0.5, 2),
      backgroundColor: completed
        ? alpha(theme.palette.primary.light, 0.4)
        : alpha(theme.palette.info.light, 0.4),
      color: completed ? theme.palette.primary.dark : theme.palette.info.dark,
      borderRadius: '1.1rem',
    })}
  >
    {completed ? 'Completada' : 'En proceso'}
  </Typography>
)

const StepIcon = ({ completed, index }) => {
  return (
    <Box
      sx={(theme) => ({
        width: '2rem',
        height: '2rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        backgroundColor: theme.palette.white.main,
        ...theme.typography.lead1,
        ...(!completed && {
          border: `2px solid ${theme.palette.primary.dark}`,
          width: '1.875rem',
          height: '1.875rem',
          color: theme.palette.primary.dark,
        }),
        ...(completed && {
          color: theme.palette.white.main,
          backgroundColor: theme.palette.primary.dark,
        }),
      })}
    >
      {index + 1}
    </Box>
  )
}

const StepperCertifications = ({ certificates }) => {
  const downloadURI = useDownloadURI()

  return (
    <Box>
      <Stepper
        orientation="vertical"
        sx={(theme) => ({
          [`& .${stepConnectorClasses.line}`]: {
            marginLeft: theme.spacing(0.5),
            borderLeft: `2px ${theme.palette.primary.dark} solid`,
            opacity: 1,
          },
        })}
      >
        {certificates?.map(
          (
            {
              completed,
              completion_date: completionDate,
              document_url: documentUrl,
              institution_name: institutionName,
              provider_type: providerType,
            },
            index
          ) => {
            return (
              <Step
                key={institutionName}
                completed={completed}
                active
                sx={(theme) => ({
                  [`& .${stepContentClasses.root}`]: {
                    marginLeft: theme.spacing(2),
                    borderLeft: `2px ${theme.palette.primary.dark} solid`,
                  },
                })}
              >
                <StepButton
                  icon={<StepIcon completed={completed} index={index} />}
                  sx={{
                    [`& .${stepLabelClasses.root}`]: {
                      padding: 0,
                    },
                  }}
                >
                  <Box
                    sx={(theme) => ({
                      display: 'flex',
                      alignItems: 'center',
                      gap: theme.spacing(1),
                    })}
                  >
                    <Typography variant="lead1">
                      {labels[providerType]}
                    </Typography>
                    <CertificateStateButton completed={completed} />
                  </Box>
                </StepButton>
                <StepContent>
                  <Typography
                    sx={(theme) => ({
                      paddingLeft: theme.spacing(0.5),
                    })}
                    variant="body2"
                  >
                    {institutionName}
                  </Typography>
                  {completed ? (
                    <Box
                      sx={(theme) => ({
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: theme.spacing(1),
                        gap: theme.spacing(1),
                      })}
                    >
                      <Button
                        variant="outlined"
                        size="small"
                        endIcon={<Icon name="download" basic />}
                        onClick={() => downloadURI(documentUrl)}
                        sx={{ width: '15rem' }}
                      >
                        Descargar certificado
                      </Button>
                      <Typography variant="body2">
                        Completado el {completionDate}
                      </Typography>
                    </Box>
                  ) : null}
                </StepContent>
              </Step>
            )
          }
        )}
      </Stepper>
    </Box>
  )
}

export default StepperCertifications
