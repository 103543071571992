import { Form, Formik } from 'formik'
import * as yup from 'yup'

import { Box } from '@mui/material'

import FormField from 'components/UI/Formik/FormField/Index'
import Modal from 'components/UI/Modal/Modal'

import { yupLocaleES } from 'utils/form'
import usePayrollConceptsService from 'utils/hooks/payroll/payrollConcepts'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'
import { generateNewCustomConcept } from 'utils/payroll'

yup.setLocale(yupLocaleES)

const validationSchema = yup.object({
  name: yup.string().trim().required(),
  category: yup.string().trim().required(),
})

const categoryNameMap = {
  salary_income: 'Ingresos salariales',
  non_salary_income: 'Ingresos NO salariales',
  deductions: 'Deducciones',
}

const CreatePayrollConceptModal = ({
  category = '',
  options = ['salary_income', 'non_salary_income', 'deductions'],
  conceptToCreate = {},
  handleClose,
  callback,
}) => {
  const { showSuccessMessage } = useNotifications()
  const { handleError } = useErrorHandler()
  const { payrollConceptsMutation } = usePayrollConceptsService({
    queryOptions: { enabled: false },
  })

  const handleSubmit = (values, form) => {
    const concept = { name: values.name }
    const conceptCategory = category || values.category

    const concepts = generateNewCustomConcept({
      payrollConcepts: [concept],
      category: conceptCategory,
    })

    payrollConceptsMutation.mutate(
      { mutationMethod: 'PUT', concepts },
      {
        onSuccess: async ({ data: { payroll_concepts: payrollConcepts } }) => {
          if (callback) {
            await callback()
          }

          showSuccessMessage('El nuevo concepto fue creado exitosamente.')
          handleClose(payrollConcepts[0], conceptCategory)
        },
        onError: (error) => {
          handleError(error, form)
        },
      }
    )
  }

  const initialCategory = category || options[0] // Index 0 initial selected category

  let modalHeader = 'Crear nuevo concepto'

  if (category) {
    modalHeader += ` de ${categoryNameMap[category]}`
  } else if (options.length === 1) {
    modalHeader += ` de ${categoryNameMap[initialCategory]}`
  }

  return (
    <Formik
      initialValues={{
        name: conceptToCreate.name ? conceptToCreate.name : '',
        category: initialCategory,
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
      validateOnBlur={false}
      validateOnChange={false}
    >
      {(form) => {
        const { handleSubmit: onSubmit, isSubmitting } = form

        return (
          <Modal
            open
            header={modalHeader}
            onOk={onSubmit}
            onCancel={() => handleClose()}
            okText="Guardar"
            isLoading={isSubmitting}
            dialogProps={{
              maxWidth: 'xs',
              fullWidth: true,
            }}
          >
            <Form>
              <Box
                sx={(theme) => ({
                  marginTop: theme.spacing(2),
                  display: 'flex',
                  flexDirection: 'column',
                  gap: theme.spacing(3),
                })}
              >
                <FormField
                  name="name"
                  label="Nombre"
                  disabled={isSubmitting}
                  optional={false}
                />
                {!category && options.length > 1 ? (
                  <FormField
                    name="category"
                    variant="select"
                    label="Tipo"
                    options={options.map((opt) => ({
                      label: categoryNameMap[opt],
                      value: opt,
                    }))}
                    disabled={isSubmitting}
                    optional={false}
                  />
                ) : null}
              </Box>
            </Form>
          </Modal>
        )
      }}
    </Formik>
  )
}

export default CreatePayrollConceptModal
