import { Link as RouterLink } from 'react-router-dom'
import * as yup from 'yup'

import FilterButton from 'components/UI/Button/FilterButton'
import Icon from 'components/UI/Icon'
import Link from 'components/UI/MaterialUI/Link'

import { formatDisplayDateString } from 'utils/dateTime'
import { yupLocaleES } from 'utils/form'
import { contractCategories } from 'utils/worker'

import { ORGANIZER_WORKER_SHOW } from 'config/routes'

yup.setLocale(yupLocaleES)

const affiliationsStatus = {
  waiting_response: 'Pendientes',
  approved: 'Aprobadas',
  rejected: 'Rechazadas',
  received: 'Recibidas',
}

const getOptions = (affiliations) =>
  Object.keys(affiliations).map((affiliation) => {
    return {
      label: `${affiliationsStatus[affiliation.split('_total')[0]]} (${
        affiliations[affiliation]
      })`,
      value: affiliation.split('_total')[0],
    }
  })

export const getColumns = (handleOpenAffiliation) => {
  const columns = [
    {
      accessor: 'status_change_date',
      Header: 'Fecha',
      Cell: ({ row }) =>
        formatDisplayDateString(row.original.status_change_date),
    },
    {
      accessor: 'worker_name',
      Header: 'Persona',
      Cell: ({ row }) => (
        <Link
          to={ORGANIZER_WORKER_SHOW(row.original.id)}
          state={{
            fromAffiliation: true,
            workerId: row.original.worker_id,
          }}
          onClick={() => handleOpenAffiliation(row.original.company_id)}
          color="black.main"
        >
          {row.original.worker_name}
        </Link>
      ),
    },
    {
      accessor: 'company_name',
      Header: 'Empresa',
    },
    {
      accessor: 'worker_location',
      Header: 'Sede',
    },
    {
      accessor: 'contract_category',
      Header: 'Contrato',
      Cell: ({ row }) => contractCategories[row.original.contract_category],
    },
  ]

  return columns
}

export const getActions = ({
  handleOpenAffiliation,
  filter,
  handleChange,
  affiliations = {},
}) => {
  return [
    (rowData) => ({
      id: 'view_profile',
      tooltip: 'Ver perfil',
      icon: <Icon name="eye" basic />,
      onClick: () => handleOpenAffiliation(rowData.company_id),
      buttonProps: {
        component: RouterLink,
        to: ORGANIZER_WORKER_SHOW(rowData.id),
        state: {
          fromAffiliation: true,
          workerId: rowData.worker_id,
        },
      },
    }),
    {
      id: 'filterAffiliations',
      isFreeAction: true,
      position: 'left',
      Component: (
        <FilterButton
          defaultLabel="Pendientes"
          options={getOptions(affiliations)}
          value={filter}
          onChange={handleChange}
        />
      ),
    },
  ]
}

export const getValidationSchema = (
  isRejectAction,
  documentType,
  contractCategory
) => {
  if (isRejectAction) {
    return yup.object({
      comment: yup.string().nullable().required(),
    })
  }

  return yup.object().shape(
    {
      approved_severance_document: yup
        .mixed()
        .when(
          [
            'approved_compensation_fund_document',
            'approved_health_document',
            'approved_pension_document',
            'approved_risk_document',
          ],
          {
            is: (compensationFundDoc, healthDoc, pensionDoc, riskDoc) =>
              !compensationFundDoc && !healthDoc && !pensionDoc && !riskDoc,
            then: yup
              .mixed()
              .test('approved_severance_document', null, (value) => {
                return typeof value === 'string' || value instanceof File
              })
              .required('Debes proporcionar al menos uno de estos documentos'),
            otherwise: yup.mixed().nullable(),
          }
        ),
      approved_compensation_fund_document: [
        'employee',
        'part_time_contract',
      ].includes(contractCategory)
        ? yup
            .mixed()
            .when(
              [
                'approved_severance_document',
                'approved_health_document',
                'approved_pension_document',
                'approved_risk_document',
              ],
              {
                is: (severanceDOc, healthDoc, pensionDoc, riskDoc) =>
                  !severanceDOc && !healthDoc && !pensionDoc && !riskDoc,
                then: yup
                  .mixed()
                  .test(
                    'approved_compensation_fund_document',
                    null,
                    (value) => {
                      return typeof value === 'string' || value instanceof File
                    }
                  )
                  .required(
                    'Debes proporcionar al menos uno de estos documentos'
                  ),
                otherwise: yup.mixed().nullable(),
              }
            )
        : null,
      approved_health_document: ![
        'contractor',
        'student_decree_055',
        'part_time_contract',
      ].includes(contractCategory)
        ? yup
            .mixed()
            .when(
              [
                'approved_severance_document',
                'approved_compensation_fund_document',
                'approved_pension_document',
                'approved_risk_document',
              ],
              {
                is: (severanceDOc, compensationFundDoc, pensionDoc, riskDoc) =>
                  !severanceDOc &&
                  !compensationFundDoc &&
                  !pensionDoc &&
                  !riskDoc,
                then: yup
                  .mixed()
                  .test('approved_health_document', null, (value) => {
                    return typeof value === 'string' || value instanceof File
                  })
                  .required(
                    'Debes proporcionar al menos uno de estos documentos'
                  ),
                otherwise: yup.mixed().nullable(),
              }
            )
        : null,
      approved_pension_document:
        !['ce_no_pension', 'pe_no_pension', 'pt_no_pension'].includes(
          documentType
        ) &&
        ['employee', 'student_law_789', 'part_time_contract'].includes(
          contractCategory
        )
          ? yup
              .mixed()
              .when(
                [
                  'approved_severance_document',
                  'approved_compensation_fund_document',
                  'approved_health_document',
                  'approved_risk_document',
                ],
                {
                  is: (
                    severanceDOc,
                    compensationFundDoc,
                    healthDoc,
                    pensionDoc
                  ) =>
                    !severanceDOc &&
                    !compensationFundDoc &&
                    !healthDoc &&
                    !pensionDoc,
                  then: yup
                    .mixed()
                    .test('approved_pension_document', null, (value) => {
                      return typeof value === 'string' || value instanceof File
                    })
                    .required(
                      'Debes proporcionar al menos uno de estos documentos'
                    ),
                  otherwise: yup.mixed().nullable(),
                }
              )
          : null,
      approved_risk_document: !['contractor', 'schooling_trainee'].includes(
        contractCategory
      )
        ? yup
            .mixed()
            .when(
              [
                'approved_severance_document',
                'approved_compensation_fund_document',
                'approved_health_document',
                'approved_pension_document',
              ],
              {
                is: (severanceDOc, compensationFundDoc, healthDoc, riskDoc) =>
                  !severanceDOc &&
                  !compensationFundDoc &&
                  !healthDoc &&
                  !riskDoc,
                then: yup
                  .mixed()
                  .test('approved_pension_document', null, (value) => {
                    return typeof value === 'string' || value instanceof File
                  })
                  .required(
                    'Debes proporcionar al menos uno de estos documentos'
                  ),
                otherwise: yup.mixed().nullable(),
              }
            )
        : null,
    },
    [
      ['approved_severance_document', 'approved_health_document'],
      ['approved_severance_document', 'approved_compensation_fund_document'],
      ['approved_severance_document', 'approved_pension_document'],
      ['approved_severance_document', 'approved_risk_document'],
      ['approved_risk_document', 'approved_health_document'],
      ['approved_risk_document', 'approved_compensation_fund_document'],
      ['approved_risk_document', 'approved_pension_document'],
      ['approved_risk_document', 'approved_severance_document'],
      ['approved_health_document', 'approved_risk_document'],
      ['approved_health_document', 'approved_compensation_fund_document'],
      ['approved_health_document', 'approved_pension_document'],
      ['approved_health_document', 'approved_severance_document'],
      ['approved_compensation_fund_document', 'approved_health_document'],
      ['approved_compensation_fund_document', 'approved_pension_document'],
      ['approved_compensation_fund_document', 'approved_risk_document'],
      ['approved_compensation_fund_document', 'approved_severance_document'],
      ['approved_pension_document', 'approved_risk_document'],
      ['approved_pension_document', 'approved_health_document'],
      ['approved_pension_document', 'approved_compensation_fund_document'],
      ['approved_pension_document', 'approved_severance_document'],
    ]
  )
}

export const getInitialValues = (certificates, isRejectAction) => {
  if (isRejectAction) {
    return {
      comment: null,
    }
  }

  return {
    approved_compensation_fund_document: certificates[0]?.document_url || null,
    approved_health_document: certificates[1]?.document_url || null,
    approved_pension_document: certificates[2]?.document_url || null,
    approved_risk_document: certificates[3]?.document_url || null,
  }
}
