import { useFormikContext } from 'formik'

import { Box, Button } from '@mui/material'

import CompanyIdentificationNumberField from 'components/UI/Formik/CommonFields/CompanyIdentificationNumberField'
import DocumentTypeField from 'components/UI/Formik/CommonFields/DocumentTypeField'
import FormField from 'components/UI/Formik/FormField/Index'

import { documentTypesOptions } from 'utils/company'
import { getFileNameFromUrl, isValidUrl } from 'utils/general'
import useDownloadURI from 'utils/hooks/useDownloadURI'

import LogoFormField from './LogoField'

const CompanyFormFieldsBasics = ({ documentType }) => {
  const { values } = useFormikContext()
  const downloadURI = useDownloadURI()
  const taxDocumentUrl = isValidUrl(values?.files?.tax_document)
  const textDocumentName = getFileNameFromUrl(
    taxDocumentUrl ? taxDocumentUrl.search : undefined
  )

  const handleDownloadTaxDocument = () => downloadURI(taxDocumentUrl)

  return (
    <>
      <Box
        component="section"
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(3),
          [theme.breakpoints.up('xl')]: {
            display: 'grid',
            alignItems: 'center',
            gridTemplateColumns: '1fr 1fr',
          },
        })}
      >
        <FormField name="name" label="Razón social" optional={false} />
        <FormField
          name="email"
          type="email"
          label="Email contacto"
          optional={false}
        />
        <Box
          sx={(theme) => ({
            display: 'grid',
            gridTemplateColumns: '1fr',
            rowGap: theme.spacing(3),
            [theme.breakpoints.up('lg')]: {
              columnGap: theme.spacing(3),
              gridTemplateColumns: '1fr 1fr',
            },
            ...(documentType === 'ni' && {
              gridColumnEnd: 'span 2',
            }),
          })}
        >
          <DocumentTypeField options={documentTypesOptions} optional={false} />
          <CompanyIdentificationNumberField optional={false} />
        </Box>
        <FormField
          name="phone"
          type="phone"
          label="Teléfono"
          optional={false}
        />
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'flex-end',
            columnGap: theme.spacing(1),
            width: '100%',
            minWidth: 0,
          })}
        >
          <FormField
            name="files.tax_document"
            variant="file"
            fileType="application/pdf"
            accept="application/pdf"
            fileName={textDocumentName}
            label="Adjuntar RUT de la empresa"
            tooltipContent="
              Adjunta el RUT de la empresa para
              realizar la correcta Facturación
              Electrónica de tu suscripción."
          />
          <Button
            variant="text"
            size="small"
            sx={(theme) => ({
              marginBottom: theme.spacing(1),
            })}
            disabled={!taxDocumentUrl}
            onClick={handleDownloadTaxDocument}
          >
            Ver
          </Button>
        </Box>
      </Box>
      <LogoFormField />
    </>
  )
}

export default CompanyFormFieldsBasics
