import { Box, Card, LinearProgress, Typography } from '@mui/material'

const CreditBarProgress = ({ progressPayment, paidValue, remainingValue }) => {
  return (
    <Card
      sx={(theme) => ({
        boxShadow: 'none',
        border: '0.125rem solid',
        borderColor: theme.palette.white.dark,
        padding: theme.spacing(0, 7, 0.5, 2),
      })}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'space-between',
          margin: theme.spacing(1.5, 0),
        })}
      >
        <Typography variant="lead2">Valor pagado</Typography>
        <Typography variant="body2">Valor restante</Typography>
      </Box>
      <LinearProgress variant="determinate" value={progressPayment} />
      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'space-between',
          margin: theme.spacing(1.5, 0),
        })}
      >
        <Typography variant="lead2">{paidValue}</Typography>
        <Typography variant="body2">{remainingValue}</Typography>
      </Box>
    </Card>
  )
}

export default CreditBarProgress
