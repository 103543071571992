import Table from 'components/UI/Table/Table'

const BenefitsTable = ({
  columns = undefined,
  data = [],
  actions = undefined,
}) => (
  <Table
    columns={columns}
    data={data}
    actions={actions}
    options={{
      flatStyle: true,
      alignActionsHeader: 'center',
      alignActionsCell: 'center',
    }}
  />
)

export default BenefitsTable
