import { useQueryClient } from 'react-query'
import { useLocation, useParams } from 'react-router-dom'

import { useMediaQuery } from '@mui/material'

import usePremiumFeature from 'components/App/Premium/usePremiumFeature'
import { useUser } from 'components/App/UserContext/useUser'
import useSubscription from 'components/Subscription/Atoms/useSubscription'
import usePartnerSubscriptionModal from 'components/Subscription/Index/Plan/PartnerSubscription/usePartnerSubscriptionModal'
import usePaymentExoneratedSubscriptionModal from 'components/Subscription/Index/Plan/PaymentExoneratedSubscriptionModal/usePaymentExoneratedSubscriptionModal'

import {
  isAccountant,
  isFreeCompany,
  isOrganizer,
  isPremiumExpiredByCancellationCompany,
  isWorker,
} from 'utils/auth'
import { getCompanyId, hasOneCompany, hasSelectedCompany } from 'utils/company'
import { isObjectEmpty } from 'utils/general'
import useFeatureFlags from 'utils/hooks/useFeatureFlags'

import { HELP_CENTER } from 'config/organization'
import * as routes from 'config/routes'

import useCloseDrawer from '../../useCloseDrawer'

function useAdmin() {
  const { pathname } = useLocation()
  const queryClient = useQueryClient()
  const handleCloseMobileDrawer = useCloseDrawer()
  const {
    clearCompany,
    clearFetchCompany,
    company,
    distributor,
    isAPartnerChild,
  } = useUser()
  const { showHRFeatures } = usePremiumFeature()
  const {
    subscription,
    isPaymentExoneratedCompany,
    isHolisticPayrollMVPCompany,
  } = useSubscription()
  const { workerId } = useParams
  const { openPartnerSubscriptionModal } = usePartnerSubscriptionModal()

  const { openPaymentExoneratedSubscriptionModal } =
    usePaymentExoneratedSubscriptionModal()

  const companyId = getCompanyId()
  const { holisticPayrollMVP, flagsReady } = useFeatureFlags({
    flags: ['holisticPayrollMVP'],
    trackingMode: 'attributes',
    attributes: {
      companyId,
    },
  })

  const isLaptop = useMediaQuery((theme) => theme.breakpoints.only('laptop'))

  const {
    onboarding_first_steps: onboardingFirstSteps = {},
    electronic_period_id: electronicPeriodId,
  } = company

  const hasFirstSteps = () => {
    let firstStep = true
    if (!isObjectEmpty(onboardingFirstSteps)) {
      firstStep = Boolean(onboardingFirstSteps?.finished_all_steps)
    }
    return firstStep
  }

  const periodQueryKey = ['currentPeriod', companyId]

  const isNotPremium = isFreeCompany(
    subscription?.status,
    subscription?.payment_status
  )

  const hideConfigurationOption =
    pathname === routes.COMPANY_INDEX() ||
    pathname.includes('organizer') ||
    !hasSelectedCompany() ||
    isWorker()

  const hideSubscriptionOption =
    !hasSelectedCompany() ||
    distributor === 'alegra_claro' ||
    ((subscription.payment_category === 'distributor_payment' ||
      subscription?.status === 'premium_expired' ||
      isPremiumExpiredByCancellationCompany(subscription) ||
      isNotPremium) &&
      !isOrganizer()) ||
    isWorker()

  const hideHolisticPayrollOptions =
    ((!flagsReady || holisticPayrollMVP?.value !== 'on') &&
      !isHolisticPayrollMVPCompany) ||
    !hasSelectedCompany()

  return [
    {
      key: 'firstSteps',
      icon: 'first-steps',
      text: 'Primeros pasos',
      to: routes.ONBOARDING_FIRST_STEPS(),
      hidden: hasFirstSteps(),
      onClick: () => handleCloseMobileDrawer(),
    },
    {
      key: 'dashboard',
      icon: 'joistick',
      text: 'Torre de control',
      to: routes.DASHBOARD,
      groupDivider: true,
      hidden: !hasSelectedCompany(),
      onClick: () => handleCloseMobileDrawer(),
    },
    ...(showHRFeatures
      ? [
          {
            key: 'novelties_requests',
            icon: 'calendar-2',
            text: isLaptop ? (
              <span>
                Solicitudes <br />
                tiempo fuera
              </span>
            ) : (
              <span>
                Solicitudes tiempo <br /> fuera
              </span>
            ),
            to: routes.NOVELTIES_REQUEST_HISTORY(),
            hidden: !hasSelectedCompany(),
            onClick: () => handleCloseMobileDrawer(),
          },
        ]
      : []),
    {
      key: 'pay_payroll',
      icon: 'wallet',
      text: 'Liquidar nómina',
      to: routes.PERIOD_PAY_PAYROLL(),
      hidden: !hasSelectedCompany(),
      onClick: () => {
        handleCloseMobileDrawer()
        queryClient.removeQueries(periodQueryKey)
      },
      pathsToHighlight: [
        routes.PERIOD_GENERATED_PAYROLL(),
        routes.PERIOD_PAYROLL_VIEW_DETAILS(),
        routes.PERIOD_NOVELTIES_HISTORY(),
        routes.PERIOD_SOCIAL_BENEFIT_PERIODS(),
        routes.PERIOD_DECREE_376_INDEX(),
        routes.PERIOD_SOCIAL_SECURITY_SHOW(),
      ],
    },
    {
      key: 'electronic_payroll',
      icon: 'invoice-receipt',
      text: 'Nómina electrónica',
      to: electronicPeriodId
        ? routes.PERIOD_PAY_ELECTRONIC_PAYROLL(electronicPeriodId)
        : routes.SETTINGS_ELECTRONIC_PAYROLL(),
      hidden: !hasSelectedCompany(),
      onClick: () => handleCloseMobileDrawer(),
      pathsToHighlight: [
        routes.PERIOD_ELECTRONIC_PAYROLL_WORKER_HISTORY(
          electronicPeriodId,
          workerId
        ),
      ],
    },
    {
      key: 'periods_history',
      icon: 'folder-open',
      text: 'Historial',
      to: routes.PERIOD_HISTORY(),
      hidden: !hasSelectedCompany(),
      groupDivider: true,
      onClick: () => handleCloseMobileDrawer(),
      pathsToHighlight: [
        routes.PERIOD_PAY_PREVIOUS_PAYROLL(),
        routes.PERIOD_PAY_ELECTRONIC_PAYROLL(),
        routes.PERIOD_PREVIOUS_GENERATED_PAYROLL(),
        routes.PERIOD_PREVIOUS_PAYROLL_NOVELTIES_HISTORY(),
        routes.PERIOD_PREVIOUS_PAYROLL_DECREE_376_INDEX(),
        routes.PERIOD_PREVIOUS_PAYROLL_SOCIAL_SECURITY_SHOW(),
        routes.PERIOD_ELECTRONIC_PAYROLL_WORKER_HISTORY(),
      ],
      excludedPaths: [
        routes.PERIOD_PAY_ELECTRONIC_PAYROLL(electronicPeriodId),
        routes.PERIOD_ELECTRONIC_PAYROLL_WORKER_HISTORY(
          electronicPeriodId,
          workerId
        ),
      ],
    },
    {
      key: 'work_disabilities_claiming',
      text: 'Reclamaciones',
      icon: 'refund',
      to: routes.DISABILITIES_CLAIMS(),
      hidden: hideHolisticPayrollOptions,
      badge: 'Beta',
      onClick: () => handleCloseMobileDrawer(),
    },
    {
      key: 'financial_wellness',
      text: 'Bienestar financiero',
      icon: 'wired-outline-internship',
      to: routes.ADMIN_FINANCIAL_WELLNESS(),
      hidden: hideHolisticPayrollOptions,
      groupDivider: true,
      badge: 'Beta',
      onClick: () => handleCloseMobileDrawer(),
    },
    {
      key: 'company_workers',
      icon: 'female-and-male',
      text: 'Personas',
      to: routes.WORKER_INDEX('?tab=active'),
      hidden: !hasSelectedCompany(),
      onClick: () => handleCloseMobileDrawer(),
      pathsToHighlight: [
        routes.WORKER_SHOW(),
        routes.WORKER_NEW(),
        routes.WORKER_IMPORT(),
        routes.WORKER_PAYMENTS_HISTORY(),
        routes.WORKER_HOLIDAYS_HISTORY(),
        routes.WORKER_NOVELTIES_HISTORY(),
        routes.WORKER_CONTRACT_INDEX(),
        routes.WORKER_CONTRACT_DETAIL_INDEX(),
        routes.WORKER_CONTRACT_WAGE_INDEX(),
        routes.WORKER_CONTRACT_TERMINATION_NEW(),
        routes.WORKER_CONTRACT_TERMINATION_EDIT(),
        routes.WORKER_CONTRACT_SOCIAL_BENEFITS_INDEX(),
        routes.WORKER_CONTRACT_SOCIAL_BENEFITS_SHOW(),
        routes.WORKER_LOAN_INDEX(),
        routes.WORKER_LOAN_SHOW(),
        routes.WORKER_BENEFITS_SHOW(),
        routes.WORKER_WAGES_UPDATE(),
      ],
    },
    {
      key: 'company_information',
      icon: 'factory',
      text: 'Empresa',
      to: routes.COMPANY_SHOW(companyId),
      hidden: !hasSelectedCompany(),
      groupDivider: isOrganizer() || (hasOneCompany() && !isAccountant()),
      onClick: () => handleCloseMobileDrawer(),
      pathsToHighlight: [routes.COMPANY_EDIT()],
    },
    {
      key: 'companies',
      icon: 'grid-list',
      text: 'Panel multiempresa',
      to: routes.COMPANY_INDEX(),
      groupDivider: true,
      hidden: isOrganizer() || (hasOneCompany() && !isAccountant()),
      onClick: () => {
        clearFetchCompany()
        handleCloseMobileDrawer()
      },
    },
    {
      key: 'settings',
      icon: 'gear-settings',
      text: 'Configuración',
      to: routes.SETTINGS(),
      hidden: hideConfigurationOption,
      onClick: () => handleCloseMobileDrawer(),
      pathsToHighlight: [
        routes.SETTINGS(),
        routes.SETTINGS_ADVANCED(),
        routes.SETTINGS_USERS(),
        routes.SETTINGS_WORKERS_BENEFITS(),
        routes.SETTINGS_COMPANY_HISTORY(),
        routes.SETTINGS_ACCOUNTING(),
        routes.SETTINGS_INTEGRATION_INDEX(),
        routes.SETTINGS_INTEGRATION_ALEGRA_ACCOUNTING(),
      ],
    },
    {
      key: 'subscription',
      icon: 'invoice-pay',
      text: 'Mi suscripción',
      to:
        isAPartnerChild || (isPaymentExoneratedCompany && !isOrganizer())
          ? null
          : routes.SUBSCRIPTION_STATUS(),
      onClick: () => {
        handleCloseMobileDrawer()
        if (isAPartnerChild) {
          openPartnerSubscriptionModal()
        }
        if (isPaymentExoneratedCompany && !isOrganizer()) {
          openPaymentExoneratedSubscriptionModal()
        }
      },
      hidden: hideSubscriptionOption,
      pathsToHighlight: [
        routes.SUBSCRIPTION(),
        routes.SUBSCRIPTION_STATUS(),
        routes.SUBSCRIPTION_PAYMENTS(),
      ],
    },
    {
      key: 'help_center',
      icon: 'info',
      text: 'Centro de ayuda',
      to: HELP_CENTER,
      target: '_blank',
      hidden: !hasSelectedCompany(),
      groupDivider: true,
      onClick: () => handleCloseMobileDrawer(),
    },
    {
      key: 'organizator',
      icon: 'admin',
      text: 'Administrador',
      onClick: () => {
        clearCompany()
        clearFetchCompany()
        handleCloseMobileDrawer()
      },
      to: routes.ORGANIZER_COMPANY_INDEX(),
      hidden: !isOrganizer(),
    },
  ]
}

export default useAdmin
