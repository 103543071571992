import { nanoid } from 'nanoid'

import { Box } from '@mui/material'

import CreditBarProgess from 'components/Benefits/Atoms/BarProgress'
import BenefitsTable from 'components/Benefits/Atoms/BenefitsTable'
import ContainerDetails from 'components/Benefits/Atoms/ContainerDetails'
import DetailSectionContainer from 'components/Benefits/Atoms/DetailSectionContainer'
import CreditResumeInfoCard from 'components/Benefits/Atoms/ResumeCard'

import { formatCurrency } from 'utils/format'

import { columnsData } from './helpers'

const CreditDetails = ({ data = {}, usedByAdmin }) => {
  const { worker = {}, credit = {} } = data

  const paidProgress = parseInt(
    (credit.paid_value * 100) / credit.initial_value,
    10
  )
  const parsedPayments = credit?.payments?.map((payment, index) => ({
    ...payment,
    payment_number: index + 1,
  }))

  const creditDetail = {
    details: [
      { nameDetail: 'Nombre', value: worker.full_name, id: nanoid() },
      { nameDetail: 'Dirección', value: worker.address, id: nanoid() },
      { nameDetail: 'Ciudad', value: worker.city, id: nanoid() },
      {
        nameDetail: 'Valor inicial',
        value: formatCurrency(credit.initial_value),
        id: nanoid(),
      },
      { nameDetail: 'Plazo', value: `${credit.term} cuotas`, id: nanoid() },
      {
        nameDetail: 'Tasa de interés',
        value: `${credit.rate} %`,
        id: nanoid(),
      },
    ],
    progress: {
      paidValue: formatCurrency(credit.paid_value),
      remainingValue: formatCurrency(credit.pending_value),
      progressValue: paidProgress,
    },
    progressDetails: [
      { nameDetail: 'Total cuotas', value: credit.term, id: nanoid() },
      { nameDetail: 'Cuotas pagadas', value: credit.fees_paid, id: nanoid() },
      {
        nameDetail: 'Cuotas restantes',
        value: credit.pending_fees,
        id: nanoid(),
      },
    ],
  }

  return (
    <>
      <DetailSectionContainer
        title="Información crédito"
        removeHeader={usedByAdmin}
      >
        <Box
          sx={(theme) => ({
            display: 'grid',
            gridGap: theme.spacing(3),
            gridTemplateColumns: '1fr',
            [theme.breakpoints.up('md')]: {
              gridTemplateColumns: 'repeat(3, 1fr)',
            },
          })}
        >
          <CreditResumeInfoCard
            headerTitle="Crédito de libranza"
            creditData={credit.number}
            cardBold
          />
          <CreditResumeInfoCard
            headerTitle="Saldo a capital"
            creditData={formatCurrency(credit.balance)}
          />
          <CreditResumeInfoCard
            headerTitle="Valor cuota"
            creditData={formatCurrency(credit.fee_value)}
          />
        </Box>
        <Box
          sx={(theme) => ({
            marginTop: theme.spacing(3),
            display: 'grid',
            gridGap: theme.spacing(3),
            gridTemplateColumns: 'repeat(2, minmax(100px, 1fr))',
            [theme.breakpoints.up('md')]: {
              gridTemplateColumns: 'repeat(3, 1fr)',
            },
          })}
        >
          {creditDetail.details.map(({ nameDetail, value, id }) => (
            <ContainerDetails key={id} nameDetail={nameDetail} detail={value} />
          ))}
        </Box>
      </DetailSectionContainer>
      {!usedByAdmin ? (
        <DetailSectionContainer title="Progreso">
          <CreditBarProgess
            progressPayment={creditDetail.progress.progressValue}
            paidValue={creditDetail.progress.paidValue}
            remainingValue={creditDetail.progress.remainingValue}
          />
          <Box
            sx={(theme) => ({
              marginTop: theme.spacing(3),
              display: 'grid',
              gridGap: theme.spacing(3),
              gridTemplateColumns: 'repeat(2, minmax(100px, 1fr))',
              [theme.breakpoints.up('md')]: {
                gridTemplateColumns: 'repeat(3, 1fr)',
              },
            })}
          >
            {creditDetail.progressDetails.map(({ nameDetail, value, id }) => (
              <ContainerDetails
                key={id}
                nameDetail={nameDetail}
                detail={value}
              />
            ))}
          </Box>
        </DetailSectionContainer>
      ) : null}
      <DetailSectionContainer title="Movimientos" gutterBottom={false}>
        <BenefitsTable columns={columnsData} data={parsedPayments} />
      </DetailSectionContainer>
    </>
  )
}

export default CreditDetails
