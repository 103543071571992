import { Box, Typography } from '@mui/material'

import Table from 'components/UI/Table/Table'
import useWorker from 'components/Worker/useWorker'

import { getCompanyId } from 'utils/company'
import useCompanyCredentialsService from 'utils/hooks/company/credentials'

import DownloadFile from '../../DownloadFile'
import SectionContainer from '../../SectionContainer'
import EmployerData from './EmployerData'
import { companyCredentialsColumn, employeeCredentialsColumns } from './helpers'

const EmployerContainer = () => {
  const companyId = getCompanyId()
  const { worker } = useWorker({ useCache: true })

  const companyCredentialsQueryKey = ['getCredentials', companyId]
  const { companyCredentialsQuery } = useCompanyCredentialsService({
    serviceParams: {
      queryKey: companyCredentialsQueryKey,
    },
  })
  const currentCompanyCredentials = companyCredentialsQuery?.data || []

  const sectionData = [
    {
      id: 'employer',
      title: 'Empleador',
      containerCyLabel: 'employer_container',
      hiddenEdit: true,
      showDivider: false,
      items: [
        {
          id: 'company_documents',
          label: 'Documentos adjuntos',
          itemsList: [
            {
              label: 'RUT',
              value: <DownloadFile file={worker?.files?.tax_document} />,
            },
            {
              label: 'Cámara y comercio',
              value: (
                <DownloadFile file={worker?.files?.identification_document} />
              ),
            },
            {
              label: 'Cédula representante legal',
              value: (
                <DownloadFile
                  file={worker?.files?.legal_representative_document}
                />
              ),
            },
          ],
        },
      ],
    },
  ]

  return (
    <>
      {sectionData.map((item) => (
        <SectionContainer item={item} key={item.id} />
      ))}

      <EmployerData workerId={worker.id} />

      <Box
        sx={(theme) => ({
          padding: theme.spacing(0, 3, 6, 3),
        })}
      >
        <Box
          sx={(theme) => ({
            margin: theme.spacing(0, 0, 3, 2),
          })}
        >
          <Typography variant="lead1">Credenciales de la persona</Typography>
        </Box>

        <Table
          columns={employeeCredentialsColumns}
          data={worker?.credentials || []}
          options={{
            pagination: false,
            search: false,
            toolbar: false,
          }}
        />
      </Box>

      <Box
        sx={(theme) => ({
          padding: theme.spacing(0, 3, 6, 3),
        })}
      >
        <Box
          sx={(theme) => ({
            margin: theme.spacing(0, 0, 3, 2),
          })}
        >
          <Typography variant="lead1">Credenciales del empleador</Typography>
        </Box>

        <Table
          columns={companyCredentialsColumn}
          data={currentCompanyCredentials}
          options={{
            pagination: false,
            search: false,
            toolbar: false,
          }}
        />
      </Box>
    </>
  )
}

export default EmployerContainer
