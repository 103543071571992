import { getCompanyId } from 'utils/company'

import http, { getApiURL } from 'services/httpService'

export const getApplication = (workerId, creditStatus = false) => {
  const url = getApiURL({
    pathname: `/benefits_data/${workerId}/worker_credit_informations`,
    withoutCompany: true,
    searchParams: {
      worker_payroll_credit_information: creditStatus,
    },
  })

  return http.getV2(url)
}

export const applicationMutation = (workerId, data) => {
  const url = getApiURL({
    pathname: `/benefits_data/${workerId}/worker_credit_informations`,
    withoutCompany: true,
  })

  return http.putV2(url, data)
}

export const updateWorkerCredit = (data) => {
  const url = getApiURL({
    pathname: '/benefits_data/workers_credits',
    withoutCompany: true,
  })

  return http.putV2(url, data)
}

export const getWorkersCredits = () => {
  const companyId = getCompanyId()
  const url = getApiURL({
    pathname: `/benefits_data/companies/${companyId}/workers_credits`,
    withoutCompany: true,
  })

  return http.getV2(url)
}

export const getCreditDetail = (workerId, creditId) => {
  const url = getApiURL({
    pathname: `/benefits_data/${workerId}/workers_credits/${creditId}`,
    withoutCompany: true,
  })

  return http.getV2(url)
}
