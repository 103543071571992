import { Box, Typography } from '@mui/material'

import Button from 'components/UI/Button/Button'
import Emoji from 'components/UI/Emoji'

import { redCrossMark } from 'utils/emojis'

const DiscardProcessContent = ({
  discardText,
  handleClose,
  handleUpdateOnboarding,
  isLoading,
}) => {
  return (
    <>
      <Typography
        variant="h3"
        sx={(theme) => ({
          marginBottom: theme.spacing(1),
        })}
      >
        ¿Deseas descartar esta acción? <Emoji code={redCrossMark} />
      </Typography>
      <Typography variant="body1" color="black.dark">
        Descarta esta acción si no quieres realizarle el proceso de{' '}
        {`"${discardText}"`} a la persona. Una acción descartada cuenta como un
        proceso {`"${'Completado'}"`}.
      </Typography>
      <Box
        sx={(theme) => ({
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          gap: theme.spacing(2),
          marginTop: theme.spacing(6),
          flexWrap: 'wrap',
          [theme.breakpoints.up('tablet')]: {
            justifyContent: 'flex-start',
          },
        })}
      >
        <Button onClick={handleUpdateOnboarding} loading={isLoading}>
          Descartar
        </Button>
        <Button variant="outlined" onClick={handleClose} disabled={isLoading}>
          Cancelar
        </Button>
      </Box>
    </>
  )
}

export default DiscardProcessContent
