import { Box, Typography } from '@mui/material'

const DetailSectionContainer = ({
  children,
  title = undefined,
  actions = undefined,
  gutterBottom = true,
  removeHeader = false,
}) => {
  return (
    <Box
      sx={(theme) => ({
        ...(gutterBottom && {
          marginBottom: theme.spacing(8),
        }),
      })}
    >
      {!removeHeader && (title || actions) ? (
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            marginBottom: theme.spacing(4),
          })}
        >
          <Typography variant="h5">{title}</Typography>
          {actions ? <>{actions}</> : null}
        </Box>
      ) : null}
      <>{children}</>
    </Box>
  )
}

export default DetailSectionContainer
