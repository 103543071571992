import { useFormikContext } from 'formik'

import { Box, Typography } from '@mui/material'

import FormField from 'components/UI/Formik/FormField/Index'

import SectionCard from '../SectionCard'

const SocialSecurity = ({ options }) => {
  const { values } = useFormikContext()

  if (values.social_security_file_type === 'unique') {
    values.social_security_branch_name = ''
    values.social_security_branch_code = ''
  }

  return (
    <Box sx={(theme) => ({ marginTop: theme.spacing(4) })}>
      <SectionCard subtitle="Configuración para presentar la seguridad social">
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(2),
            width: '100%',
          })}
        >
          <Typography
            variant="body1"
            color="black.dark"
            sx={(theme) => ({ marginBottom: theme.spacing(2) })}
          >
            Elige cómo se hará la presentación de la seguridad social.
          </Typography>
          <Box
            component="ul"
            sx={(theme) => ({
              listStyleType: 'square',
              marginTop: 0,
              paddingLeft: theme.spacing(3),
              marginBottom: theme.spacing(1),
            })}
          >
            <li>
              <b>Única: el aporte se hará de forma consolidada.</b>
            </li>
            <li>
              <b>Sucursal: el aporte se hará por cada sucursal.</b>
            </li>
          </Box>
          <FormField
            name="social_security_file_type"
            variant="radio-group"
            options={options}
            row
          />
        </Box>
        {values?.social_security_file_type === 'branch' ? (
          <Box
            sx={(theme) => ({
              display: 'grid',
              gridTemplateColumns: '1fr',
              flexDirection: 'column',
              rowGap: theme.spacing(2),
              width: '100%',
              marginTop: theme.spacing(2),
              [theme.breakpoints.up('tablet')]: {
                columnGap: theme.spacing(1.75),
                gridTemplateColumns: 'repeat(2, 1fr)',
              },
            })}
          >
            <FormField
              name="social_security_branch_name"
              label="Nombre de la sucursal"
              variant="text"
              placeholder="Ingresa el nombre de la sucursal"
              optional={false}
            />
            <FormField
              name="social_security_branch_code"
              label="Código de la sucursal"
              variant="number"
              placeholder="Ingresa el código de la sucursal"
              optional={false}
              decimalSeparator={false}
              thousandSeparator={''}
            />
          </Box>
        ) : null}
      </SectionCard>
    </Box>
  )
}

export default SocialSecurity
