import { Typography } from '@mui/material'

import LoadingBox from 'components/UI/Loading/LoadingBox'
import Modal from 'components/UI/Modal/Modal'

import { getCompanyId } from 'utils/company'
import useAffiliationsService from 'utils/hooks/affiliations/affiliations'
import useWorkerService from 'utils/hooks/worker/workerService'

import InProgressContent from './InProgressContent'
import ReceivedContent from './ReceivedContent'

const CurrentContent = ({ worker, affiliations, handleClose }) =>
  affiliations.status === 'received' ? (
    <ReceivedContent
      worker={worker}
      affiliations={affiliations}
      handleClose={handleClose}
    />
  ) : (
    <InProgressContent
      worker={worker}
      affiliations={affiliations}
      handleClose={handleClose}
    />
  )

const InProcessModal = ({
  state = {},
  handleClose,
  resetInProcessModalState,
}) => {
  const companyId = getCompanyId()

  const { workerId } = state
  const workerQueryKey = ['getWorkerById', workerId]
  const affiliationQueryKey = ['getAffiliationById', workerId, companyId]

  const { workerQuery } = useWorkerService({
    serviceParams: { queryKey: workerQueryKey, workerId },
    queryOptions: {
      enabled: Boolean(workerId) && state.open,
    },
  })
  const { affiliationsQuery } = useAffiliationsService({
    serviceParams: { queryKey: affiliationQueryKey, workerId, companyId },
    queryOptions: {
      enabled: Boolean(workerId) && state.open,
    },
  })

  const worker = workerQuery?.data || {}
  const affiliations = affiliationsQuery?.data || {}

  return (
    <Modal
      open={state.open}
      header={`Tu solicitud de afiliación a Seguridad Social está ${
        affiliations.status === 'waiting_response' ? 'pendiente' : 'en proceso'
      }`}
      onCancel={handleClose}
      hideFooter
      adornment={[
        {
          variant: 'signature',
          color: 'complementary1.light',
          width: 177,
          height: 195,
          sx: { bottom: '-4rem', right: '2rem' },
        },
      ]}
      paperSx={{
        maxWidth: '45.5rem',
      }}
      dialogProps={{
        TransitionProps: {
          onExited: resetInProcessModalState,
        },
      }}
    >
      {affiliations.status === 'waiting_response' ? (
        <Typography
          variant="h4"
          color="accent4.dark"
          sx={(theme) => ({ marginBottom: theme.spacing(3) })}
        >
          Fecha de creación: {affiliations.updated_at}
        </Typography>
      ) : null}
      <Typography
        variant="body1"
        color="black.dark"
        sx={(theme) => ({ marginBottom: theme.spacing(4) })}
      >
        La información de la persona será utilizada para ayudarte a gestionar la
        afiliación a Seguridad Social, pensión y cesantías.
      </Typography>
      {workerQuery.isLoading || affiliationsQuery.isLoading ? (
        <LoadingBox />
      ) : (
        <CurrentContent
          worker={worker}
          affiliations={affiliations}
          handleClose={handleClose}
        />
      )}
    </Modal>
  )
}

export default InProcessModal
