import { useQuery } from 'react-query'

import { formatDisplayDateString } from 'utils/dateTime'
import { formatCurrency } from 'utils/format'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import { getWorkerId } from 'utils/worker'

import { getApplication, getCreditDetail } from 'services/benefits/credits'

export const columnsData = [
  {
    Header: 'Número cuota',
    accessor: 'payment_number',
  },
  {
    Header: 'Fecha de pago',
    accessor: 'date',
    Cell: ({ row }) => formatDisplayDateString(row.original.date),
  },
  {
    Header: 'Total pagado',
    accessor: 'value',
    Cell: ({ row }) => formatCurrency(row.original.value),
  },
]

export const useCreditApplicationStatus = (options = {}) => {
  const workerId = getWorkerId()
  const { handleError } = useErrorHandler()

  const {
    data: { data: creditStatusData } = {},
    isLoading: isGettingCreditStatus,
    ...restCreditStatus
  } = useQuery('creditStatus', () => getApplication(workerId, true), {
    onError: (error) => {
      handleError(error)
    },
    retry: 3,
    ...options,
  })

  return {
    creditStatusData,
    isGettingCreditStatus,
    ...restCreditStatus,
  }
}

export const useCreditDetail = (
  creditId,
  creditStatusActive,
  currentWorkerId
) => {
  const workerId = getWorkerId()
  const { handleError } = useErrorHandler()

  const {
    data: { data: creditDetailData } = {},
    isLoading: isGettingCreditDetail,
    ...restCreditDetailData
  } = useQuery(
    'creditDetail',
    () => getCreditDetail(workerId || currentWorkerId, creditId),
    {
      enabled: creditStatusActive,
      onError: (error) => {
        handleError(error)
      },
    }
  )

  return {
    creditDetailData,
    isGettingCreditDetail,
    ...restCreditDetailData,
  }
}
