import { Box, IconButton, Tooltip, Typography } from '@mui/material'

const Actions = ({ leftAction, rightAction }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography
        variant="lead1"
        sx={(theme) => ({
          marginBottom: theme.spacing(0.6),
        })}
        textAlign="center"
      >
        Acciones
      </Typography>
      <Box
        sx={(theme) => ({
          display: 'flex',
          gap: theme.spacing(1),
        })}
      >
        <Tooltip arrow title={leftAction.tooltip}>
          <IconButton
            size="large"
            color="complementary1"
            onClick={leftAction.onClick}
          >
            {leftAction.icon}
          </IconButton>
        </Tooltip>
        <Tooltip arrow title={rightAction.tooltip}>
          <IconButton
            size="large"
            color="complementary1"
            onClick={rightAction.onClick}
          >
            {rightAction.icon}
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  )
}

export default Actions
