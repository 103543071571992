import { Card, CardContent, CardHeader, Typography } from '@mui/material'

const CreditResumeInfoCard = ({
  headerTitle,
  creditData,
  cardBold = false,
  hidden = false,
}) => {
  return (
    <Card
      sx={(theme) => ({
        padding: theme.spacing(3, 4, 1, 4),
        boxShadow: 'none',
        border: '1px solid',
        borderColor: theme.palette.black.light,
        flex: '1',
        minWidth: '220px',
        ...(cardBold && {
          backgroundColor: theme.palette.white.dark,
          border: 'none',
        }),
      })}
      hidden={hidden}
    >
      <CardHeader
        sx={(theme) => ({
          color: theme.palette.black.light,
          padding: theme.spacing(1, 0),
        })}
        title={<Typography variant="body2">{headerTitle}</Typography>}
      />
      <CardContent
        sx={(theme) => ({
          padding: theme.spacing(1, 0),
        })}
      >
        <Typography variant="h5">{creditData}</Typography>
      </CardContent>
    </Card>
  )
}

export default CreditResumeInfoCard
