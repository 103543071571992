import { Form, Formik } from 'formik'
import { useState } from 'react'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { Box, Typography } from '@mui/material'

import useModals from 'components/App/ModalsManager/useModals'
import Button from 'components/UI/Button/Button'
import FormField from 'components/UI/Formik/FormField/Index'

import useAffiliationsService from 'utils/hooks/affiliations/affiliations'
import useDownloadURI from 'utils/hooks/useDownloadURI'
import useFileName from 'utils/hooks/useFileName'
import useNotifications from 'utils/hooks/useNotifications'

import { ORGANIZER_AFFILIATIONS } from 'config/routes'

import { getInitialValues, getValidationSchema } from './helpers'

const description = {
  rejected:
    'Describe brevemente el motivo por el que rechazas esta afiliación Ej: Falta algún documento, un dato mal diligenciado, etc.',
  approved:
    'Para aprobar la afiliación debes adjuntar los soportes requeridos, ya que la empresa solicitante los necesita.',
}

const DownloadButton = ({ file }) => {
  const downloadURI = useDownloadURI()

  if (!file) return null

  return (
    <Button
      onClick={() => downloadURI(file)}
      sx={(theme) => ({
        marginTop: theme.spacing(3),
        padding: 0,
        minWidth: 'auto',
        width: 'fit-content',
        backgroundColor: 'transparent',
        textDecoration: 'underline',
        '&:hover': {
          backgroundColor: 'transparent',
          textDecoration: 'underline',
        },
        '&:focus': {
          boxShadow: 'none',
        },
      })}
    >
      <Typography
        variant="lead2"
        sx={(theme) => ({ color: theme.palette.black.dark })}
      >
        Ver
      </Typography>
    </Button>
  )
}

const ModalActions = ({ action, workerId, contractCategory, documentType }) => {
  const [actionSubmitForm, setActionSubmitForm] = useState('')
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const modals = useModals()
  const { showSuccessMessage } = useNotifications()
  const isRejectAction = action === 'rejected'
  const affiliationQueryKey = ['getAffiliationById', workerId]
  const { data: { certificates_data: certificatesData = [] } = {} } =
    queryClient.getQueryData(affiliationQueryKey) || {}
  const { affiliationsMutation } = useAffiliationsService({
    queryOptions: {
      enabled: false,
    },
  })

  const compensationFundDocumentName = useFileName(
    certificatesData[0]?.document_url
  )
  const healthDocumentName = useFileName(certificatesData[1]?.document_url)
  const pensionDocumentName = useFileName(certificatesData[2]?.document_url)
  const riskDocumentName = useFileName(certificatesData[3]?.document_url)

  const sizeDocuments = certificatesData.filter(
    ({ completed }) => completed
  ).length

  const onSubmit = (values) => {
    const affiliationData = new FormData()

    if (isRejectAction) {
      affiliationData.append('status', 'rejected')
      if (values.comment && values.comment.length > 0) {
        affiliationData.append('rejected_comment', values.comment)
      }
    } else if (!isRejectAction) {
      if (actionSubmitForm === 'completeAffiliation') {
        affiliationData.append('status', 'approved')
      }

      if (values.approved_compensation_fund_document instanceof File) {
        affiliationData.append(
          'approved_compensation_fund_document',
          values.approved_compensation_fund_document
        )
      }

      if (values.approved_health_document instanceof File) {
        affiliationData.append(
          'approved_health_document',
          values.approved_health_document
        )
      }

      if (values.approved_pension_document instanceof File) {
        affiliationData.append(
          'approved_pension_document',
          values.approved_pension_document
        )
      }

      if (values.approved_risk_document instanceof File) {
        affiliationData.append(
          'approved_risk_document',
          values.approved_risk_document
        )
      }
    }

    affiliationsMutation.mutate(
      {
        mutationMethod: 'PUT',
        workerId,
        affiliationData,
      },
      {
        onSuccess: async () => {
          if (actionSubmitForm === 'uploadFiles') {
            showSuccessMessage('La certificación ha sido cargada')
          } else {
            showSuccessMessage(
              `La afiliación ha sido ${
                isRejectAction ? 'rechazada' : 'aprobada'
              }`
            )
          }
          modals.closeAll()
          await queryClient.invalidateQueries(['getAllAffiliations'], {
            exact: false,
          })
          navigate(ORGANIZER_AFFILIATIONS())
        },
      }
    )
  }

  return (
    <Formik
      onSubmit={onSubmit}
      validationSchema={getValidationSchema(
        isRejectAction,
        documentType,
        contractCategory
      )}
      initialValues={getInitialValues(certificatesData, isRejectAction)}
    >
      {({ dirty }) => {
        return (
          <Form>
            <Typography
              variant="body2"
              color="black.dark"
              sx={(theme) => ({
                color: theme.palette.black.dark,
                marginBottom: theme.spacing(3),
              })}
            >
              {description[action]}
            </Typography>
            {action === 'rejected' ? (
              <FormField
                name="comment"
                variant="textarea"
                label="Detalle del rechazo"
                rows="4"
                inputProps={{
                  maxLength: 1000,
                }}
              />
            ) : null}
            {action === 'approved' ? (
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  flexDirection: 'column',
                  gap: theme.spacing(2),
                })}
              >
                <Box
                  sx={(theme) => ({ display: 'flex', gap: theme.spacing(2) })}
                >
                  <FormField
                    name="approved_compensation_fund_document"
                    label="Certificado de afiliación CCF"
                    variant="file"
                    fileType="application/pdf"
                    accept="application/pdf"
                    fileName={compensationFundDocumentName}
                  />
                  <DownloadButton file={certificatesData[0]?.document_url} />
                </Box>
                <Box
                  sx={(theme) => ({ display: 'flex', gap: theme.spacing(2) })}
                >
                  <FormField
                    name="approved_health_document"
                    label="Certificado de afiliación EPS"
                    variant="file"
                    fileType="application/pdf"
                    accept="application/pdf"
                    fileName={healthDocumentName}
                  />
                  <DownloadButton file={certificatesData[1]?.document_url} />
                </Box>
                <Box
                  sx={(theme) => ({ display: 'flex', gap: theme.spacing(2) })}
                >
                  <FormField
                    name="approved_pension_document"
                    label="Certificado de afiliación Pensión"
                    variant="file"
                    fileType="application/pdf"
                    accept="application/pdf"
                    fileName={pensionDocumentName}
                  />
                  <DownloadButton file={certificatesData[2]?.document_url} />
                </Box>
                <Box
                  sx={(theme) => ({ display: 'flex', gap: theme.spacing(2) })}
                >
                  <FormField
                    name="approved_risk_document"
                    label="Certificado de afiliación ARL"
                    variant="file"
                    fileType="application/pdf"
                    accept="application/pdf"
                    fileName={riskDocumentName}
                  />
                  <DownloadButton file={certificatesData[3]?.document_url} />
                </Box>
              </Box>
            ) : null}
            <Box
              sx={(theme) => ({
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                gap: theme.spacing(2),
                marginTop: theme.spacing(6),
                flexWrap: 'wrap',
                [theme.breakpoints.up('tablet')]: {
                  justifyContent: 'flex-start',
                },
              })}
            >
              {!isRejectAction ? (
                <>
                  {sizeDocuments === 4 ? (
                    <>
                      <Button
                        type="submit"
                        loading={affiliationsMutation.isLoading}
                        onClick={() =>
                          setActionSubmitForm('completeAffiliation')
                        }
                      >
                        Completar Afiliación
                      </Button>

                      <Button
                        variant="outlined"
                        onClick={() => modals.closeAll()}
                      >
                        Cancelar
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        type="submit"
                        loading={affiliationsMutation.isLoading}
                        disabled={!dirty}
                        onClick={() => setActionSubmitForm('uploadFiles')}
                      >
                        Cargar Certificados
                      </Button>
                      <Button
                        type="submit"
                        variant="outlined"
                        disabled={sizeDocuments < 1 && !dirty}
                        loading={affiliationsMutation.isLoading}
                        onClick={() =>
                          setActionSubmitForm('completeAffiliation')
                        }
                      >
                        Completar Afiliación
                      </Button>
                    </>
                  )}
                </>
              ) : (
                <>
                  <Button
                    type="submit"
                    loading={affiliationsMutation.isLoading}
                  >
                    Enviar información
                  </Button>
                  <Button variant="outlined" onClick={() => modals.closeAll()}>
                    Cancelar
                  </Button>
                </>
              )}
            </Box>
          </Form>
        )
      }}
    </Formik>
  )
}

export default ModalActions
