import useLocalStorage from '@rehooks/local-storage'
import { useState } from 'react'

import { Typography } from '@mui/material'

import Page from 'components/UI/Page/Page'
import Table from 'components/UI/Table/Table'
import { getEmptyStateTitle } from 'components/Worker/SSAffiliations/OnboardingWorkers/SSAffiliation/helpers'

import { getAllAffiliations } from 'services/organizer/affiliations'

import addPersonLogo from 'assets/images/views/worker/add_person.png'

import { getActions, getColumns } from './helpers'

const OrganizerAffiliationsIndex = () => {
  const [, setCompanyId] = useLocalStorage('company_id')
  const [filter, setFilter] = useState('waiting_response')
  const [affiliations, setAffiliations] = useState({})

  const handleChange = (newValue) => setFilter(newValue)

  const handleOpenAffiliation = (companyId) => {
    setCompanyId(companyId)
  }

  const columns = getColumns(handleOpenAffiliation)
  const actions = getActions({
    handleOpenAffiliation,
    filter,
    handleChange,
    affiliations,
  })

  const fetchAffiliations = (pageIndex, search) => {
    return {
      queryKey: ['getAllAffiliations', pageIndex, search, filter],
      queryFunction: () =>
        getAllAffiliations({
          searchParams: {
            page: pageIndex + 1,
            search,
            filter,
          },
        }),
      queryOptions: {
        onSuccess: ({ data }) => setAffiliations(data.totals),
      },
    }
  }

  return (
    <Page
      documentTitle="Afiliaciones a Seguridad Social"
      header="Afiliaciones a Seguridad Social"
    >
      <Typography
        sx={(theme) => ({
          marginBottom: theme.spacing(7),
        })}
      >
        Acá puedes gestionar las afiliaciones a Seguridad, pensión y cesantías
        de las empresas.
      </Typography>
      <Table
        data={fetchAffiliations}
        columns={columns}
        actions={actions}
        options={{
          alignActionsCell: 'center',
          customQueryFetch: 'affiliations',
          emptyState: {
            title: getEmptyStateTitle(filter),
            hideDescription: true,
            logo: addPersonLogo,
          },
          version: {
            toolbar: 'v2',
          },
        }}
      />
    </Page>
  )
}

export default OrganizerAffiliationsIndex
