import { useState } from 'react'
import { useQueryClient } from 'react-query'

import Modal from 'components/UI/Modal/Modal'

import { getCompanyId } from 'utils/company'
import useBackgroundCheckService from 'utils/hooks/backgroundCheck/backgroundCheck'

import DiscardAction from '../SSAffiliation/DiscardAction'
import DiscardProcessContent from '../SSAffiliation/DiscardProcessContent'
import { discardText, discardTitles } from '../SSAffiliation/helpers'
import useUpdateOnboarding from '../useUpdateOnboarding'
import BackgroundCheckModalContent from './BackgroundCheckModalContent'

const BackgroundCheckInitialConsultModal = ({
  state,
  handleClose,
  resetBackgroundCheckModalState,
  openBackgroundCheckModal,
}) => {
  const queryClient = useQueryClient()
  const companyId = getCompanyId()
  const { updateOnboarding, onboardingMutation } = useUpdateOnboarding()
  const { backgroundCheckMutation } = useBackgroundCheckService({
    queryOptions: { enabled: false },
  })
  const [discard, setDiscard] = useState(
    state.backgroundCheck?.status === 'not_apply'
  )

  const handleUpdateOnboarding = () => {
    updateOnboarding({
      onboardingId: state.onboardingId,
      data: {
        action_to_discard: 'background_check',
      },
      onSuccessCallback: () => {
        handleClose()
      },
    })
  }

  const handleCreateBackgroundCheck = () => {
    backgroundCheckMutation.mutate(
      {
        mutationMethod: 'POST',
        workerId: state.workerId,
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries([
            'getCompanyOnboardings',
            companyId,
          ])
          handleClose()
          openBackgroundCheckModal({
            onboardingId: state.onboardingId,
            workerId: state.workerId,
            workerName: state.workerName,
            backgroundCheck: state.backgroundCheck,
          })
        },
      }
    )
  }

  const resetModalState = () => {
    setDiscard(false)
    resetBackgroundCheckModalState()
  }

  return (
    <Modal
      open={state.open}
      onCancel={handleClose}
      header={
        <DiscardAction
          discardTitles={discardTitles.backgroundCheck}
          discard={discard}
          setDiscard={setDiscard}
        />
      }
      hideFooter
      isLoading={onboardingMutation.isLoading}
      adornment={[
        {
          variant: 'asterisk',
          color: 'accent2.light',
          width: 120,
          height: 120,
          sx: { bottom: '1rem', right: '1.5rem' },
        },
      ]}
      paperSx={{
        maxWidth: '45.5rem',
      }}
      dialogProps={{
        TransitionProps: {
          onExited: resetModalState,
        },
      }}
    >
      {!discard ? (
        <BackgroundCheckModalContent
          state={state}
          handleClose={handleClose}
          hadleSubmit={handleCreateBackgroundCheck}
          isLoading={backgroundCheckMutation.isLoading}
        />
      ) : null}
      {discard ? (
        <DiscardProcessContent
          discardText={discardText.discardBackgroundCheck}
          handleClose={handleClose}
          handleUpdateOnboarding={handleUpdateOnboarding}
          isLoading={onboardingMutation.isLoading}
        />
      ) : null}
    </Modal>
  )
}

export default BackgroundCheckInitialConsultModal
