import Page from 'components/UI/Page/Page'

import robotNotFoundImg from 'assets/images/views/common/aleluya_beach_man.svg'

import ErrorPageAtom from './Atoms/ErrorPage'

const RouteNotFound = () => {
  return (
    <Page documentTitle="Página no encontrada">
      <ErrorPageAtom
        subtitle="Esta página ya no existe"
        description="Al parecer estás perdido, probablemente la página que estás buscando fue trasladada o eliminada."
        image={robotNotFoundImg}
      />
    </Page>
  )
}

export default RouteNotFound
