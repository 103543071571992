import { Navigate, useLocation } from 'react-router-dom'

import { useUser } from 'components/App/UserContext/useUser'

import {
  USER_PROFILE,
  WORKER_BENEFITS_CREDIT_FORM,
  WORKER_BENEFITS_CREDIT_SIMULATOR,
  WORKER_PROFILE_BENEFITS,
} from 'config/routes'

/**
 * View related to a worker so the user needs to have worker role to access this routes
 */
const View = ({ children }) => {
  const { isWorker, workerWithBenefits } = useUser()
  const { pathname } = useLocation()

  const isUserProfileRoute = USER_PROFILE() === pathname

  const notAllowedUser =
    !isWorker ||
    ([
      WORKER_PROFILE_BENEFITS(),
      WORKER_BENEFITS_CREDIT_FORM(),
      WORKER_BENEFITS_CREDIT_SIMULATOR(),
    ].includes(pathname) &&
      !workerWithBenefits)

  if (notAllowedUser && !isUserProfileRoute) {
    return <Navigate to="/" />
  }

  return children
}

export default View
