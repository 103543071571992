import { Box, Typography, useTheme } from '@mui/material'

import Adornment from 'components/UI/Adornment'
import OrganizationEmail from 'components/UI/OrganizationEmail'
import SlideShow from 'components/UI/SlideShow/Index'

import { getDistributor } from 'utils/auth'

import alegraLogo from 'assets/images/logos/alegra/alegra_logo.svg'
import aleluyaMonogram from 'assets/images/logos/aleluya_monogram_positive.webp'
import arusLogo from 'assets/images/logos/arus/logo_arus.svg'
import aleluyaBeachMan from 'assets/images/views/common/aleluya_beach_man.svg'
import aleluyaDocumentsMan from 'assets/images/views/common/aleluya_documents_man.svg'
import aleluyaPersonPurple from 'assets/images/views/common/aleluya_person_purple.svg'

const adornments = [
  {
    variant: 'star',
    styles: {
      container: {
        height: '20.3%',
        backgroundColor: 'primary.main',
      },
      svg: {
        width: '5.68rem',
        height: '6.25rem',
        color: 'white.main',
      },
    },
  },
  {
    variant: 'signature',
    styles: {
      container: {
        flex: 1,
        backgroundColor: 'accent3.main',
      },
      svg: {
        width: '27.68rem',
        height: '27.68rem',
        color: 'white.main',
      },
    },
  },
  {
    variant: 'asterisk',
    styles: {
      container: {
        height: '30%',
        backgroundColor: 'complementary2.main',
      },
      svg: {
        width: '6.25rem',
        height: '6.25rem',
        color: 'white.main',
      },
    },
  },
]

const Slide = ({ step, src, title, description }) => {
  const backgroundColor = {
    payroll: 'accent2.light',
    electronic_payroll: 'complementary1.light',
    report: 'accent1.light',
  }

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={(theme) => ({
          width: '100%',
          height: '100%',
          padding: theme.spacing(2),
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: backgroundColor[step],
          position: 'relative',
        })}
      >
        <Box
          sx={{
            position: 'absolute',
            width: '60%',
            height: '70%',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            margin: 'auto',
          }}
        >
          <Box
            component="img"
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
            src={src}
            alt={title}
            loading="lazy"
          />
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.accent4.main,
          textAlign: 'center',
          color: theme.palette.white.main,
          padding: theme.spacing(4, 3),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          minHeight: '30%',
          zIndex: 2,
        })}
      >
        <Box
          sx={{
            maxWidth: '32.375rem',
          }}
        >
          <Typography variant="h3" gutterBottom>
            {title}
          </Typography>
          <Typography variant="body1">{description}</Typography>
        </Box>
      </Box>
    </Box>
  )
}

const slides = [
  {
    id: 'payroll_step',
    content: (
      <Slide
        step="payroll"
        src={aleluyaPersonPurple}
        title="Liquida tu nómina en tiempo récord"
        description="Ingresa las novedades y Aleluya hace todos los cálculos por ti de forma automática y sin errores."
      />
    ),
  },
  {
    id: 'electronic_payroll_step',
    content: (
      <Slide
        step="electronic_payroll"
        src={aleluyaDocumentsMan}
        title="Kit de Nómina Electrónica"
        description="Habilitación, emisión, firma digital y capacitación para que le cumplas a la DIAN y te evites dolores de cabeza."
      />
    ),
  },
  {
    id: 'report_step',
    content: (
      <Slide
        step="report"
        src={aleluyaBeachMan}
        title="Automatiza la gestión de tu nómina"
        description="Colillas de pago, integración con tu software contable, archivo de pago de nómina y seguridad social, reportes y mucho más."
      />
    ),
  },
]

const AuthSlideShow = () => {
  const themeInstance = useTheme()
  const distributor = getDistributor()
  const designConfiguration = {
    nominapp: {
      logo: aleluyaMonogram,
      color: themeInstance.palette.black.main,
      backgroundColor: themeInstance.palette.primary.dark,
      width: 112,
      height: 112,
      alt: 'Logo aleluya',
    },
    arus: {
      logo: arusLogo,
      color: themeInstance.palette.primary.main,
      backgroundColor: themeInstance.palette.white.main,
      width: 85,
      height: 66,
      alt: 'Logo suaporte',
    },
    alegra_claro: {
      logo: alegraLogo,
      color: themeInstance.palette.primary.main,
      backgroundColor: themeInstance.palette.white.main,
      width: 180,
      height: 77,
      alt: 'Logo alegra',
    },
  }

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      }}
    >
      <Box sx={{ display: 'flex', flex: 1 }}>
        <SlideShow slides={slides} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '24.4%',
          }}
        >
          {adornments.map(({ variant, styles: { svg, container } }) => {
            return (
              <Box
                key={variant}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  overflow: 'hidden',
                  ...container,
                }}
              >
                <Box
                  sx={(theme) => ({
                    position: 'relative',
                    width: '50%',
                    height: '50%',
                    ...(variant === 'signature' && {
                      width: '100%',
                      height: '100%',
                      transform: 'scale(2.4)',
                    }),
                    [theme.breakpoints.up('lg')]: {
                      width: '55%',
                      height: '55%',
                      ...(variant === 'signature' && {
                        width: '100%',
                        height: '100%',
                        transform: 'scale(2.2)',
                      }),
                    },
                    [theme.breakpoints.up('desktop')]: {
                      ...(variant === 'signature' && {
                        width: '100%',
                        height: '100%',
                        transform: 'scale(1.6)',
                        left: '10%',
                      }),
                    },
                  })}
                >
                  <Adornment
                    sx={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                    }}
                    variant={variant}
                    width="100%"
                    height="100%"
                    color={svg.color}
                  />
                </Box>
              </Box>
            )
          })}
        </Box>
      </Box>

      <Box sx={{ display: 'flex', height: '18.5%' }}>
        <Box
          sx={(theme) => ({
            width: '23%',
            backgroundColor: theme.palette.accent3.main,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          })}
        >
          <Box
            sx={(theme) => ({
              width: '4.375rem',
              height: '4.375rem',
              borderRadius: '100%',
              backgroundColor: theme.palette.complementary2.main,
            })}
          />
        </Box>
        <Box
          sx={(theme) => ({
            backgroundColor: designConfiguration[distributor]?.backgroundColor,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            columnGap: theme.spacing(2),
            flex: 1,
          })}
        >
          <img
            src={designConfiguration[distributor]?.logo}
            alt={designConfiguration[distributor]?.alt}
            width={designConfiguration[distributor]?.width}
            height={designConfiguration[distributor]?.height}
            loading="lazy"
          />
          <Box
            sx={{
              color: designConfiguration[distributor]?.color,
            }}
          >
            <Typography variant="h4">Equipo Aleluya</Typography>
            <Typography variant="body1">¿Te ayudamos con algo?</Typography>
            <Typography variant="body1">
              Escríbenos a{' '}
              <OrganizationEmail
                sx={{
                  color: designConfiguration[distributor]?.color,
                }}
              />
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default AuthSlideShow
