import { Form, Formik } from 'formik'
import { useState } from 'react'
import { useQueryClient } from 'react-query'

import { Box, Typography } from '@mui/material'

import useSubscription from 'components/Subscription/Atoms/useSubscription'
import Button from 'components/UI/Button/Button'
import LoadingBox from 'components/UI/Loading/LoadingBox'
import Modal from 'components/UI/Modal/Modal'
import NewSteps from 'components/UI/Steps/NewSteps'

import { getCompanyId } from 'utils/company'
import { getBase64FromFile, isObjectEmpty } from 'utils/general'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'
import useWorkerService from 'utils/hooks/worker/workerService'

import { getWorkerDirtyValues } from '../../../Form/helpers'
import FieldsBasic from './StepsForm/FormFieldsBasic'
import FieldsLabor from './StepsForm/FormFieldsLabor'
import FieldsPayment from './StepsForm/FormFieldsPayment'
import FieldsPersonal from './StepsForm/FormFieldsPersonal'
import { getInitialValues, stepsData } from './helpers'

const NewWorkerModal = ({ state, handleClose }) => {
  const { subscription } = useSubscription()
  const enableRefreshSubscription = subscription?.type === 'year'
  const { workerId, workerName } = state
  const [currentStep, setCurrentStep] = useState(0)
  const [progressStep, setProgressStep] = useState(0)
  const workerQueryKey = ['getWorkerById', workerId]
  const queryClient = useQueryClient()
  const { showSuccessMessage } = useNotifications()
  const { handleError } = useErrorHandler()

  const { workerQuery, workerMutation } = useWorkerService({
    serviceParams: { queryKey: workerQueryKey, workerId },
    queryOptions: {
      enabled: Boolean(workerId),
    },
  })
  const [currentWorker, setCurrentWorker] = useState({})

  const initialValues = getInitialValues(
    !workerId ? currentWorker : workerQuery.data
  )

  const handleClickStep = (index) => {
    setCurrentStep(index)
  }

  const handlePreviousStep = () => {
    setCurrentStep((previous) => previous - 1)
  }

  const callbackError = (error, form) => {
    handleError(error, form, {
      errorsToNotificate: [
        { object: 'worker' },
        { object: 'id_number' },
        { object: 'email' },
        // error returned when a user update the end_date of a contract but it can't
        // be done because the deductions exceed the value to be paid to the worker
        { object: 'worker_payment' },
      ],
      errorFieldMap: { user: 'email' },
    })

    form.setSubmitting(false)
  }

  const resetModalState = () => {
    setCurrentStep(0)
    setProgressStep(0)
    setCurrentWorker({})
  }

  const closeModal = async () => {
    await queryClient.invalidateQueries(['filteredWorkers'], { exact: false })
    resetModalState()
    handleClose()
  }

  const callbackSuccess = async (values, response, isEditing) => {
    if (enableRefreshSubscription)
      queryClient.invalidateQueries(['getSubscription', getCompanyId()])

    setCurrentWorker({ ...values, id: response?.id || workerId })
    await queryClient.invalidateQueries(workerQueryKey, { exact: false })

    await queryClient.invalidateQueries(['getCompanyOnboardings'], {
      exact: false,
    })

    if (currentStep < stepsData.length - 1) {
      setCurrentStep((previousStep) => previousStep + 1)

      if (currentStep === progressStep) {
        setProgressStep((previousStep) => previousStep + 1)
      }

      // if it's the first step and it's creating a new worker
      if (currentStep === 0 && !isEditing) {
        showSuccessMessage('La persona fue creada exitosamente')
      }
    } else {
      closeModal()
    }
  }

  const handleSubmit = async (values, form) => {
    const isEditing =
      (!workerId && Boolean(currentWorker.id)) || Boolean(workerId)

    const { dirtyWorker } = getWorkerDirtyValues(
      initialValues,
      values,
      isEditing
    )

    if (!isObjectEmpty(dirtyWorker)) {
      if (isEditing) {
        workerMutation.mutate(
          {
            mutationMethod: 'PATCH',
            worker:
              currentStep === 0
                ? {
                    ...dirtyWorker,
                    picture:
                      dirtyWorker.picture?.image instanceof File
                        ? {
                            ...dirtyWorker.picture,
                            image: await getBase64FromFile(
                              dirtyWorker.picture.image
                            ),
                          }
                        : {},
                  }
                : {
                    ...dirtyWorker,
                  },
            workerId: workerId || currentWorker.id,
          },
          {
            onSuccess: async ({ data: response }) => {
              await callbackSuccess(values, response, isEditing)
            },
            onError: (error) => {
              callbackError(error, form)
            },
          }
        )
      } else {
        Object.assign(dirtyWorker, {
          fullName: `${dirtyWorker.name} ${dirtyWorker.last_name}`,
        })

        workerMutation.mutate(
          {
            mutationMethod: 'POST',
            worker:
              currentStep === 0
                ? {
                    worker: {
                      ...dirtyWorker,
                      picture:
                        dirtyWorker.picture?.image instanceof File
                          ? {
                              ...dirtyWorker.picture,
                              image: await getBase64FromFile(
                                dirtyWorker.picture.image
                              ),
                            }
                          : null,
                    },
                  }
                : { worker: { ...dirtyWorker } },
          },
          {
            onSuccess: async ({ data: response }) => {
              await callbackSuccess(values, response)
            },
            onError: (error) => {
              callbackError(error, form)
            },
          }
        )
      }
    } else {
      setCurrentStep((previousStep) => previousStep + 1)
    }
  }

  return (
    <Modal
      open={state.open}
      onCancel={handleClose}
      header={
        !workerName
          ? 'Crear nueva Persona'
          : `Completar el registro de ${workerName}`
      }
      hideFooter
      isLoading={workerMutation.isLoading}
      adornment={[
        {
          variant: 'spring',
          width: 196,
          height: 86,
          color: 'accent4.light',
          sx: {
            bottom: '1.25rem',
            right: '2.125rem',
          },
        },
      ]}
      paperSx={{
        maxWidth: {
          tablet: '45.5rem',
        },
      }}
      dialogProps={{
        TransitionProps: {
          onExited: resetModalState,
        },
      }}
    >
      <Box>
        <Typography variant="body1" color="black.dark">
          La información de la persona será utilizada para ayudarte a generar la
          nómina más rápida que has visto, recuerda que siempre podrás regresar
          a editar cualquier valor.
        </Typography>
        <Box
          sx={(theme) => ({
            marginY: theme.spacing(3),
          })}
        >
          <NewSteps
            stepsData={stepsData}
            current={currentStep}
            progress={progressStep}
            onChangeStep={handleClickStep}
          />
        </Box>
        {workerQuery.isLoading ? (
          <LoadingBox />
        ) : (
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={stepsData[currentStep]?.schemaValidation}
            enableReinitialize
          >
            {(form) => {
              const { handleSubmit: onSubmitForm } = form

              return (
                <>
                  <Form>
                    {currentStep === 0 ? <FieldsBasic /> : null}
                    {currentStep === 1 ? (
                      <FieldsLabor isCreatingWorker />
                    ) : null}
                    {currentStep === 2 ? <FieldsPayment /> : null}
                    {currentStep === 3 ? <FieldsPersonal /> : null}
                  </Form>
                  <Box
                    sx={(theme) => ({
                      display: 'flex',
                      gap: theme.spacing(2),
                      marginTop: theme.spacing(6),
                      flexWrap: 'wrap',
                      justifyContent: 'center',
                      [theme.breakpoints.up('tablet')]: {
                        justifyContent: 'flex-start',
                      },
                    })}
                  >
                    <Button
                      onClick={() => {
                        onSubmitForm()
                      }}
                      loading={workerMutation.isLoading}
                      disabled={workerMutation.isLoading}
                    >
                      {currentStep !== 3 ? 'Guardar y continuar' : 'Finalizar'}
                    </Button>
                    {currentStep === 0 ? (
                      <Button
                        variant="outlined"
                        onClick={handleClose}
                        disabled={workerMutation.isLoading}
                      >
                        Cancelar
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        onClick={handlePreviousStep}
                        disabled={workerMutation.isLoading}
                      >
                        Regresar
                      </Button>
                    )}
                  </Box>
                </>
              )
            }}
          </Formik>
        )}
      </Box>
    </Modal>
  )
}

export default NewWorkerModal
