import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { Box, Button } from '@mui/material'

import DetailSectionContainer from 'components/Benefits/Atoms/DetailSectionContainer'
import CreditResumeInfoCard from 'components/Benefits/Atoms/ResumeCard'
import StatusBanner from 'components/Benefits/Atoms/StatusBanner'

import { formatCurrency } from 'utils/format'
import { isDataCached } from 'utils/reactQuery'

import crossedDolarSign from 'assets/images/views/worker/crossed_dolar_sign.png'

import { SETTINGS_ADVANCED } from 'config/routes'

import CreditsData from './CreditsData'
import { useWorkersCredits } from './helpers'

const EnableBenefitsAction = () => {
  const navigate = useNavigate()

  const onClick = () => {
    navigate(SETTINGS_ADVANCED())
  }

  return <Button onClick={onClick}>Activar beneficios</Button>
}

const BenefitsShow = ({ benefitsEnabled }) => {
  const queryClient = useQueryClient()
  const cachedWorkersCredits = queryClient.getQueryData('workersCredits') || {}

  useWorkersCredits({
    enabled: !isDataCached(cachedWorkersCredits),
  })

  const { data: workersCreditsData } = cachedWorkersCredits

  if (!benefitsEnabled) {
    return (
      <StatusBanner
        title="Aún no tienes activado ningún beneficio para tus empleados"
        message="Activa los beneficios para empleados y permite que puedan acceder a programas de financiamiento exclusivos que les permitan alcanzar sus metas más fácil."
        image={crossedDolarSign}
        imageAlt="Image you don't have any benefits activated"
        action={<EnableBenefitsAction />}
      />
    )
  }

  return (
    <>
      <DetailSectionContainer title="Información general">
        <Box
          sx={(theme) => ({
            display: 'grid',
            gridGap: theme.spacing(3),
            gridTemplateColumns: '1fr',
            [theme.breakpoints.up('md')]: {
              gridTemplateColumns: 'repeat(auto-fit, minmax(13.75rem, 1fr));',
            },
          })}
          data-cy="credits-totals-container"
        >
          <CreditResumeInfoCard
            headerTitle="Créditos activos"
            creditData={workersCreditsData?.totals.active_credits}
            cardBold
          />
          <CreditResumeInfoCard
            headerTitle="Pre aprobados"
            creditData={workersCreditsData?.totals.preapproveds}
          />
          <CreditResumeInfoCard
            headerTitle="Solicitudes a crédito "
            creditData={workersCreditsData?.totals.credit_requests}
          />
          <CreditResumeInfoCard
            headerTitle="Deducciones de nómina"
            creditData={formatCurrency(
              workersCreditsData?.totals.payroll_deductions
            )}
          />
        </Box>
      </DetailSectionContainer>
      <DetailSectionContainer title="Créditos activos" gutterBottom={false}>
        <CreditsData data={workersCreditsData?.company_workers_credits} />
      </DetailSectionContainer>
    </>
  )
}
export default BenefitsShow
