import { useFormikContext } from 'formik'

import { Box, Typography } from '@mui/material'

import ContributorSubtype from 'components/Worker/Contract/Fields/ContributorSubtype'
import HealthProviderField from 'components/Worker/Contract/Fields/HealthProvider'
import InitialDayField from 'components/Worker/Contract/Fields/InitialDay'
import PensionProviderField from 'components/Worker/Contract/Fields/PensionProvider'
import RiskTypeField from 'components/Worker/Contract/Fields/RiskType'
import SeveranceProviderField from 'components/Worker/Contract/Fields/SeveranceProvider'
import LocationField from 'components/Worker/Form/Fields/Personal/Location/Location'
import PositionField from 'components/Worker/Form/Fields/Personal/Position/Position'
import BaseSalaryField from 'components/Worker/Wage/Fields/BaseSalary'

const AffiliationDataStep = ({ worker }) => {
  const { values } = useFormikContext()

  return (
    <>
      <Typography variant="body1" color="black.dark">
        Esta información es la que registraste en el perfil de la persona. Para
        realizar algún cambio, ingresa a su perfil y luego vuelve a realizar la
        afiliación a seguridad social.
      </Typography>
      <Box
        sx={(theme) => ({
          display: 'grid',
          gridTemplateColumns: '1fr',
          rowGap: theme.spacing(3),
          marginTop: theme.spacing(3),
          [theme.breakpoints.up('tablet')]: {
            gridTemplateColumns: '1fr 1fr',
            columnGap: theme.spacing(3),
          },
        })}
      >
        <PositionField
          placeholder="Cargo"
          tooltipContent={null}
          optional={false}
        />
        <BaseSalaryField
          isEditing={false}
          contractCategory={worker.contract_category}
          tooltipContent={null}
          optional={false}
        />
        <InitialDayField
          tooltipContent={null}
          label="Fecha de ingreso"
          optional={false}
        />
        <ContributorSubtype
          optional={false}
          contractCategory={worker.contract_category}
        />
        <HealthProviderField
          contractCategory={worker.contract_category}
          tooltipContent={null}
          optional={false}
        />
        <PensionProviderField
          contractCategory={worker.contract_category}
          documentType={worker.document_type}
          label="Pensiones"
          tooltipContent={null}
          optional={false}
          contributorSubtype={values?.contributor_subtype}
        />
        <SeveranceProviderField
          wageCategory={worker.wage_category}
          contractCategory={worker.contract_category}
          label="Cesantías"
          tooltipContent={null}
          optional={false}
        />
        <RiskTypeField
          contractCategory={worker.contract_category}
          label="Centro de trabajo - Nivel de riesgo"
          tooltipContent={null}
          optional={false}
        />
        <LocationField
          tooltipContent={null}
          label="Sede donde trabaja"
          optional={false}
        />
      </Box>
    </>
  )
}

export default AffiliationDataStep
