import { Chip, Typography } from '@mui/material'

import Link from 'components/UI/MaterialUI/Link'

import { getRoleDisplayName } from 'utils/company'

import * as routes from 'config/routes'

const handleRedirect = (workerWithBenefits, active) => {
  if (workerWithBenefits && !active) {
    return routes.WORKER_PROFILE_BENEFITS()
  }
  return routes.DASHBOARD
}

export default function getColumnsData(updateUser, changeCompany) {
  return [
    {
      Header: 'Nombre',
      accessor: 'name',
      Cell: ({
        row: {
          original: {
            id,
            name,
            role,
            worker_id: workerId,
            active,
            worker_with_benefits: workerWithBenefits,
          },
        },
      }) =>
        active || workerWithBenefits ? (
          <Link
            to={handleRedirect(workerWithBenefits, active)}
            onClick={() => {
              updateUser({ role, workerId, active, workerWithBenefits })
              changeCompany({ id })
            }}
            color="black.main"
          >
            {name}
          </Link>
        ) : (
          <Typography variant="body2">{name}</Typography>
        ),
    },
    {
      Header: 'Rol',
      accessor: 'role',
      Cell: ({ row }) => {
        return <Chip label={getRoleDisplayName(row.original.role)} />
      },
    },
    {
      Header: 'Activo',
      accessor: 'active',
      Cell: ({ row }) => <span>{row.original.active ? 'Sí' : 'No'}</span>,
    },
  ]
}
