import { Children, useState } from 'react'

import { Box, Paper, useMediaQuery } from '@mui/material'

import Tabs from 'components/UI/MaterialUI/Tabs/Tabs'

const TabsContainer = ({ tabs = [], children }) => {
  const [activeTab, setActiveTab] = useState(0)
  const smDown = useMediaQuery((theme) => theme.breakpoints.down('tablet'))

  const onChangeTab = (_, newTab) => {
    setActiveTab(newTab)
  }

  if (Children.count(children) !== tabs.length) {
    throw new Error(
      `The number of tabs (${
        tabs.length
      }) must be equal to the number of children (${Children.count(
        children
      )}) wrapped in TabsContainer`
    )
  }

  return (
    <Paper>
      <Tabs
        value={activeTab}
        onChange={onChangeTab}
        tabs={tabs}
        variant="scrollable"
        scrollButtons={smDown}
      />
      <Box
        sx={(theme) => ({
          padding: theme.spacing(5, 4),
        })}
      >
        {children[activeTab] || children}
      </Box>
    </Paper>
  )
}

export default TabsContainer
