import { Box, Typography, alpha } from '@mui/material'

import FinancialCoaching from 'components/Company/FinancialWellness/FinancialCoaching'
import FinancialWellnessIndicators from 'components/Company/FinancialWellness/FinancialWellnessIndicators'
import GeneralDiagnosis from 'components/Company/FinancialWellness/GeneralDiagnosis'
import Emoji from 'components/UI/Emoji'
import LordIcon from 'components/UI/LordIcon'
import FinancialCoachingSessions from 'components/Worker/FinancialWellness/FinancialCoachingSessions'
import FinancialWellnessCalculator from 'components/Worker/FinancialWellness/FinancialWellnessCalculator'
import Summary from 'components/Worker/FinancialWellness/Summary'

import {
  greenHeart,
  sunglassesFace,
  waterWave,
  winkingFace,
} from 'utils/emojis'

export const CLAIMS_LINK =
  'https://docs.google.com/forms/d/e/1FAIpQLSdXF_cqOCrFJg4KH7SKHaQJxG0aypLRD5ytYTV6gu4aRFajuQ/viewform'

export const claimsSectionCardInfo = [
  {
    id: 'upload_disabilities',
    number: 1,
    icon: 'document-upload',
    title: 'Carga tus incapacidades',
    numberBackgroundColor: 'accent4.dark',
  },
  {
    id: 'charge_disabilities',
    number: 2,
    icon: 'refund',
    title: 'Cobramos tus incapacidades',
    numberBackgroundColor: 'accent3.main',
  },
  {
    id: 'receive_money',
    number: 3,
    icon: 'withdrawal',
    title: 'Recibe tu platica en tu cuenta bancaria',
    numberBackgroundColor: 'primary.dark',
  },
]

export const coachingSectionCardsInfo = [
  {
    id: 'schedule_coaching',
    number: 1,
    icon: 'appointment-schedule',
    title: 'Solicita una cita gratuita con tu Coach',
    numberBackgroundColor: 'accent4.dark',
  },
  {
    id: 'receive_plan',
    number: 2,
    icon: 'people-handshake',
    title: 'Recibe un plan personalizado',
    numberBackgroundColor: 'accent3.main',
  },
  {
    id: 'financial_freedom',
    number: 3,
    icon: 'yoga-female',
    title: 'Comienza a vivir una vida libre de estrés financiero',
    numberBackgroundColor: 'primary.dark',
  },
]

export const createFakeDataPie = (sizeArray) => {
  const data = []

  for (let index = 0; index < sizeArray; index++) {
    data.push({ value: 1 })
  }
  return data
}

export const getAdminFinancialWellnessSectionsData = (dashboardData) => [
  {
    id: 'general_diagnosis',
    title: 'Diagnóstico general',
    description: (
      <>
        Dale un vistazo al bienestar financiero de las personas de tu equipo.
        Aquí te mostramos el puntaje crediticio y el indicador de bienestar
        financiero (promedio), para que sepas si el dinero fluye con
        tranquilidad o si es hora de ajustar las velas.{' '}
        <Emoji code={winkingFace} /> <Emoji code={greenHeart} />
      </>
    ),
    content: <GeneralDiagnosis fetchedData={dashboardData} />,
  },
  {
    id: 'financial_wellness_indicators',
    title: 'Indicador de Bienestar Financiero',
    description: (
      <>
        Queremos que las personas estén bien tranquis con las finanzas, agarren
        maletas y cumplan todos sus sueños. Visualiza cómo está su bienestar.{' '}
        <Emoji code={greenHeart} /> <Emoji code={waterWave} />
      </>
    ),
    content: <FinancialWellnessIndicators fetchedData={dashboardData} />,
  },
  {
    id: 'financial_coaching',
    title: 'Coaching Financiero',
    description: (
      <>
        Nada mejor que construir un futuro, pero juntos y en HD. Aquí están las
        sesiones que han tomado en tu equipo con nuestro amazing Coach
        Financiero. <Emoji code={sunglassesFace} /> <Emoji code={greenHeart} />
      </>
    ),
    content: <FinancialCoaching fetchedData={dashboardData} />,
  },
]

const FinancialWellnessTabLabel = ({ iconName, iconTarget, label }) => {
  return (
    <Typography
      variant="span"
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        columnGap: theme.spacing(1),
      })}
    >
      <Box
        sx={(theme) => ({
          borderRadius: '0.5rem',
          padding: theme.spacing(0.5),
          backgroundColor: alpha(theme.palette.accent2.light, 0.4),
        })}
      >
        <LordIcon
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& > lord-icon': {
              width: '2rem',
              height: '2rem',
            },
          }}
          name={iconName}
          target={iconTarget}
        />
      </Box>
      {label}
    </Typography>
  )
}

export const getTabsConfig = ({
  workerName,
  coachingSessions,
  financialWellnessIndicator,
  setActiveTab,
}) => {
  return [
    {
      label: (
        <FinancialWellnessTabLabel
          iconName="wired-lineal-documents"
          iconTarget="wired_lineal_documents"
          label="Resumen"
        />
      ),
      content: (
        <Summary
          coachingSessions={coachingSessions}
          financialWellnessIndicator={financialWellnessIndicator}
          changeTab={setActiveTab}
        />
      ),
      className: 'wired_lineal_documents',
    },
    {
      label: (
        <FinancialWellnessTabLabel
          iconName="financial-project"
          iconTarget="financial_project"
          label="Calculadora de bienestar"
        />
      ),
      content: <FinancialWellnessCalculator workerName={workerName} />,
      className: 'financial_project',
    },
    {
      label: (
        <FinancialWellnessTabLabel
          iconName="whistle"
          iconTarget="whistle"
          label="Coach financiero"
        />
      ),
      content: <FinancialCoachingSessions workerName={workerName} />,
      className: 'whistle',
    },
  ]
}
