import { AppBar, IconButton, Toolbar } from '@mui/material'

import Icon from 'components/UI/Icon'

import MonogramLogo from './MonogramLogo'

const AppMobileAppBar = ({
  mobileOpen,
  handleDrawerToggle,
  isCompanyImmediatePayment,
}) => {
  return (
    <AppBar
      component="div"
      position="fixed"
      sx={(theme) => ({
        boxShadow: 'none',
        minHeight: theme.mobileAppbar,
        zIndex: `${theme.zIndex.appBar + 1}`,
        display: {
          mobile: 'initial',
          laptop: 'none',
        },
      })}
    >
      <Toolbar
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: theme.spacing(0, 2),
          minHeight: theme.mobileAppbar,
          backgroundColor: theme.palette.primary.main,
        })}
        variant="dense"
        disableGutters
      >
        <MonogramLogo />
        <IconButton
          aria-label="abrir barra de navegación"
          onClick={handleDrawerToggle}
          size="large"
          sx={{
            ...(isCompanyImmediatePayment && {
              filter: 'saturate(0)',
              opacity: 0.5,
              pointerEvents: 'none',
            }),
          }}
        >
          {mobileOpen ? <Icon name="close-2" /> : <Icon name="menu" />}
        </IconButton>
      </Toolbar>
    </AppBar>
  )
}

export default AppMobileAppBar
