import { Box } from '@mui/material'

import Button from 'components/UI/Button/Button'

import StepperCertifications from '../Commons/StepperCertifications'

const ReceivedContent = ({ affiliations, handleClose }) => {
  const certificates = affiliations?.certificates_data || {}

  return (
    <Box>
      <StepperCertifications certificates={certificates} />
      <Button
        sx={(theme) => ({ marginTop: theme.spacing(7) })}
        onClick={handleClose}
      >
        Regresar
      </Button>
    </Box>
  )
}

export default ReceivedContent
