import { useUser } from 'components/App/UserContext/useUser'

const useSubscription = () => {
  const { subscription } = useUser()
  const additionalWorkersInfo = subscription?.additional_workers_info
  const paidWorkers = subscription?.paid_workers
  const payrollsSize = subscription?.payrolls_size

  const isPaymentExoneratedCompany =
    subscription.payment_category === 'exonerated_payment'

  const isHolisticPayrollMVPCompany =
    subscription.plan_category_with_suffix === 'per_worker_plan20241108' &&
    subscription.status === 'premium_trial'

  return {
    additionalWorkersInfo,
    paidWorkers,
    subscription,
    isPaymentExoneratedCompany,
    payrollsSize,
    isHolisticPayrollMVPCompany,
  }
}

export default useSubscription
