/**
 * Replaces payroll_concet with payroll_concept_id and adds category
 *
 * Used in Deductions and Income recurrent concepts components
 */
export const formatRecurrentConceptItem = (item, category) => {
  const newItem = {
    ...item,
    payroll_concept_id: item.payroll_concept.id,
    category,
    name: item.payroll_concept.name,
  }

  delete newItem.payroll_concept

  return newItem
}

export const formatRecurrentConceptsData = (
  recurrentConcepts = {},
  loans = []
) => {
  let deductions = []
  let incomes = []

  if (recurrentConcepts?.deductions && loans) {
    deductions = [
      ...recurrentConcepts?.deductions.map((deduction) =>
        formatRecurrentConceptItem(deduction, 'deductions')
      ),
      ...loans.map((loan) => ({
        ...loan,
        category: 'loans',
      })),
    ]
  }

  if (
    recurrentConcepts?.salary_income &&
    recurrentConcepts?.non_salary_income
  ) {
    incomes = [
      ...recurrentConcepts?.salary_income.map((income) =>
        formatRecurrentConceptItem(income, 'salary_income')
      ),
      ...recurrentConcepts?.non_salary_income.map((income) =>
        formatRecurrentConceptItem(income, 'non_salary_income')
      ),
    ]
  }

  return { deductions, incomes }
}
