import { Typography } from '@mui/material'

const ConfirmAffiliation = () => {
  return (
    <div>
      <Typography variant="body1" color="black.dark">
        Estás a un paso de terminar tu solicitud de afiliación a seguridad
        social. Una vez realicemos todo el proceso, te estaremos notificando vía
        correo electrónico.
        <br />
        <br />
        También podrás validar el estado de cada afiliación a seguridad social
        en el módulo de Personas.
      </Typography>
    </div>
  )
}

export default ConfirmAffiliation
