import { useFormikContext } from 'formik'

import { Box, Typography } from '@mui/material'

import FormField from 'components/UI/Formik/FormField/Index'

import { getFileNameFromUrl, isValidUrl } from 'utils/general'

import BeneficiariesStep from './Beneficiaries/Index'

const AttachDocumentsStep = () => {
  const { values } = useFormikContext()
  const identificationDocumentUrl = isValidUrl(values?.identification_document)
  const identificationDocumentName = getFileNameFromUrl(
    identificationDocumentUrl ? identificationDocumentUrl.search : undefined
  )
  const { worker } = values

  return (
    <>
      <Typography variant="body1" color="black.dark">
        Adjunta la cédula de la persona, esto con el fin de validar la
        información y obtener los datos necesarios para la afiliación a
        seguridad social.
      </Typography>
      <Box
        sx={(theme) => ({
          margin: theme.spacing(2.5, 0, 5, 0),
        })}
      >
        <FormField
          name="identification_document"
          label="Cédula de la persona"
          variant="file"
          fileName={identificationDocumentName}
          fileType="application/pdf"
          accept="application/pdf"
          optional={false}
        />
      </Box>
      <BeneficiariesStep worker={worker} />
      <Box
        sx={(theme) => ({
          marginTop: theme.spacing(5),
        })}
      >
        <FormField
          name="comment"
          label="¿Hay algo más que debemos saber o tener en cuenta sobre esta persona y sus beneficiarios para realizar la afiliación?"
          variant="textarea"
          rows={4}
        />
      </Box>
    </>
  )
}

export default AttachDocumentsStep
